import msgpack from 'msgpack-lite'
import { fetchCandlesURL } from '../../utils/apiEndpoints'
import {
  MOD_WATCH_LIST,
  GET_WATCHLIST_INIT,
  GET_WATCHLIST_FAILURE,
  GET_WATCHLIST_SUCCESS,
  UPDATE_WATCHLIST_INIT,
  UPDATE_WATCHLIST_SUCCESS,
  UPDATE_WATCHLIST_FAILURE,
  GET_ANALYSIS_SUCCESS,
  GET_ANALYSIS_FAILURE,
  GET_ANALYSIS_INIT,
  GET_TOP_MOVERS,
  GET_TOP_MOVERS_SUCCESS,
  GET_TOP_MOVERS_FAILURE,
  CLEAR_UPDATE_RESP,
  UPDATE_MARKET_SCRIPLIST,
  CLEAR_ANALYSIS,
} from './actionTypes'

export function modWatchList(params) {
  return {
    type: MOD_WATCH_LIST,
    params,
  }
}

export function getWatchListSuccess(data) {
  return {
    type: GET_WATCHLIST_SUCCESS,
    data,
  }
}

export function getWatchListFailure(error) {
  return {
    type: GET_WATCHLIST_FAILURE,
    error,
  }
}

export function getWatchList(params) {
  return {
    type: GET_WATCHLIST_INIT,
    payload: { params },
  }
}

export function updateWatchListSuccess(data) {
  return {
    type: UPDATE_WATCHLIST_SUCCESS,
    data,
  }
}

export function updateWatchListFailure(error, params) {
  return {
    type: UPDATE_WATCHLIST_FAILURE,
    error,
    params,
  }
}

export function updateWatchList(params) {
  return {
    type: UPDATE_WATCHLIST_INIT,
    params,
  }
}

export function getAnalysisSuccess(data, stock) {
  return {
    type: GET_ANALYSIS_SUCCESS,
    data,
    stock,
  }
}

export function getAnalysisFailure(error) {
  return {
    type: GET_ANALYSIS_FAILURE,
    error,
  }
}

export function getAnalysis(params, headers) {
  return {
    type: GET_ANALYSIS_INIT,
    params,
    headers,
  }
}

export function clearAnalysis() {
  return {
    type: CLEAR_ANALYSIS,
  }
}

export function getTopMovers(params, headers) {
  return {
    type: GET_TOP_MOVERS,
    params,
    headers,
  }
}

export function getTopMoversSuccess(data) {
  return {
    type: GET_TOP_MOVERS_SUCCESS,
    data,
  }
}

export function getTopMoversFailure(error) {
  return {
    type: GET_TOP_MOVERS_FAILURE,
    error,
  }
}

export function clearUpdateResp() {
  return {
    type: CLEAR_UPDATE_RESP,
  }
}

export function updateMarketScrips(list) {
  return {
    type: UPDATE_MARKET_SCRIPLIST,
    list,
  }
}

// umed
export const fetchCandles = ({
  exchange, symbol, startTime, endTime, timePeriod, csrf, sessionid,
}) => {
  const payload = {
    exchange,
    symbol,
    start_time: startTime,
    end_time: endTime,
    time_period: timePeriod,
    source: 'charts',
    csrf,
    session_id: sessionid,
  }

  const url = fetchCandlesURL.india
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    }).then(async (response) => {
      try {
        // msgpack encoded arrayBuffer is converted back to JSON
        const res = await response.arrayBuffer()
        const codec = msgpack.createCodec({ useraw: true })
        const barray = new Uint8Array(res)
        const actualData = []
        const dt = await msgpack.decode(barray, { codec })
        for (let i = 0; i < dt.length; i += 1) {
          const cdl = msgpack.decode(dt[i], { codec })
          actualData.push(cdl)
        }
        if (actualData.length) {
          resolve(actualData)
        }
        reject(new Error('No Data'))
      } catch (error) {
        reject(new Error('No Data'))
      }
    })
  })
}
