// SVG
// import Paper from '../assets/svgs/paper.svg'
import PaperFilled from '../assets/svgs/paper_filled_round.svg'
import PaperFilledDark from '../assets/svgs/paper_filled_dark.svg'
// import Wifi from '../assets/svgs/wifi.svg'
import WifiFilled from '../assets/svgs/wifi_filled_round.svg'
import WifiFilledDark from '../assets/svgs/wifi_filled_dark.svg'
import Scanner from '../assets/svgs/scanner.svg'
import ScannerFilled from '../assets/svgs/scanner_filled.svg'
import ScannerFilledDark from '../assets/svgs/scanner_filled_dark.svg'

import Cube from '../assets/svgs/cube.svg'
import CubeFilled from '../assets/svgs/cube_filled.svg'
import CubeFilledDark from '../assets/svgs/cube_filled_dark.svg'
import Flower from '../assets/svgs/flower.svg'
import FlowerFilled from '../assets/svgs/flower_filled.svg'
import FlowerFilledDark from '../assets/svgs/flower_filled_dark.svg'

// BROKER LOGOS
import KiteImage from '../assets/png/logo/kite-logo.png'
import Logo from '../assets/png/streak-logo/logo-tm.png'
import LogoDark from '../assets/png/streak-logo/logo-tm-dark.png'
import ABImage from '../assets/png/logo/AngelOneRGBLogo.png'
import StreakLogo from '../assets/png/streak-logo/streak-logo.png'
import KiteLogo from '../assets/svgs/kite_logo.svg'
import ABLogo from '../assets/svgs/angel_broking_logo.svg'
import FivePaisaLogo from '../assets/svgs/five_paisa.svg'
import AcademyLogo from '../assets/svgs/academy_logo.svg'
import UpstoxLogo from '../assets/png/logo/upstox.png'
import ZerodhaLogo from '../assets/svgs/zerodha_logo.svg'
import ZerodhaStreakLogo from '../assets/png/logo/zerodha_streak.png'
import PoweredByStreak from '../assets/png/logo/poweredByStreak.png'
// import StreakWhiteLogo from '../assets/svgs/streak_white_logo.svg'
// import KiteWhiteLogo from '../assets/svgs/kite_white_logo.svg'

import Partial_1 from '../assets/svgs/partial_1.svg'
import Partial_2 from '../assets/svgs/partial_2.svg'
import Partial_3 from '../assets/svgs/partial_3.svg'

// Exchange Icons
import CDS from '../assets/exchanges/CDS-FUT.svg'
import MCX from '../assets/exchanges/MCX.svg'
import NFOFUT from '../assets/exchanges/NFO-FUT.svg'
import NSE from '../assets/exchanges/NSE.svg'
import BSE from '../assets/exchanges/BSE.svg'
import DYC_DEFAULT from '../assets/exchanges/dyc_default.svg'

// Gradient icons
import GRADIENT_BLACK from '../assets/svgs/gradient_black.svg'
import GRADIENT_BLUE from '../assets/svgs/gradient_blue.svg'
import GRADIENT_PURPLE from '../assets/svgs/gradient_purple.svg'
import GRADIENT_RED from '../assets/svgs/gradient_red.svg'
import GRADIENT_PEACH from '../assets/svgs/gradient_peach.svg'
import GRADIENT_GREEN from '../assets/svgs/gradient_green.svg'

import BAR from '../assets/png/technicals/bar.svg'
import BAR_MA from '../assets/png/technicals/bar_ma.svg'
import BAR_OSCILlATOR from '../assets/png/technicals/bar_oscillator.svg'
import XS_BAR from '../assets/png/technicals/bar_small.svg'
import BAR_RESISTANCE from '../assets/png/technicals/bar_resistance.svg'
import XS_BAR_RESISTANCE from '../assets/png/technicals/bar_resistance_small.svg'
import XS_POINTER from '../assets/png/technicals/pointer_small.svg'
import POINTER from '../assets/png/technicals/pointer.svg'
// import Pointer from '../assets/svgs/pointer.svg'
import XS_POINTER_DARK from '../assets/png/technicals/xs_pointer_dark.svg'
import POINTER_DARK from '../assets/png/technicals/pointer_dark.svg'
// import POINTER_DARK from '../assets/svgs/pointer_dark.svg'
import PointerResistance from '../assets/svgs/pointer_resistance.svg'

// GIFs
import BlueLightGif from '../assets/gif/blue-blinker-light.gif'
import BlueDarkGif from '../assets/gif/blue-blinker-dark.gif'

import GreenLightGif from '../assets/gif/green-blinker-light.gif'
import GreenDarkGif from '../assets/gif/green-blinker-dark.gif'

import OrangeLightGif from '../assets/gif/orange-blinker-light.gif'
import OrangeDarkGif from '../assets/gif/orange-blinker-dark.gif'

import RedLightGif from '../assets/gif/red-blinker-light.gif'
import RedDarkGif from '../assets/gif/red-blinker-dark.gif'

import DeploySuccess from '../assets/gif/deploy-success.gif'

import DotLoaderBlue from '../assets/gif/dot_loader_blue.gif'
import DotLoaderWhite from '../assets/gif/dot_loader_white.gif'
import CircleLoader from '../assets/gif/circle_loader.gif'

import PlaceholderDarkGreyGif from '../assets/gif/placeholder_dark.gif'
import PlaceholderLightGreyGif from '../assets/gif/placeholder-lightgrey.gif'
import PlaceholderLightGif from '../assets/gif/placeholder_light.gif'

import BASIC from '../assets/png/payment/basic.png'
import PREMIUM from '../assets/png/payment/premium.png'
import ULTIMATE from '../assets/png/payment/ultimate.png'
import NO_PLAN from '../assets/png/payment/no_plan.png'
import BASIC_BOX from '../assets/png/payment/basic_box.png'
import PREMIUM_BOX from '../assets/png/payment/premium_box.png'
import ULTIMATE_BOX from '../assets/png/payment/ultimate_box.png'
import FREE_BOX from '../assets/png/payment/free_box.png'
import EXPLORER from '../assets/png/payment/explorer.png'
import FREE_PLAN from '../assets/png/payment/free.png'
import PRO from '../assets/png/payment/pro.png'
import Amex from '../assets/png/payment/amex.png'
import Master from '../assets/png/payment/master.png'
import Visa from '../assets/png/payment/visa.png'
import Rupay from '../assets/png/payment/rupay.png'
// import DinersCard from '../assets/png/payment/dinerscard.png'
import Maestro from '../assets/png/payment/maestro.png'
import SBI from '../assets/svgs/netbanking/sbi_bank.svg'
import ICICI from '../assets/svgs/netbanking/icici_bank.svg'
import AXIS from '../assets/svgs/netbanking/axis_bank.svg'
import CANARA from '../assets/svgs/netbanking/canara_bank.svg'
import KOTAK from '../assets/svgs/netbanking/kotak_bank.svg'
import UPI from '../assets/svgs/netbanking/upi.svg'
import G_PAY from '../assets/svgs/netbanking/g_pay.svg'
import PAYMENT_SUCCESS from '../assets/png/payment/payment_success.png'
import PAYMENT_SUCCESS_DARK from '../assets/png/payment/payment_success_dark.png'

import DEPLOY_COUNT from '../assets/png/common/deploy_count.png'
import DEPLOY_COUNT_DARK from '../assets/png/common/deploy_count_dark.png'
import BACKTEST_COUNT from '../assets/png/common/backtest_count.png'
import BACKTEST_COUNT_DARK from '../assets/png/common/backtest_count_dark.png'
import SCANNER_COUNT from '../assets/png/common/scanner_count.png'
import SCANNER_COUNT_DARK from '../assets/png/common/scanner_count_dark.png'
import ONBOARDING_HEADER from '../assets/png/common/onboarding_header.png'

import USER_IMG from '../assets/png/common/user_img.png'

import ADD_STOCKS from '../assets/svgs/common/add_stocks.svg'
import ADD_STOCKS_DARK from '../assets/svgs/common/add_stocks_dark.svg'
import NO_STOCKS_DARK from '../assets/svgs/common/no_stocks_dark.svg'
import NO_STOCKS from '../assets/svgs/common/no_stocks.svg'
import NO_SCANS from '../assets/svgs/common/no_scans.svg'
import NO_SCANS_DARK from '../assets/svgs/common/no_scans_dark.svg'
import NO_BACKTESTS from '../assets/svgs/common/no_backtests.svg'
import NO_BACKTESTS_DARK from '../assets/svgs/common/no_backtests_dark.svg'
import CREATE_SWITCH from '../assets/svgs/common/create_switch.svg'
import CREATE_EMPTY from '../assets/svgs/common/create_empty.svg'
import CREATE_EMPTY_DARK from '../assets/svgs/common/create_empty_dark.svg'
import INCOMPLETE_EMPTY from '../assets/svgs/common/incomplete_empty.svg'
import INCOMPLETE_EMPTY_DARK from '../assets/svgs/common/incomplete_empty_dark.svg'
import DASH_PORTFOLIO_EMPTY from '../assets/svgs/common/dash_portfolio_empty.svg'
import DASH_PORTFOLIO_EMPTY_DARK from '../assets/svgs/common/dash_portfolio_empty_dark.svg'
import PORTFOLIO_EMPTY from '../assets/svgs/common/portfolio_empty.svg'
import NOTIFICATION_EMPTY from '../assets/svgs/common/notification_empty.svg'
import DEPLOYED_EMPTY from '../assets/svgs/common/deployed_empty.svg'
import ORDERBOOK_EMPTY from '../assets/svgs/common/orderbook_empty.svg'
import PORTFOLIO_EMPTY_DARK from '../assets/svgs/common/portfolio_empty_dark.svg'
import NOTIFICATION_EMPTY_DARK from '../assets/svgs/common/notification_empty_dark.svg'
import DEPLOYED_EMPTY_DARK from '../assets/svgs/common/deployed_empty_dark.svg'
import ORDERBOOK_EMPTY_DARK from '../assets/svgs/common/orderbook_empty_dark.svg'
import VERIFY_EMAIL from '../assets/svgs/common/verify_email.svg'

import TREND_FOLLOWING from '../assets/svgs/discover/trend_following.svg'
import PRICE_ACTION from '../assets/svgs/discover/price_action.svg'
import MEAN_REVERSION from '../assets/svgs/discover/mean_reversion.svg'
import MOMENTUM from '../assets/svgs/discover/momentum.svg'
import MISCELLANEOUS from '../assets/svgs/discover/miscellaneous.svg'
import PIVOT_POINTS from '../assets/svgs/discover/pivot_points.svg'
import OVERLAYS from '../assets/svgs/discover/overlays.svg'
import ATR from '../assets/svgs/discover/atr_tralling_stop_loss.svg'
import INTRADAY from '../assets/svgs/discover/intraday.svg'
import MTF from '../assets/svgs/discover/mtf.svg'
import OPTIONS_STRATEGIES from '../assets/svgs/discover/options_strategies.svg'
import SWING_TRADING from '../assets/svgs/discover/swing_trading.svg'
import THE_MILLENNIALS from '../assets/svgs/discover/the_millennials.svg'
import TECH from '../assets/svgs/discover/tech.svg'
import LEADING from '../assets/svgs/discover/leading.svg'
import POSITION_TRADING from '../assets/svgs/discover/position_trading.svg'

import TREND_FOLLOWING_OB from '../assets/png/onboardingDiscover/cross_symbol.png'
import PRICE_ACTION_OB from '../assets/png/onboardingDiscover/price_action.png'
import MEAN_REVERSION_OB from '../assets/png/onboardingDiscover/mean_reversion.png'
import MOMENTUM_OB from '../assets/png/onboardingDiscover/momentum.png'
import MISCELLANEOUS_OB from '../assets/png/onboardingDiscover/miscellaneous.png'
import PIVOT_POINTS_OB from '../assets/png/onboardingDiscover/pivot_points.png'
import OVERLAYS_OB from '../assets/png/onboardingDiscover/overlays.png'
import ATR_OB from '../assets/png/onboardingDiscover/atr_tralling_stop_loss.png'
import INTRADAY_OB from '../assets/png/onboardingDiscover/intraday.png'
import MTF_OB from '../assets/png/onboardingDiscover/mtf.png'
import OPTIONS_STRATEGIES_OB from '../assets/png/onboardingDiscover/options_strategies.png'
import SWING_TRADING_OB from '../assets/png/onboardingDiscover/swing_trading.png'
import THE_MILLENNIALS_OB from '../assets/png/onboardingDiscover/the_millennials.png'
import TECH_OB from '../assets/png/onboardingDiscover/tech.png'
import LEADING_OB from '../assets/png/onboardingDiscover/leading.png'
import POSITION_TRADING_OB from '../assets/png/onboardingDiscover/position_trading.png'

import QL_DEPLOYED from '../assets/svgs/discover/ql_deployed.svg'
import QL_ORDERBOOK from '../assets/svgs/discover/ql_orderbook.svg'
import QL_PORTFOLIO from '../assets/svgs/discover/ql_portfolio.svg'

import CROSSOVER from '../assets/svgs/discoverScans/crossover.svg'
import INTRADAY_BEARISH from '../assets/svgs/discoverScans/intraday_bearish.svg'
import BEARISH from '../assets/svgs/discoverScans/bearish.svg'
import BULLISH from '../assets/svgs/discoverScans/bullish.svg'
import INTRADAY_BULLISH from '../assets/svgs/discoverScans/intraday_bullish.svg'
import RANGE_BREAKOUT from '../assets/svgs/discoverScans/range_breakout.svg'
import TOP_GAINERS from '../assets/svgs/discoverScans/top_gainers.svg'
import TOP_LOSERS from '../assets/svgs/discoverScans/top_losers.svg'
import WEBINAR from '../assets/svgs/discoverScans/webinar.svg'
import CANDLE_PATTERNS from '../assets/svgs/discoverScans/candle_patterns.svg'

import CROSSOVER_OB from '../assets/png/onboardingDiscoverScans/crossover.png'
import INTRADAY_BEARISH_OB from '../assets/png/onboardingDiscoverScans/intraday_bearish.png'
import BEARISH_OB from '../assets/png/onboardingDiscoverScans/bearish.png'
import BULLISH_OB from '../assets/png/onboardingDiscoverScans/bullish.png'
import INTRADAY_BULLISH_OB from '../assets/png/onboardingDiscoverScans/intraday_bullish.png'
import RANGE_BREAKOUT_OB from '../assets/png/onboardingDiscoverScans/range_breakout.png'
import TOP_GAINERS_OB from '../assets/png/onboardingDiscoverScans/top_gainers.png'
import TOP_LOSERS_OB from '../assets/png/onboardingDiscoverScans/top_losers.png'
import WEBINAR_OB from '../assets/png/onboardingDiscoverScans/webinar.png'
import CANDLE_PATTERNS_OB from '../assets/png/onboardingDiscoverScans/candle_patterns.png'

import LOGIN_BG from '../assets/png/login/login_bg.png'
import EXTEND_TRIAL from '../assets/png/common/extend_trial.png'
import WELL_DONE from '../assets/svgs/common/well_done.svg'

import DEMO_SUCCESS from '../assets/svgs/common/demo_success.svg'

// share icons
import Whatsapp from '../assets/png/common/whatsapp.png'
import Facebook from '../assets/png/common/facebook.png'
import LinkedIn from '../assets/png/common/LinkedIn.png'
import Twitter from '../assets/png/common/twitterIcon.png'
import FacebookIcon from '../assets/svgs/facebookIcon.svg'
import Lock from '../assets/svgs/Lock.svg'

import SUCCESS_LOTTIE from '../assets/lottie_jsons/success.json'

import ENABLED_2FA from '../assets/png/account/twoFA-enabled.png'
import NoDataPlaceholder from '../assets/png/common/NoDataPlaceholder.png'
import NoDataCardPlaceholder from '../assets/svgs/common/NoDataPlaceholder.svg'
import Weekly from '../assets/svgs/weekly.svg'

export default {

  // BROKER LOGOS
  StreakLogo,
  Logo,
  LogoDark,
  KiteImage,
  KiteLogo,
  ZerodhaLogo,
  ZerodhaStreakLogo,
  PoweredByStreak,
  Lock,
  ABLogo,
  ABImage,
  FivePaisaLogo,
  AcademyLogo,
  UpstoxLogo,
  Whatsapp,
  Facebook,
  LinkedIn,
  Twitter,
  FacebookIcon,
  // StreakWhiteLogo,
  // KiteWhiteLogo,

  PaperFilled,
  PaperFilledDark,
  Scanner,
  ScannerFilled,
  ScannerFilledDark,
  WifiFilled,
  WifiFilledDark,
  Cube,
  CubeFilled,
  CubeFilledDark,
  Flower,
  FlowerFilled,
  FlowerFilledDark,
  PlaceholderDarkGreyGif,
  PlaceholderLightGreyGif,
  PlaceholderLightGif,
  // partial algos scanners
  Partial_1,
  Partial_2,
  Partial_3,

  GRADIENT_BLACK,
  GRADIENT_BLUE,
  GRADIENT_PURPLE,
  GRADIENT_RED,
  GRADIENT_PEACH,
  GRADIENT_GREEN,

  POINTER,
  XS_POINTER_DARK,
  POINTER_DARK,
  PointerResistance,
  BAR,
  XS_BAR,
  BAR_RESISTANCE,
  XS_BAR_RESISTANCE,
  BAR_MA,
  BAR_OSCILlATOR,
  XS_POINTER,
  CDS,
  MCX,
  NFOFUT,
  NSE,
  BSE,
  DYC_DEFAULT,

  BlueLightGif,
  BlueDarkGif,
  GreenLightGif,
  GreenDarkGif,
  OrangeLightGif,
  OrangeDarkGif,
  RedLightGif,
  RedDarkGif,
  DeploySuccess,
  DotLoaderBlue,
  DotLoaderWhite,
  CircleLoader,

  BASIC,
  PREMIUM,
  ULTIMATE,
  NO_PLAN,
  BASIC_BOX,
  PREMIUM_BOX,
  ULTIMATE_BOX,
  FREE_BOX,
  FREE_PLAN,
  EXPLORER,
  PRO,
  Amex,
  Master,
  Visa,
  Rupay,
  Maestro,
  SBI,
  ICICI,
  AXIS,
  CANARA,
  KOTAK,
  UPI,
  G_PAY,
  PAYMENT_SUCCESS,
  PAYMENT_SUCCESS_DARK,

  DEPLOY_COUNT,
  DEPLOY_COUNT_DARK,
  BACKTEST_COUNT,
  BACKTEST_COUNT_DARK,
  SCANNER_COUNT,
  SCANNER_COUNT_DARK,
  ONBOARDING_HEADER,

  USER_IMG,

  ADD_STOCKS,
  ADD_STOCKS_DARK,
  CREATE_SWITCH,
  CREATE_EMPTY,
  CREATE_EMPTY_DARK,
  NO_STOCKS,
  NO_STOCKS_DARK,
  NO_SCANS,
  NO_SCANS_DARK,
  NO_BACKTESTS,
  NO_BACKTESTS_DARK,
  INCOMPLETE_EMPTY,
  INCOMPLETE_EMPTY_DARK,
  DASH_PORTFOLIO_EMPTY,
  DASH_PORTFOLIO_EMPTY_DARK,
  PORTFOLIO_EMPTY,
  NOTIFICATION_EMPTY,
  ORDERBOOK_EMPTY,
  DEPLOYED_EMPTY,
  PORTFOLIO_EMPTY_DARK,
  NOTIFICATION_EMPTY_DARK,
  DEPLOYED_EMPTY_DARK,
  ORDERBOOK_EMPTY_DARK,

  TREND_FOLLOWING,
  PRICE_ACTION,
  MEAN_REVERSION,
  MOMENTUM,
  MISCELLANEOUS,
  PIVOT_POINTS,
  OVERLAYS,
  OPTIONS_STRATEGIES,
  INTRADAY,
  SWING_TRADING,
  POSITION_TRADING,
  THE_MILLENNIALS,
  TECH,
  LEADING,
  ATR,
  MTF,

  TREND_FOLLOWING_OB,
  PRICE_ACTION_OB,
  MEAN_REVERSION_OB,
  MOMENTUM_OB,
  MISCELLANEOUS_OB,
  PIVOT_POINTS_OB,
  OVERLAYS_OB,
  OPTIONS_STRATEGIES_OB,
  INTRADAY_OB,
  SWING_TRADING_OB,
  POSITION_TRADING_OB,
  THE_MILLENNIALS_OB,
  TECH_OB,
  LEADING_OB,
  ATR_OB,
  MTF_OB,

  QL_DEPLOYED,
  QL_ORDERBOOK,
  QL_PORTFOLIO,

  CANDLE_PATTERNS,
  WEBINAR,
  BULLISH,
  BEARISH,
  INTRADAY_BULLISH,
  INTRADAY_BEARISH,
  RANGE_BREAKOUT,
  CROSSOVER,
  TOP_GAINERS,
  TOP_LOSERS,

  CANDLE_PATTERNS_OB,
  WEBINAR_OB,
  BULLISH_OB,
  BEARISH_OB,
  INTRADAY_BULLISH_OB,
  INTRADAY_BEARISH_OB,
  RANGE_BREAKOUT_OB,
  CROSSOVER_OB,
  TOP_GAINERS_OB,
  TOP_LOSERS_OB,

  LOGIN_BG,
  VERIFY_EMAIL,
  EXTEND_TRIAL,
  WELL_DONE,

  DEMO_SUCCESS,
  SUCCESS_LOTTIE,

  ENABLED_2FA,
  NoDataPlaceholder,
  NoDataCardPlaceholder,
  Weekly,
}
