import { takeLatest, put, call } from 'redux-saga/effects'
import axios from 'axios'
import { v4 as uuid } from 'uuid'
import { prodEndpoints, baseUrl, msPollUrl } from '../../utils/apiEndpoints'
import { getRequest } from '../../utils/apiRequests'
import {
  profileCarouselSuccess,
  profileCarouselFailure,
} from './actions'
import {
  FETCH_PROFILE_CAROUSEL,
  POLL_USER,
} from './actionTypes'

import { error_msg, getCookies, setSessionCookie } from '../../utils/common'

function* fetchProfileCarousel(action) {
  try {
    const { headers } = action
    const url = `${baseUrl}${prodEndpoints.profile_carousel}`
    const data = yield call(getRequest, url, {}, headers)
    if (!data.error && data.status === 'success') {
      yield put(profileCarouselSuccess(data))
    } else {
      yield put(profileCarouselFailure(data.error))
    }
  } catch(err) {
    yield put(profileCarouselFailure(err.message || error_msg))
  }
}

export function* fetchProfileCarouselSaga() {
  yield takeLatest(FETCH_PROFILE_CAROUSEL, fetchProfileCarousel)
}

const pollUser = async (action) => {
  let { params } = action
  const ApiRequest = axios.create({
    timeout: 45000,
  })
  const cookies = getCookies()
  if(cookies.sessionId) {
    params = { ...params, sessionId: cookies.sessionId }
    setSessionCookie(cookies.sessionId)
  }else{
    const sessionId = uuid()
    params = { ...params, sessionId }
    setSessionCookie(sessionId)
  }
  const headers = { 'content-type': 'application/json' }
  const url = msPollUrl
  const data = await ApiRequest.post(url, params, headers)
    .then((resp) => {
      return resp.data
    }).catch((err) => {
      console.log(err)
    })
  return data
}

export function* pollUserSaga() {
  yield takeLatest(POLL_USER, pollUser)
}
