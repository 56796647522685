import React, { Component, Suspense } from 'react'
import { connect } from 'react-redux'
// import ReactGA from 'react-ga'
import isEqual from 'lodash/isEqual'
import {
  Switch,
  Redirect,
  withRouter,
} from 'react-router-dom'
import { v4 as uuid } from 'uuid'
import ScreenHeader from './components/ScreenHeader'
import Common from './containers/Common/Common-redux'
import PageLoader from './UI/PageLoader'
import SnackBar from './UI/Snackbar'
import Routes, { routes } from './Routes'
import { withTheme } from './Theme/ThemeProvider'
import { pathname_mapping } from './utils/navigationConstant'
import {
  orginMap,
  PREFERENCES,
} from './utils/consts'
import { PRO_FEATURE_MAP } from './utils/userAccessChecks'
import {
  getLocalStorage, getCurrentBroker, getCookies, setCookie, decodeRoute,
} from './utils/common'
import {
  SPACING, theme, COLORS, ASSETS,
} from './Theme'
import ScreenerFooter from './UI/ScreenerFooter'
import { pollUser } from './containers/Common/actions'

const dummy = () => { }

const ROUTES = {
  APP: 'app',
  LOADING: 'loading',
}

const sideBarWidth = 294

export const derivedBroker = getCurrentBroker()

const validPathname = Object.values(pathname_mapping)

class App extends Component {
  constructor(props) {
    super(props)
    const version = orginMap()[window.location.host] || 3
    this.unAuthorized = false
    this.initialMount = false
    this.finalWasm = null
    this.queryParams = {}
    this.scrollRef = React.createRef()
    this.state = {
      version,
      loggedIn: true,
      password: '',
      hideSideBar: true,
      activeSideBarObj: {},
      showUpgradeModal: false,
      screens: routes.some(route => window.location.pathname.includes(route.path)),
      referralModal: false,
      activeRoute: ROUTES.APP,
      showPageLoader: false,
      isWebView: false,
      handlePostUUID: uuid(),
      isHeatmap: false,
    }
    this.sideBarRef = React.createRef()
    this.upgradeModalProps = {}
  }

  componentDidMount() {
    const { location } = this.props
    const { handlePostUUID } = this.state
    this.updatePreferences()
    const handlePostID = this.handlePostIDSet()
    if (location.hash === '#heatmap') {
      this.pollHandler(handlePostID || handlePostUUID, true)
    }
    if(validPathname.includes(location.pathname)) this.pollHandler(handlePostID || handlePostUUID)
    // this.initializeGA()
  }

  componentDidUpdate(prevProps) {
    const {
      location,
      greetingModal,
      toggleTheme,
      themeMode,
    } = this.props
    const handlePostID = this.handlePostIDSet()
    if (!isEqual(location.pathname, prevProps.location.pathname)) {
      if(validPathname.includes(location.pathname)) this.pollHandler(handlePostID)
      if (this.scrollRef.current && (location.pathname + location.hash) !== (`${pathname_mapping.Dashboard}#heatmap`)) {
        this.windowScrollTimer = setTimeout(() => {
          this.scrollRef.current.scrollTo(0, 0)
        }, 150)
      }
      const screens = routes.some(route => location.pathname.includes(route.path))
      this.setState({
        screens,
      })
      const defaultMode = getLocalStorage(PREFERENCES.THEME)
      if (defaultMode && defaultMode !== themeMode) {
        toggleTheme(defaultMode)
      }
    }
    if (!isEqual(greetingModal, prevProps.greetingModal)) {
      this.upgradeModalProps = PRO_FEATURE_MAP[greetingModal] || {}
      this.setState({ showUpgradeModal: !!greetingModal })
    }
  }

  componentWillUnmount = () => {
    if (this.requestDemoTimer) {
      clearTimeout(this.requestDemoTimer)
    }
    if (this.windowScrollTimer) clearTimeout(this.windowScrollTimer)
    // window.removeEventListener('focus', this.windowFocusHandler)
    // window.removeEventListener('blur', this.toggleWindowActive)
  }

  handlePostIDSet = () => {
    const { handlePostUUID } = this.state
    const { handlePostUUID: handlePostID = '' } = getCookies()
    const expirationDate = new Date()
    expirationDate.setDate(expirationDate.getDate() + 365)
    if (!handlePostID) {
      setCookie({ handlePostUUID }, `${expirationDate.toUTCString()}`)
    }
    return handlePostID
  }

  pollHandler = (user_uuid, heatmap) => {
    const { location: { pathname, search }, pollUserHandle } = this.props
    let params = {}
    if(heatmap) {
      params = {
        user_uuid,
        click: 'heatmap',
      }
    }else{
      const view = decodeRoute(pathname, search)
      params = {
        user_uuid,
        view,
      }
    }
    pollUserHandle(params)
  }

  updatePreferences = () => {
    const { updatePreferences } = this.props
    const preferences = getLocalStorage(PREFERENCES.KEY)
    if (preferences) {
      updatePreferences(JSON.parse(preferences))
    }
  }

  // initializeGA = () => {
  //   ReactGA.initialize('UA-110056397-4', {
  //     linker: {
  //       domains: ['streak.tech'], // 'localhost:3000'
  //     },
  //     testMode: process.env.NODE_ENV === 'development',
  //   })
  //   // GAScript()
  // }

  updateGA = () => {
    try {
      const page = window.location.search && window.location.search.includes('tab=')
        ? window.location.pathname + window.location.search : window.location.pathname
      console.log(page)
      // ReactGA.pageview(page)
    } catch (e) {
      console.log(e.message, 'ga error')
    }
  }

  check = (e) => {
    e.preventDefault()
    const { password } = this.state
    if (password === 'newui321321') {
      this.setState({ loggedIn: true })
    } else {
      this.setState({ password: '' })
    }
  }

  fallback = () => {
    const { styles } = this.props
    return (
      <div className={styles.fallback}>
        <PageLoader fullScreen />
      </div>
    )
  }

  toggleSideBar = (e, hide) => {
    const {
      websiteConfig = {}, updateSiteConfig, location, isMobile, // updateGA,
    } = this.props
    if (isMobile) return
    const { activeSideBarObj, hideSideBar } = this.state

    if (this.sideBarRef.current) {
      if (hideSideBar) {
        this.sideBarRef.current.style.position = 'absolute'
        if (this.sideBarStyleTimer) clearTimeout(this.sideBarStyleTimer)
        this.sideBarStyleTimer = setTimeout(() => {
          this.sideBarRef.current.style.position = 'sticky'
        }, 450)
      } else {
        this.sideBarRef.current.style.position = 'absolute'
      }
    }
    let modSideBarState = websiteConfig.sideBarState || {}
    const pathname = activeSideBarObj.tabKey ? `${location.pathname}?${activeSideBarObj.tabKey}`
      : location.pathname
    modSideBarState = {
      ...modSideBarState,
      [pathname]: hideSideBar ? '1' : '0',
    }
    this.setState({ hideSideBar: !hideSideBar })
    updateSiteConfig('sideBarState', modSideBarState)
    if (hide !== undefined) {
      // const gaAction = hide ? 'Close button' : 'Open gear icon'
      // let gaLabel = ''
      // let gaCategory = ''
      if (location.pathname === pathname_mapping.Backtests
        || location.pathname === pathname_mapping.MarketplaceBacktest
      ) {
        // gaLabel = 'Backtest parameters'
        // gaCategory = 'Edit parameters'
      } else if (location.pathname === pathname_mapping.SharedBacktest) {
        // gaLabel = 'Shared backtest parameters'
        // gaCategory = 'Edit parameters'
      } else if (location.pathname === pathname_mapping.ScanDetails) {
        // gaLabel = 'Scanner parameters'
        // gaCategory = 'Scanner parameters'
      }
      // updateGA(gaAction, gaLabel, 1, gaCategory)
    }
  }

  renderScreens = ({ activeRoute }) => {
    if (activeRoute === ROUTES.LOADING) {
      return (
        <PageLoader fullScreen />
      )
    }
    if (activeRoute === ROUTES.APP) {
      return (
        <Switch>
          {Routes()}
          <Redirect to={pathname_mapping.Dashboard} />
        </Switch>
      )
    }
    return <PageLoader fullScreen />
  }

  routeToDashboard = () => {
    this.props.history.push({
      pathname: pathname_mapping.Dashboard,
    })
  }

  routeToDiscover = () => {
    this.props.history.push({
      pathname: pathname_mapping.Discover,
      search: '?tab=top_performer',
    })
  }

  routeToScanners = () => {
    this.props.history.push({
      pathname: pathname_mapping.popular,
      search: '?tab=discover_popular',
    })
  }

  routeToPricing = () => {
    const { history, isLoggedIn, location } = this.props
    if (isLoggedIn) {
      history.push(pathname_mapping.pricing)
    } else if (location.pathname === pathname_mapping.Home) {
      window.open('#pricing', '_self')
    } else {
      history.push(`${pathname_mapping.Home}#pricing`)
    }
  }

  routeToHome = () => {
    this.props.history.push({
      pathname: pathname_mapping.Home,
    })
  }

  routeToLogin = () => {
    this.props.history.push({
      pathname: pathname_mapping.Auth,
    })
  }

  onDismissLoginModal = () => {
    const { toggleAuthModal } = this.props
    toggleAuthModal(false)
  }

  routeToPayment = (url) => {
    this.props.history.push(url)
    this.setState({ showUpgradeModal: false })
  }

  toggleUpgradeModal = () => {
    this.props.toggleAuthGreeting()
  }

  render() {
    const {
      styles, location, history,
      updatePreferences, preferences, startTour,
    } = this.props
    const {
      hideSideBar, activeRoute, showPageLoader, isWebView,
    } = this.state

    const stylesProps = { id: 'authScreens', className: styles.authScreens }

    return (
      <main className={`${styles.main}`} data-tour={startTour ? 'tour' : ''} id="main" ref={this.scrollRef}>
        {showPageLoader && <PageLoader isLoading={showPageLoader} fullScreen />}
        <Common
          activeRoute={activeRoute}
          updatePreferences={updatePreferences}
          preferences={preferences}
          location={location}
          isWebView={isWebView}
        />
        <ScreenHeader location={location} history={history} />
        <Suspense fallback={this.fallback()}>
          <div className={styles.screens}>
            <div {...stylesProps}>
              <div id="portalWindow" />
              <div className={`${styles.screensContent} ${hideSideBar
                ? styles.container : styles.leftContainer}`}
              >
                {this.renderScreens({ activeRoute })}
              </div>
            </div>
          </div>
        </Suspense>
        <ScreenerFooter />
        {SnackBar}
      </main>
    )
  }
}

const stylesheet = {
  main: {
    height: '100vh',
    // do not change this other this are dependent on scrollBehavior
    // search for getElementById('main')
    overflow: 'auto',
    overflowX: 'hidden',
    backgroundColor: theme.screenBg,
    // pos = relative used for tour
    position: 'relative',
  },
  authScreens: {
  },
  referralContainer: {
    width: 325,
  },
  screensContent: {
    display: 'inline-block',
    transition: 'all 0.35s var(--anim-func-ease)',
  },
  screens: {
    // minHeight: `calc(100% - ${SPACING.HEADER_HEIGHT}px)`,
    minHeight: 'calc(100% - 170px)',
  },
  screensBg: {
    backgroundImage: `url(${ASSETS.LOGIN_BG})`,
    backgroundPosition: 'center',
  },
  leftContainer: {
    // flex: 0.77,
    width: `calc(100% - ${sideBarWidth + 40}px)`, // '72%',
    marginRight: 40,
  },
  container: {
    width: '100%',
  },
  rightContainer: {
    // flex: 0.23,
    width: sideBarWidth,
    position: 'sticky',
    top: SPACING.HEADER_HEIGHT, // `${75 + (DIMENSIONS.HEIGHT * 0.06)}px`,
    verticalAlign: 'top',
    display: 'inline-block',
    marginBottom: SPACING.SPACE_38,
    marginTop: SPACING.SPACE_30,
    opacity: 1,
    transition: 'all 350ms var(--anim-func-ease)',
    transform: 'translate(0px, 0px)',
  },
  rightContainerHidden: {
    // transform: 'translate(294px, 0px)',
    opacity: 0,
    visibility: 'hidden',
    position: 'absolute',
  },
  fallback: {
    zIndex: '99999',
    top: '0',
    height: '100%',
    position: 'fixed',
    width: '100%',
    background: '#f9f9f99e',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  sideBarToggler: {
    position: 'fixed',
    top: '100px',
    right: '36px',
    transition: 'transform 450ms var(--anim-func-ease)',
    opacity: 1,
    transform: 'translate(0px)',
  },
  sideBarTogglerBtn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // backgroundColor: theme.bgSecondary,
    borderRadius: 6,
    border: `1px solid ${COLORS.BLUE}`,
    height: '40px',
    width: '52px',
    backgroundColor: COLORS.BLUE,
    // fill: theme.white,
    '&:hover': {
      '& > svg': {
      },
    },
  },
  sideBarTogglerHidden: {
    transform: 'translate(100px, 0)',
    opacity: 0,
  },
  rightContainerCloseBtn: {
    zIndex: 99,
    position: 'absolute',
    right: 30,
    top: 10,
  },
  upgradeModal: {
    maxWidth: 740,
    padding: 20,
  },
  // '@media only screen and (min-width: 1366px) and (max-width: 1440px)': {
  // },
  '@media only screen and (min-width: 1025px) and (max-width: 1366px)': {
    leftContainer: {
      marginRight: SPACING.SPACE_24,
      width: `calc(75% - ${SPACING.SPACE_24})`,
    },
    rightContainer: {
      width: '25%',
    },
  },
  '@media only screen and (min-width: 786px) and (max-width: 1024px)': {
    leftContainer: {
      marginRight: 0,
      width: '100%',
    },
  },
  '@media only screen and (max-width: 786px)': {
    leftContainer: {
      marginRight: 0,
      width: '100%',
    },
    screens: {
    },
  },
}

const mapStateToProps = () => {
  return {
    summary: '',
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getSummary: params => dispatch(dummy(params)),
    pollUserHandle: params => dispatch(pollUser(params)),
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(
    withTheme(stylesheet)(App),
  ),
)
