import { call, put, takeLatest } from 'redux-saga/effects'
import {
  GET_SCREENS_INIT,
  DELETE_SCREENS_INIT,
  POPULAR_SCREENS_INIT,
  GET_ALERT_SCREENS_INIT,
  SET_ALERT_SCREENS_INIT,
  STOP_ALERT_SCREENS_INIT,
  GET_ALERT_HISTORY_INIT,
  MARK_FAVORITE_INIT,
  GET_FAVORITE_INIT,
  FETCH_SCANNER_CATEGORY,
  SEARCH_SCANNER_INIT,
  FETCH_SCANNERS_STOCKS,
  GENERATE_SCANNER_SHARE_LINK,
} from './actionTypes'
import { error_msg } from '../../utils/common'
import {
  getScreensSuccess,
  getScreensFailure,
  deleteScreensSuccess,
  deleteScreensFailure,
  popularScreensSuccess,
  popularScreensFailure,
  getAlertScreensSuccess,
  getAlertScreensFailure,
  setAlertScreensSuccess,
  setAlertScreensFailure,
  stopAlertScreensSuccess,
  stopAlertScreensFailure,
  getAlertHistorySuccess,
  getAlertHistoryFailure,
  searchScannerSuccess,
  searchScannerFailure,
  markFavoriteSuccess,
  markFavoriteFailure,
  getFavoriteFailure,
  getFavoriteSuccess,
  fetchScannerCategoriesSuccess,
  fetchScannerCategoriesFailure,
  fetchScannersStocksSuccess,
  fetchScannersStocksFailure,
  generateScannerShareLinkSuccess,
  generateScannerShareLinkFailure,
} from './actions'
import { getRequest, postRequest } from '../../utils/apiRequests'
import {
  alertScreenUrl, baseUrl, prodEndpoints,
} from '../../utils/apiEndpoints'
import { screenerPairs, getInstruments } from '../Instruments/actions'
import { NAVIGATIONS } from '../../utils/navigationConstant'
import { SCREENER_TAGS_DATA } from './scanUtils'

function* getScreens(action) {
  try {
    const {
      params, headers, append, isSearching,
    } = action
    const url = `${prodEndpoints.get_screens}`
    // params.page_number += 1
    const data = yield call(postRequest, url, JSON.stringify(params), headers, false)
    if (!data.error && data.data) {
      if(isSearching) {
        yield put(searchScannerSuccess(data.data, append))
      } else {
        yield put(getScreensSuccess(data.data, append))
      }
    } else if(isSearching) {
      yield put(searchScannerFailure(data.error_msg || error_msg))
    } else {
      yield put(getScreensFailure(data.error_msg || error_msg))
    }
  } catch (err) {
    yield put(getScreensFailure(error_msg))
  }
}

export function* getScreensSaga() {
  yield takeLatest(GET_SCREENS_INIT, getScreens)
}

function* popularScreens(action) {
  try {
    const { params, headers, isSearching } = action
    const url = `${prodEndpoints.get_screens}`
    const data = yield call(postRequest, url, JSON.stringify(params), headers, false)
    if (!data.error && data.data) {
      if(isSearching) {
        yield put(searchScannerSuccess(data.data.results))
      } else {
        yield put(popularScreensSuccess(data.data.results))
      }
    } else if(isSearching) {
      yield put(searchScannerFailure(data.error_msg || error_msg))
    } else {
      yield put(popularScreensFailure(data.error_msg || error_msg))
    }
  } catch (err) {
    yield put(popularScreensFailure(error_msg))
  }
}

export function* popularScreensSaga() {
  yield takeLatest(POPULAR_SCREENS_INIT, popularScreens)
}

function* searchScanner(action) {
  try {
    const {
      params, headers, append,
    } = action
    const url = `${prodEndpoints.get_screens}`
    // params.page_number += 1
    const data = yield call(postRequest, url, JSON.stringify(params), headers, false)
    if (!data.error && data.data) {
      yield put(searchScannerSuccess(data.data, append))
    } else {
      yield put(searchScannerFailure(data.error_msg || error_msg))
    }
  } catch (err) {
    yield put(searchScannerFailure(error_msg))
  }
}

export function* searchScannerSaga() {
  yield takeLatest(SEARCH_SCANNER_INIT, searchScanner)
}

function* markFavorite(action) {
  try {
    const { params, headers } = action
    const url = `${baseUrl}${prodEndpoints.favorite_screener}`
    const data = yield call(postRequest, url, params, headers)
    if (data.status === 'success' && !data.error) {
      yield put(markFavoriteSuccess(data))
    } else {
      yield put(markFavoriteFailure(data.error_msg || error_msg))
    }
  } catch (err) {
    yield put(markFavoriteFailure(error_msg))
  }
}

export function* markFavoriteSaga() {
  yield takeLatest(MARK_FAVORITE_INIT, markFavorite)
}

function* getFavorite(action) {
  try {
    const { params, headers } = action
    const url = `${baseUrl}${prodEndpoints.favorite_screener}`
    const data = yield call(getRequest, url, params, headers)
    if (data.status === 'success' && !data.error) {
      yield put(getFavoriteSuccess(data))
    } else {
      yield put(getFavoriteFailure(data.error_msg || error_msg))
    }
  } catch (err) {
    yield put(getFavoriteFailure(error_msg))
  }
}

export function* getFavoriteSaga() {
  yield takeLatest(GET_FAVORITE_INIT, getFavorite)
}

function* deleteScreens(action) {
  try {
    const { params, headers } = action
    const url = `${baseUrl}${prodEndpoints.remove_screener}`
    const data = yield call(postRequest, url, params, headers)
    if (data.status === 'success' && !data.error) {
      yield put(deleteScreensSuccess(data))
    } else {
      yield put(deleteScreensFailure(data.error_msg || error_msg))
    }
  } catch (err) {
    yield put(deleteScreensFailure(error_msg))
  }
}

export function* deleteScreensSaga() {
  yield takeLatest(DELETE_SCREENS_INIT, deleteScreens)
}

function* getAlertScreens(action) {
  try {
    const { params, dataExists, filters } = action
    const url = `${alertScreenUrl}${prodEndpoints.get_alert_screens}`
    if (filters && dataExists) {
      yield put(getAlertScreensSuccess(null, filters))
    } else {
      const data = yield call(postRequest, url, params)
      if (data.data && !data.error) {
        yield put(getAlertScreensSuccess(data.data, filters))
      } else {
        yield put(getAlertScreensFailure(data.error_msg || error_msg))
      }
    }
  } catch (err) {
    yield put(getAlertScreensFailure(error_msg))
  }
}

export function* getAlertScreensSaga() {
  yield takeLatest(GET_ALERT_SCREENS_INIT, getAlertScreens)
}

function* setAlertScreens(action) {
  try {
    const { params, headers } = action
    const url = `${alertScreenUrl}${prodEndpoints.set_alert_screens}`
    const data = yield call(postRequest, url, params, headers)
    if (data.data && data.data === 'Ok') {
      yield put(setAlertScreensSuccess({ ...data.status, status: 'success' }))
    } else {
      yield put(setAlertScreensFailure(data.error_msg || error_msg))
    }
  } catch (err) {
    yield put(setAlertScreensFailure(error_msg))
  }
}

export function* setAlertScreensSaga() {
  yield takeLatest(SET_ALERT_SCREENS_INIT, setAlertScreens)
}

function* stopAlertScreens(action) {
  try {
    const { params, headers } = action
    const url = `${alertScreenUrl}${prodEndpoints.stop_alert_screens}`
    const data = yield call(postRequest, url, JSON.stringify(params), headers, false)
    if (data.data && data.data === 'Ok') {
      yield put(stopAlertScreensSuccess({ ...data, status: 'success' }))
    } else {
      yield put(stopAlertScreensFailure(data.error_msg || error_msg))
    }
  } catch (err) {
    yield put(stopAlertScreensFailure(error_msg))
  }
}

export function* stopAlertScreensSaga() {
  yield takeLatest(STOP_ALERT_SCREENS_INIT, stopAlertScreens)
}

function* getAlertHistory(action) {
  try {
    const { params, headers } = action
    const url = `${alertScreenUrl}${prodEndpoints.get_alert_history}`
    const data = yield call(postRequest, url, params, headers)
    if (data.data && !data.error) {
      yield put(getAlertHistorySuccess(data.data))
    } else {
      yield put(getAlertHistoryFailure(data.error_msg || error_msg))
    }
  } catch (err) {
    yield put(getAlertHistoryFailure(error_msg))
  }
}

export function* getAlertHistorySaga() {
  yield takeLatest(GET_ALERT_HISTORY_INIT, getAlertHistory)
}

function* fetchScannerCategories() {
  try {
    // const url = `${baseUrl}${prodEndpoints.screener_tags}`
    // const data = yield call(getRequest, url, {})
    const data = SCREENER_TAGS_DATA
    if (data.status === 'success' && !data.error) {
      yield put(fetchScannerCategoriesSuccess(data))
    } else {
      yield put(fetchScannerCategoriesFailure(data.error_msg || error_msg))
    }
  } catch (err) {
    yield put(fetchScannerCategoriesFailure(error_msg))
  }
}

export function* fetchScannerCategoriesSaga() {
  yield takeLatest(FETCH_SCANNER_CATEGORY, fetchScannerCategories)
}

function* fetchScannersStocks(action) {
  try {
    const { params } = action
    const url = `${baseUrl}${prodEndpoints.screener}`
    for (let i = 0; i < params.length; i++) {
      const { idKey, screener_uuid, sample } = params[i]
      const data = yield call(getRequest, `${url}?screener_uuid=${screener_uuid}&sample=${sample}&max_count=-1`)
      if (!data.error && data.status === 'success') {
        const { screener_result = [] } = data
        yield put(fetchScannersStocksSuccess({ idKey, id: screener_uuid, data: screener_result }))
        // const { tokens, token_keys } = screenerPairs(data.screener_result)
        // yield put(subscribe(tokens, token_keys))
        yield put(getInstruments(
          screenerPairs(null, data.screener_result.slice(0, 16)),
          {},
          NAVIGATIONS.SCAN_DETAILS.name,
        ))
      } else {
        yield put(fetchScannersStocksFailure(data.error_msg || error_msg))
      }
    }
  } catch (err) {
    yield put(fetchScannersStocksFailure(error_msg))
  }
}

export function* fetchScannersStocksSaga() {
  yield takeLatest(FETCH_SCANNERS_STOCKS, fetchScannersStocks)
}

function* generateShareLink(action) {
  try {
    const { params, headers } = action
    const url = prodEndpoints.generate_scanner_share_link
    // const url = prodEndpoints.generate_shareable_link
    const data = yield call(postRequest, url, params, headers)
    if (!data.error) {
      yield put(generateScannerShareLinkSuccess(data))
    } else {
      yield put(generateScannerShareLinkFailure(data.error))
    }
  } catch (err) {
    yield put(generateScannerShareLinkFailure(error_msg))
  }
}

export function* generateShareLinkSaga() {
  yield takeLatest(GENERATE_SCANNER_SHARE_LINK, generateShareLink)
}
