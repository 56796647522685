import {
  FETCH_SCANNER_DETAILS_INIT,
  FETCH_SCANNER_DETAILS_SUCCESS,
  FETCH_SCANNER_DETAILS_FAILURE,
} from './actionTypes'

const initialState = {
  isFetchingScannerDetails: false,
  fetchingScannerDetailsErr: false,
  fetchingScannerDetailsErrMsg: '',
  scannerDetails: {},
}

const dashboard = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SCANNER_DETAILS_INIT:
      return {
        ...state,
        isFetchingScannerDetails: true,
        fetchingScannerDetailsErr: false,
        fetchingScannerDetailsErrMsg: '',
        scannerDetails: {},
      }

    case FETCH_SCANNER_DETAILS_SUCCESS: {
      const { data: { screeners = [] } } = action
      screeners.map((screener) => {
        const { stocks } = screener
        const segmentTypes = ['NSE', 'INDICES', 'NFO-OPT', 'MCX', 'NFO-FUT']
        const segmentChecker = stock => (segmentTypes.includes(stock.segment)) && (stock.symbol.startsWith('NIFTY') || stock.segment !== 'INDICES')
        const filteredStocks = []
        stocks.forEach((stock) => {
          // if (!(!stock.symbol.startsWith('NIFTY') && stock.segment === 'INDICES')
          // && (stock.segment === 'NSE' || stock.segment === 'INDICES'
          // || stock.segment === 'NFO-OPT'
          // || stock.segment === 'MCX' || stock.segment === 'NFO-FUT')) {
          //   filteredStocks.push(stock)
          // }
          if(segmentChecker(stock)) filteredStocks.push(stock)
        })
        // const sortedStocks = sortAllData(filteredStocks, 'change', false)
        screener.stocks = filteredStocks
        // screener.stocks = sortedStocks
        return 1
      })
      return {
        ...state,
        isFetchingScannerDetails: false,
        scannerDetails: screeners[0],
      }
    }

    case FETCH_SCANNER_DETAILS_FAILURE: {
      return {
        ...state,
        isFetchingScannerDetails: false,
        fetchingScannerDetailsErr: true,
        fetchingScannerDetailsErrMsg: action.error,
      }
    }

    default:
      return state
  }
}

export default dashboard
