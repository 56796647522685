import React, { createRef, PureComponent } from 'react'
import { connect } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import { Link } from 'react-router-dom/'

import { throttle } from 'lodash'
import Marquee from 'react-fast-marquee'
import dayjs from 'dayjs'
import CustomModal from '../../UI/CustomModal'
import CustomText from '../../UI/CustomText'
import Icon from '../../UI/Icon'
import Button from '../../UI/Button'

import {
  ASSETS, COLORS, ICONS, theme, SPACING, FONTS, FONTWEIGHT, // DIMENSIONS
} from '../../Theme'
import { getCookies, setLocalStorage } from '../../utils/common'
import { withTheme } from '../../Theme/ThemeProvider'
import { pathname_mapping } from '../../utils/navigationConstant'
import {
  PREFERENCES, REFETCH_NEW_DATA_TIMER, THEME_TYPE_MAP,
} from '../../utils/consts'
import {
  helpPageUrl, scannersTechUrl, zerodhaHomeUrl, zerodhaSignupUrl,
} from '../../utils/apiEndpoints'
import ShowLTP from '../../UI/ShowLTP'
import { pollUser } from '../../containers/Common/actions'

const { ZerodhaLogo, PoweredByStreak } = ASSETS

const tourPages = [
  pathname_mapping.Dashboard, pathname_mapping.Strategies,
  pathname_mapping.Scanners, pathname_mapping.Backtests,
  pathname_mapping.marketwatch,
]

export const getDotColor = (unReadCount = {}, totalUnreadCount) => {
  let dotColor = COLORS.RED
  let notifColor = COLORS.GREY
  let routeToTab = pathname_mapping.Notifications

  if (totalUnreadCount > 0) {
    notifColor = theme.linkColor
    routeToTab = pathname_mapping.notif_scanner
  }
  if (unReadCount.Notifications) {
    routeToTab = pathname_mapping.notif_live
    dotColor = theme.linkColor
  } else if (unReadCount['Paper trading']) {
    routeToTab = pathname_mapping.notif_paper
    dotColor = COLORS.GREEN
  }
  return { dotColor, notifColor, routeToTab }
}

const heatmapUniverse = [
  {
    label: 'NIFTY 50', value: 'Nifty50', seg_sym: 'INDICES_NIFTY 50', icon: ICONS.NIFTY50,
  },
  {
    label: 'NIFTY BANK', value: 'NiftyBank', seg_sym: 'INDICES_NIFTY BANK', icon: ICONS.NIFTYBANK,
  },
]

class ScreenHeader extends PureComponent {
  state = {
    linkBrokerLogin: false,
    brokerChangeAlert: false,
    showABLogin: false,
    showBrokerDropdown: false,
    showSideMenu: false,
    isCondesedView: false,
  }

  alertProps = {}

  headerRef = createRef()

  refHeaderTitle = React.createRef()

  actionBarSticky = true

  intervalKey = ''

  componentDidMount() {
    const {
      updateNifty50HeatMap,
      updateNiftyBankHeatMap,
    } = this.props

    updateNifty50HeatMap({ index: 'Nifty50' })
    updateNiftyBankHeatMap({ index: 'NiftyBank' })

    this.intervalKey = setInterval(() => {
      updateNifty50HeatMap({ index: 'Nifty50' })
      updateNiftyBankHeatMap({ index: 'NiftyBank' })
    }, REFETCH_NEW_DATA_TIMER)
  }

  componentDidUpdate(prevProps) {
    const { pathname } = this.state
    if (pathname !== prevProps.pathname && this.headerRef.current) {
      this.headerRef.current.removeAttribute('data-theme')
    }
  }

  componentWillUnmount() {
    clearInterval(this.intervalKey)
  }

  pollHandler = (clickEvent) => {
    const { pollUserHandle } = this.props
    const { handlePostUUID: handlePostID = '' } = getCookies()
    const params = {
      user_uuid: handlePostID,
      click: clickEvent,
    }
    pollUserHandle(params)
  }

  scrollListener = throttle(() => {
    if (this.refHeaderTitle.current) {
      const headerTitleRef = this.refHeaderTitle.current

      // if (top < 0 && this.actionBarSticky) {
      //   this.actionBarSticky = false
      //   headerRef.style.position = 'relative'
      // } else if (top > 0 && !this.actionBarSticky) {
      //   this.actionBarSticky = true
      //   headerRef.style.position = 'sticky'
      // }

      if (headerTitleRef < 50) { // (headerTop >10 && headerTop < 50)
        headerTitleRef.style.opacity = 0
      }
      if (headerTitleRef > 50) {
        headerTitleRef.style.opacity = 1
      }
    }
  }, 10)

  onProfileClick = () => {}

  onChangeOption = (actionType) => {
    const { logout } = this.props
    switch (actionType) {
      case 'logout': {
        logout()
        break
      }

      default:
        break
    }
  }

  routeToNotifications = () => {

  }

  brokersInputRenderer = () => {
    const { connectedBrokers, styles, fancyBroker } = this.props
    if (!isEmpty(fancyBroker)) {
      const { label, logo } = fancyBroker
      return (
        <div className={styles.brokerInputContainer}>
          <div className={styles.currentBroker}>
            <img src={logo} className={styles.logoImg} alt={`${label} logo`} />
            <CustomText weight="semi_bold">{label}</CustomText>
          </div>
        </div>
      )
    }
    const currentBroker = connectedBrokers.filter(item => item.currentBroker)
    if (!currentBroker[0]) return null
    const { logo, label } = currentBroker[0]
    return (
      <div className={styles.brokerInputContainer}>
        <div className={styles.currentBroker}>
          <img src={logo} className={styles.logoImg} alt={`${label} logo`} />
          <CustomText weight="semi_bold">{label}</CustomText>
        </div>
      </div>
    )
  }

  brokerLabelRenderer = (optionObj, label, selected) => {
    const { styles } = this.props
    const { logo, name } = optionObj
    const addBroker = name === 'addBroker'
    return (
      <div className={styles.brokerRow}>
        {addBroker ? <Icon name={ICONS.ADD_ROUND_OUTLINED} className={styles.logoIcon} color="orange" size={15} />
          : <img src={logo} className={styles.logoImg} alt={`${label} logo`} />}
        <CustomText
          color={addBroker ? 'orange' : 'text'}
          weight="semi_bold"
          style={{ flex: 1, textAlign: 'left' }}
        >
          {label}
        </CustomText>
        {selected && (
          <Icon name={ICONS.TICK} color={COLORS.GREEN} size={12} />
        )}
      </div>
    )
  }

  toggleModal = (modal) => {
    this.setState(prevState => ({ [modal]: !prevState[modal] }))
  }

  toggleAbLogin = (addBroker) => {
    this.addBroker = addBroker
    this.setState({ showABLogin: true })
  }

  routeToHelp = () => {
    window.open(helpPageUrl, '_blank')
  }

  routeToLogin = ({ loggedIn, routeToLogin, routeToDashboard }) => {
    this.setState({ showSideMenu: false })
    if (loggedIn) routeToDashboard()
    else routeToLogin()
  }

  toggleCallback = (showBrokerDropdown) => {
    this.setState({ showBrokerDropdown })
  }

  shouldDisplayTour = (pathname, search) => {
    return (tourPages.includes(pathname) && isEmpty(search))
      || (pathname === pathname_mapping.Scanners && search.includes('tab=scan_details'))
      || (pathname === pathname_mapping.Backtests
      )
  }

  updateGA = (a, l, v) => {
    console.log(a, l, v)
    // this.props.updateGA(a, l, v, 'Navigation Bar')
  }

  toggleTheme = () => {
    const { toggleTheme, isDark } = this.props
    this.toggleCallback(false)

    const value = isDark ? THEME_TYPE_MAP.LIGHT.value : THEME_TYPE_MAP.DARK.value
    setLocalStorage({ [PREFERENCES.THEME]: value })
    toggleTheme(value)
    // this.updateGA('Toggle theme', isDark ? THEME_TYPE_MAP.LIGHT.label
    // : THEME_TYPE_MAP.DARK.label)
  }

  toggleSideMenu = () => {
    this.setState(prevState => ({ showSideMenu: !prevState.showSideMenu }))
  }

  routeToPricing = () => {
    const { routeToPricing } = this.props
    this.setState({ showSideMenu: false })
    routeToPricing()
  }

  routeToZerodhaHome = () => {
    window.open(zerodhaHomeUrl, '_blank')
  }

  routeToZerodhaSignup = () => {
    window.open(zerodhaSignupUrl, '_blank')
  }

  routeToHeatMap = () => {
    this.pollHandler('heatmap')
    const heatMapEle = document.getElementById('heatmap')
    heatMapEle.scrollIntoView({ behavior: 'smooth' })
  }

  renderHeaderRight = () => {
    const {
      isMobile, styles, routeToDashboard, routeToLogin,
      loggedIn, location,
    } = this.props
    const { showSideMenu } = this.state
    if (isMobile) {
      return (
        <div className={styles.row}>
          <CustomModal
            onDismiss={this.toggleSideMenu}
            visible={showSideMenu}
            containerStyles={styles.sideMenuContainer}
            position="right"
            fullScreen
          >
            <div className={styles.sideMenuHeader}>
              {/* <Button
                mode="outlined"
                buttonColor="blue"
                text="Start Free Trial"
                onPress={this.routeToLogin}
                params={{ loggedIn, routeToLogin, routeToDashboard }}
                btnStyles={styles.headerGetStartedMobile}
                labelWeight="medium"
                labelSize={isMobile ? 'SMALL_1' : 'small'}
                roundness={0}
              /> */}
              <button
                type="button"
                onClick={() => this.toggleSideMenu('showSideMenu')}
                className={styles.sideMenuBtnClose}
              >
                <Icon size={14} name={ICONS.CLOSE} color={theme.linkColor} />
              </button>
            </div>
            <div className={styles.sideMenuContent}>
              <button
                type="button"
                className={styles.homeBtn}
                onClick={() => {
                  this.routeToZerodhaHome()
                  this.setState({ showSideMenu: false })
                }}
              >
                <CustomText size="medium" weight="medium">
                  Home
                </CustomText>
              </button>
              <Button
                mode="text"
                labelColor="linkColor"
                buttonColor="blueLightBg"
                onPress={this.routeToZerodhaSignup}
                text="Sign Up"
                labelSize="medium"
                params={{ loggedIn, routeToLogin, routeToDashboard }}
                btnStyles={styles.smLoginBtn}
              />
            </div>
          </CustomModal>
          {/* <button
            type="button"
            onClick={() => this.toggleSideMenu('showSideMenu')}
            className={styles.sideMenuBtn}
            id="sideMenuBtn"
          >
            <Icon size={12} name={ICONS.SIDE_MENU} color={theme.linkColor} />
          </button> */}
        </div>
      )
    }
    return (
      <div className={styles.row}>
        {location.pathname.includes(pathname_mapping.Dashboard)
        && (
          <>
            <Button
              mode="text"
              labelColor="black"
              transparent
              onPress={this.routeToHeatMap}
              text="Heatmap"
              btnStyles={styles.heatMapBtn}
              id="heatmap-btn"
              iconName="HEATMAP"
              iconProps={{ size: 14 }}
            />
            <span className={styles.logoLinkSeprator} />
          </>
        )}
        <Button
          mode="text"
          labelColor="black"
          transparent
          onPress={this.routeToZerodhaHome}
          text="Home"
          params={{ loggedIn, routeToLogin, routeToDashboard }}
          btnStyles={styles.headerHelpBtn}
        />
        <Button
          mode="contained"
          buttonColor="zerodha_blue"
          text="Sign Up"
          onPress={this.routeToZerodhaSignup}
          btnStyles={styles.headerGetStarted}
        />
      </div>
    )
  }

  renderHeaderIndexes = (item) => {
    const {
      styles, nifty50MapData, niftyBankMapData, isMobile, heatmapData,
    } = this.props
    const {
      heatmap = [],
    } = heatmapData
    const lastUpdateTimestamp = heatmap[0]?.current_price_time
    const apiDate = dayjs.unix(lastUpdateTimestamp).format('DD-MM-YYYY')
    const todayDate = dayjs().format('DD-MM-YYYY')
    const isDataFromToday = apiDate === todayDate
    const {
      label, seg_sym, value, icon,
    } = item
    const finalMapData = value === 'Nifty50' ? nifty50MapData : niftyBankMapData
    const { nifty_percentage_change = 0, nifty_value = 0, nifty_movement = 0 } = finalMapData

    return (
      <div
        className={isMobile
          ? styles.niftyDetailsContainerMobile : styles.niftyDetailsContainer}
        key={value}
      >
        <div className={isMobile ? styles.niftyTitleMobile : styles.niftyTitle}>
          <Icon name={icon} size={16} style={{ marginRight: SPACING.SPACE_4 }} />
          <CustomText size="small_1" color="black" weight="medium">
            {label}
          </CustomText>
        </div>
        <div className={styles.niftyDetails}>
          <ShowLTP
            containerStyles={styles.ltpContainer}
            ltpStyles={styles.niftyltp}
            changeStyles={styles.niftychange}
            showLtpColor={false}
            showBrackets
            seg_sym={seg_sym}
            customChange={nifty_percentage_change}
            customLtp={nifty_value.toFixed(2)}
            customPointChange={nifty_movement}
            hideCurrency
            showPointChange
            selectCustomValues={isDataFromToday}
          />
        </div>
      </div>
    )
  }

  render() {
    const {
      styles,
      isMobile,
      location,
    } = this.props

    return (
      <header className={`mainHeader ${styles.header} ${styles.headerUnAuth}`} id="mainheader" ref={this.headerRef}>
        <div className={styles.headerContainer} id="headerContainer">
          <div id="logoSection" className={styles.logoSection}>
            <div className={styles.logoLink}>
              <Link
                to={pathname_mapping.Dashboard}
                style={{ backgroundImage: `url(${ZerodhaLogo})` }}
                className={styles.logoLinkZerodha}
                id="logoLinkZeroda"
              >
                {/* <img src={} id="headerLogo" alt="streak-logo" className={styles.logo} /> */}
              </Link>
              <span className={styles.logoLinkSeprator} />
              <a
                href={scannersTechUrl}
                style={{ backgroundImage: `url(${PoweredByStreak})` }}
                className={styles.logoLinkStreak}
                id="logoLinkStreak"
                target="_blank"
                rel="noreferrer"
              >
                {/* <img src={} id="headerLogo" alt="streak-logo" className={styles.logo} /> */}
              </a>
            </div>
          </div>
          {isMobile && location.pathname === pathname_mapping.Dashboard
          && <span className={styles.mobileTimeFrameBorder} id="headerSeparator" />}
          {location.pathname.includes(pathname_mapping.Dashboard)
          && (
          <div id="headerIndexes" className={isMobile ? styles.niftyContainerHeaderMobile : styles.niftyContainerHeader}>
            { isMobile
              ? (
                <>
                  <div className="animatedHeader">
                    <Marquee speed={75} gradient={false}>
                      <div className="scroll-element">
                        {heatmapUniverse.map(this.renderHeaderIndexes)}
                      </div>
                    </Marquee>
                  </div>
                  <Button
                    mode="text"
                    labelColor="black"
                    transparent
                    onPress={this.routeToHeatMap}
                    btnStyles={styles.heatMapBtnMobile}
                    id="heatmap-btn"
                    iconName="HEATMAP"
                    iconProps={{ size: 16 }}
                  />
                </>
              )
              : heatmapUniverse.map(this.renderHeaderIndexes)}
          </div>
          )}
          {this.renderHeaderRight()}
        </div>
      </header>
    )
  }
}

const stylesheet = ({
  header: {
    height: 64,
    backgroundColor: theme.bgPrimary,
    boxShadow: `0px 3px 20px ${theme.boxShadow}`,
    // marginBottom: DIMENSIONS.HEIGHT * 0.06, // used in app also to fix aside
    position: 'sticky',
    top: '0',
    zIndex: '9999',
    alignItems: 'center',
    display: 'flex',
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    height: '100%',
  },
  logoSection: {
    display: 'flex',
    alignItems: 'center',
  },
  logoLink: {
    display: 'flex',
    alignItems: 'center',
    width: 150,
    height: 50,
    cursor: 'pointer',
  },
  logoLinkStreak: {
    width: '35%',
    height: '50%',
    paddingBottom: SPACING.SPACE_8,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
  },
  logoLinkZerodha: {
    width: '65%',
    height: '30%',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
  },
  logoLinkSeprator: {
    width: 1.5,
    height: '50%',
    backgroundColor: '#ededed',
    margin: `0 ${SPACING.SPACE_6}`,
  },
  logo: {
    height: 40,
  },
  leftSection: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flex: 1,
  },
  menuBtn: {},
  imgContainer: {
    width: '30px',
    height: '30px',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    gap: SPACING.SPACE_10,
  },
  headerLoginBtn: {
    marginRight: SPACING.SPACE_20,
    padding: `${SPACING.SPACE_8} ${SPACING.SPACE_28}`,
    '&:hover': {
      backgroundColor: theme.hover,
      borderColor: theme.hover,
    },
  },
  headerHelpBtn: {
    display: 'flex',
    alignItems: 'center',
    padding: `${SPACING.SPACE_8} ${SPACING.SPACE_28}`,
    '&:hover': {
      backgroundColor: theme.hover,
      borderColor: theme.hover,
    },
  },
  heatMapBtn: {
    display: 'flex',
    alignItems: 'center',
    padding: `${SPACING.SPACE_8} ${SPACING.SPACE_28}`,
    '&:hover': {
      backgroundColor: COLORS.HEATMAP_BTN,
      borderColor: COLORS.HEATMAP_BTN,
    },
  },
  heatMapBtnMobile: {
    display: 'flex',
    alignItems: 'center',
    padding: `${SPACING.SPACE_8} ${SPACING.SPACE_12}`,
    position: 'absolute',
    borderRadius: '50%',
    right: 0,
  },
  headerAcademy: {
    display: 'flex',
    alignItems: 'center',
  },
  academyLogo: {
    width: 10,
    marginRight: SPACING.SPACE_8,
  },
  headerGetStarted: {},
  // navBar styles
  headerNav: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    width: '100%',
    maxWidth: 340,
  },
  navLink: {
    // margin: 'auto 20px',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px 0',
    display: 'inline-flex',
    borderRadius: '8px',
    '&:hover ': {
      backgroundColor: theme.hover,
      '& > svg': {
        fill: theme.primaryLink,
      },
    },
  },
  activeNavLink: {
    '& $activeBar': {
      backgroundColor: theme.primaryLink,
      transition: 'all 0.8s',
    },
    '& > svg': {
      fill: theme.primaryLink,
    },
    '&:hover ': {
      backgroundColor: 'unset',
      '& > svg': {
        fill: theme.primaryLink,
      },
    },
  },
  profileImgBtn: {
    cursor: 'default',
  },
  activeBar: {
    height: 4,
    backgroundColor: theme.primaryLink,
    bottom: 0,
    position: 'absolute',
    transition: 'all 0.3s var(--anim-func-bouncy)',
  },
  imgContainerNav: {
    width: '30px',
    height: '30px',
    flex: 'unset',
  },
  navLinkNotif: {
    marginRight: SPACING.SPACE_12,
    position: 'relative',
    borderRadius: '50%',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    height: 35,
    width: 35,
    '&:hover': {
      backgroundColor: theme.hover,
    },
  },
  showNotifPopup: {
    position: 'relative',
    '&:hover': {
      '& $notifPopup': {
        visibility: 'visible',
        opacity: 1,
      },
    },
  },
  activeNavLinkNotif: {
    '&:hover': {
      backgroundColor: 'transparent !important',
    },
  },
  currentBroker: {
    display: 'flex',
    alignItems: 'center',
    margin: `0 ${SPACING.SPACE_10} 0 0`,
    padding: `${SPACING.SPACE_4} ${SPACING.SPACE_8}`,
    borderRadius: 6,
    '&:hover': {
      backgroundColor: theme.hover,
    },
  },
  logoImg: {
    width: 16,
    marginRight: SPACING.SPACE_8,
  },
  logoIcon: {
    marginRight: SPACING.SPACE_8,
  },
  brokerRow: {
    display: 'flex',
    alignItems: 'center',
    padding: `${SPACING.SPACE_10} ${SPACING.SPACE_20}`,
    minWidth: 180,
    '&:hover': {
      backgroundColor: theme.hover,
    },
  },
  linkBrokerModal: {
    height: '100%',
  },
  brokerInputContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  routeToProfileBtn: {
    padding: `${SPACING.SPACE_8} ${SPACING.SPACE_20}`,
    '&:hover': {
      backgroundColor: theme.hover,
    },
    fontSize: FONTS.REGULAR,
    fontWeight: FONTWEIGHT.MEDIUM,
    marginTop: SPACING.SPACE_6,
    display: 'block',
    width: '100%',
    boxSizing: 'border-box',
    textAlign: 'left',
  },
  routeToProfile: {
  },
  switchDomainBtn: {
    padding: `${SPACING.SPACE_8} ${SPACING.SPACE_20}`,
    '&:hover': {
      backgroundColor: theme.hover,
    },
    display: 'block',
    width: '100%',
    boxSizing: 'border-box',
    textAlign: 'left',
  },
  takeTourBtn: {
    padding: `${SPACING.SPACE_8} ${SPACING.SPACE_20}`,
    '&:hover': {
      backgroundColor: theme.hover,
    },
    display: 'block',
    width: '100%',
    boxSizing: 'border-box',
    textAlign: 'left',
  },
  switchThemeBtn: {
    padding: `${SPACING.SPACE_8} ${SPACING.SPACE_20}`,
    '&:hover': {
      backgroundColor: theme.hover,
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    boxSizing: 'border-box',
    textAlign: 'left',
    '&:hover > svg': {
      fill: theme.linkWhite,
    },
  },
  takeTour: {
  },
  brokerContainer: {
    minWidth: 75,
  },
  brokerLoader: {
    maxWidth: '70px',
    height: '20px',
    marginRight: SPACING.SPACE_10,
  },
  notifPopup: {
    position: 'absolute',
    top: 34,
    backgroundColor: theme.dropdownBg,
    overflow: 'hidden',
    borderRadius: 5,
    visibility: 'hidden',
    opacity: 0,
    right: '-106px',
    boxShadow: `0px 3px 20px ${theme.boxShadow}`,
    padding: `${SPACING.SPACE_10} 0`,
  },
  sideMenuContainer: {
    height: '100%',
    // padding: `${SPACING.SPACE_28} 0 ${SPACING.SPACE_20}`,
    textAlign: 'center',
    position: 'relative',
    '& > button': {
      marginBottom: SPACING.SPACE_16,
    },
  },
  quickNavToggleBtn: {
    padding: `${SPACING.SPACE_10} 0`,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  logoHeaderImg: {
    height: '50%',
    margin: '12px 0 0px 30px',
  },
  niftyDetailsContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  niftyDetailsContainerMobile: {
    display: 'flex',
  },
  niftyTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  niftyTitleMobile: {
    display: 'flex',
    alignItems: 'center',
  },
  niftyContainerHeader: {
    display: 'flex',
    alignItems: 'center',
    gap: SPACING.SPACE_30,
    marginTop: SPACING.SPACE_2,
  },
  niftyContainerHeaderMobile: {
    display: 'flex',
    alignItems: 'center',
    marginTop: SPACING.SPACE_16,
    width: '80%',
  },
  marqueeTitle: {
    display: 'flex',
    gap: SPACING.SPACE_10,
  },
  niftyDetails: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: SPACING.SPACE_10,
  },
  niftyltp: {
    fontSize: `${SPACING.SPACE_14} !important`,
  },
  niftychange: {
    fontSize: `${SPACING.SPACE_12} !important`,
  },
  mobileTimeFrameBorder: {
    display: 'none',
    position: 'relative',
    top: 5,
    borderTop: `1px solid ${theme.inputBorder}`,
    opacity: 0.15,
  },
  '@media only screen and (min-width: 1441px)': {
    niftyDetailsContainer: {
      flexDirection: 'row !important',
    },
    niftyTitle: {
      width: '100% !important',
    },
  },
  '@media only screen and (min-width: 1366px)': {
    niftyDetailsContainer: {
      flexDirection: 'column',
    },
    niftyTitle: {
      width: '155px',
    },
  },
  '@media only screen and (max-width: 1164px)': {
    niftyDetailsContainer: {
      flexDirection: 'column',
    },
    niftyTitle: {
      width: '155px',
    },
  },
  // '@media only screen and (max-width: 415px)': {
  //   niftyContainerHeaderMobile: {
  //     width: '50%',
  //   },
  // },
  // '@media only screen and (max-width: 360px)': {
  //   niftyContainerHeaderMobile: {
  //     width: '40%',
  //   },
  // },
  '@media only screen and (max-width: 786px)': {
    header: {
      // height: 88,
    },
    headerContainer: {
      flexDirection: 'column',
      marginTop: SPACING.SPACE_20,
    },
    headerNav: {
      margin: '0 auto',
      maxWidth: 500,
    },
    headerAuth: {
      top: 'unset',
      position: 'fixed',
      width: '100%',
      marginBottom: 0,
      bottom: 0,
      zIndex: 9999,
    },
    logoLink: {
      width: 150,
      height: 50,
      marginTop: SPACING.SPACE_20,
    },
    headerGetStartedMobile: {
      borderWidth: '0 0 1px 0',
      borderRadius: 0,
      padding: '2px 4px',
      marginRight: 20,
    },
    headerAcademy: {
      margin: 'auto',
    },
    sideMenuHeader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      height: 30,
    },
    sideMenuBtnClose: {
      // position: 'absolute',
      top: 6,
      right: 0,
      position: 'relative',
      padding: '10px 16px 6px',
    },
    sideMenuContent: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      height: 'calc(100% - 40px)',
      '& button': {
        padding: 10,
        textAlign: 'left',
        borderBottom: `1px solid ${theme.borderColor}`,
        margin: '0 20px',
        width: 'calc(100% - 40px)',
        boxSizing: 'border-box',
      },
    },
    smLoginBtn: {
      marginBottom: `${SPACING.SPACE_16} !important`,
      textAlign: 'center !important',
    },
    smGetStarted: {
      textAlign: 'center !important',
      marginBottom: `${SPACING.SPACE_20} !important`,
    },
    headerLoginBtn: {
      marginRight: 0,
    },
    imgContainerNav: {
      width: '26px',
      height: '26px',
    },
  },
  '@media only screen and (max-width: 375px)': {
    logoLink: {
      width: '120px',
    },
  },
  '@media only screen and (max-width: 325px)': {
    logoLink: {
      width: '100px',
    },
  },
})

const mapStateToProps = (state) => {
  return {
    isAuthenticated: true,
    universalTimeframe: state.common.universalTimeframe,
    universalIndex: state.common.universalIndex,
    heatmapData: state.dashboard.heatmapData,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    pollUserHandle: (params, headers) => dispatch(pollUser(params, headers)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(stylesheet)(ScreenHeader))
