import { takeLatest, put, call } from 'redux-saga/effects'
import { prodEndpoints, baseUrl } from '../../utils/apiEndpoints'
import { getRequest, postRequest } from '../../utils/apiRequests'
import {
  fetchMarketAlgosSuccess,
  fetchMarketAlgosFailure,
  marketPlaceChartsSuccess,
  marketPlaceChartsFailure,
  deployPublishedSuccess,
  deployPublishedFailure,
  updatePublicationStatsSuccess,
  updatePublicationStatsFailure,
  subscribeAlgoSuccess,
  subscribeAlgoFailure,
  subscribedChartsSuccess,
  subscribedChartsFailure,
  fetchSubcribedAlgoSuccess,
  fetchSubcribedAlgoFailure,
  filterSubscribedAlgoFailure,
  filterSubscribedAlgoSuccess,
  deployAlgoSuccess,
  deployAlgoFailure,
  fetchDiscoverCategoriesSuccess,
  fetchDiscoverCategoriesFailure,
  paperTradeMarketSuccess,
  paperTradeMarketFailure,
} from './actions'
import {
  FETCH_MARKET_ALGOS_INIT,
  FETCH_MARKETPLACE_CHARTS_INIT,
  DEPLOY_PUBLISHED,
  UPDATE_PUBLICATION_STATS,
  SUBSCRIBE_ALGO_INIT,
  FETCH_SUBSCRIBED_CHARTS_INIT,
  FETCH_SUBSCRIBED_ALGO_INIT,
  SUBSCRIBE_AND_DEPLOY_INIT,
  MARKET_DEPLOY_STOCKS_INIT,
  FILTER_SUBSCRIBED_ALGO,
  FETCH_DISCOVER_CATEGORY,
  FETCH_ONBOARDING_ALGOS_INIT,
  PAPER_TRADE_MARKET,
} from './actionTypes'

import { fetchAlgoInstrument, getInstruments } from '../Instruments/actions'
// import { deployAlgoStocks } from '../Backtests/actions'
import { GRAPH_FETCH_COUNT } from '../../utils/consts'
import { ALGO_KEY_MAP } from '../../components/AlgoCondensedView/AlgoUtils'
import { error_msg } from '../../utils/common'
import { NAVIGATIONS } from '../../utils/navigationConstant'

function* fetchMarketAlgos(action) {
  const { headers, params, detailed } = action
  try {
    const { kind } = params
    const url = `${baseUrl}${prodEndpoints.marketplace}`
    const data = yield call(postRequest, url, params, headers)
    if (!data.error && data.status === 'success') {
      yield put(fetchMarketAlgosSuccess(data, kind, detailed, params))
      if (detailed) {
        yield put(getInstruments(
          fetchAlgoInstrument(null, data.algo.slice(0, 12)),
          {},
          NAVIGATIONS.DISCOVER.name,
        ))
      }
    } else {
      yield put(fetchMarketAlgosFailure(data.error, detailed))
    }
  } catch(err) {
    yield put(fetchMarketAlgosFailure(err.message || error_msg, detailed))
  }
}

export function* fetchMarketAlgosSaga() {
  yield takeLatest(FETCH_MARKET_ALGOS_INIT, fetchMarketAlgos)
}

function* fetchOnboardingAlgos(action) {
  const { headers, params } = action
  try {
    const { kind } = params
    const url = `${baseUrl}${prodEndpoints.marketplace}`
    const data = yield call(getRequest, url, params, headers)
    if (!data.error && data.status === 'success') {
      yield put(fetchMarketAlgosSuccess(data, kind))
    } else {
      yield put(fetchMarketAlgosFailure(data.error))
    }
  } catch (err) {
    yield put(fetchMarketAlgosFailure(err.message || error_msg))
  }
}

export function* fetchOnboardingAlgosSaga() {
  yield takeLatest(FETCH_ONBOARDING_ALGOS_INIT, fetchOnboardingAlgos)
}

function* marketPlaceCharts(action) {
  const { headers, params } = action
  try {
    const url = `${baseUrl}${prodEndpoints.fetch_marketplace_chart}`
    for (let i = 0; i < params.length; i++) {
      const idObj = params[i]
      if (idObj && Object.values(idObj).length) {
        const data = yield call(
          getRequest, url, { ...idObj, max_count: GRAPH_FETCH_COUNT }, headers,
        )
        if (!data.error) {
          yield put(marketPlaceChartsSuccess(data, idObj[ALGO_KEY_MAP.DISCOVER]))
        } else {
          yield put(marketPlaceChartsFailure(data.error, idObj[ALGO_KEY_MAP.DISCOVER]))
        }
      }
    }
  } catch(err) {
    yield put(marketPlaceChartsFailure(err.message || error_msg))
  }
}

export function* marketPlaceChartsSaga() {
  yield takeLatest(FETCH_MARKETPLACE_CHARTS_INIT, marketPlaceCharts)
}

function* deployPublished(action) {
  const { headers, params, noDeploy } = action
  try {
    let publishParams = {
      algo_name: params.name,
      publishing_uuid: params.publishing_uuid,
      csrfmiddlewaretoken: params.csrfmiddlewaretoken,
    }
    if (params.copyWithStocks) {
      publishParams = { ...publishParams, stocks: params.stocks }
    }
    const url = `${baseUrl}${prodEndpoints.clone_published}`
    const data = yield call(postRequest, url, publishParams, headers)
    if (!data.error && data.status === 'success') {
      const newId = data.algo_uuid
      if (newId && !noDeploy) {
        delete params.publishing_uuid
        delete params.name
        // DANGER
        // const newParams = {
        //   ...params,
        //   algo_uuid: newId,
        // }
        // yield put(deployAlgoStocks(newParams, headers))
      } else {
        yield put(deployPublishedSuccess(data))
      }
    } else {
      yield put(deployPublishedFailure(data.error))
    }
  } catch(err) {
    yield put(deployPublishedFailure(err.message || error_msg))
  }
}

export function* deployPublishedSaga() {
  yield takeLatest(DEPLOY_PUBLISHED, deployPublished)
}

function* updatePublicationStats(action) {
  const { params } = action
  try {
    const url = `${baseUrl}${prodEndpoints.publication_action}`
    const data = yield call(postRequest, url, params, {})
    if (!data.error && data.status === 'success') {
      yield put(updatePublicationStatsSuccess(data))
    } else {
      yield put(updatePublicationStatsFailure(data.error))
    }
  } catch(err) {
    yield put(updatePublicationStatsFailure(err.message || error_msg))
  }
}

export function* updatePublicationStatsSaga() {
  yield takeLatest(UPDATE_PUBLICATION_STATS, updatePublicationStats)
}

function* subscribeAlgo(action) {
  const { headers, params } = action
  try {
    const url = `${baseUrl}${prodEndpoints.subscribeAlgo}`
    const data = yield call(postRequest, url, params, headers)
    if (!data.error && data.status === 'success') {
      yield put(subscribeAlgoSuccess(data))
    } else {
      yield put(subscribeAlgoFailure(data.error))
    }
  } catch(err) {
    yield put(subscribeAlgoFailure(err.message || error_msg))
  }
}

export function* subscribeAlgoSaga() {
  yield takeLatest(SUBSCRIBE_ALGO_INIT, subscribeAlgo)
}

function* fetchSubscribedCharts(action) {
  const { headers, params } = action
  try {
    const url = `${baseUrl}${prodEndpoints.fetch_marketplace_chart}`
    for (let i = 0; i < params.length; i++) {
      const idObj = params[i]
      if (idObj && Object.values(idObj).length) {
        const data = yield call(
          getRequest, url, { ...idObj, max_count: GRAPH_FETCH_COUNT }, headers,
        )
        if (!data.error) {
          yield put(subscribedChartsSuccess(data, idObj[ALGO_KEY_MAP.SUBSCRIBED]))
        } else {
          yield put(subscribedChartsFailure(data.error, idObj[ALGO_KEY_MAP.SUBSCRIBED]))
        }
      }
    }
  } catch(err) {
    yield put(subscribedChartsFailure(err.message || error_msg))
  }
}

export function* fetchSubscribedChartsSaga() {
  yield takeLatest(FETCH_SUBSCRIBED_CHARTS_INIT, fetchSubscribedCharts)
}

function* fetchSubscribedAlgo(action) {
  const { headers, params, detailed } = action
  try {
    const {
      kind, algo_subscription_uuids = [], page, page_limit, csrfmiddlewaretoken, resp, total_pages,
      search = '', filter = '',
    } = params
    let final_params = {
      page, page_limit, csrfmiddlewaretoken, resp, search, filter,
    }
    if (algo_subscription_uuids.length) {
      final_params = {
        page: 1, page_limit, csrfmiddlewaretoken, resp, algo_subscription_uuids, search, filter,
      }
    }
    const url = `${baseUrl}${prodEndpoints.subscribed_algos}`
    const data = yield call(postRequest, url, final_params, headers)
    if (!data.error && data.status === 'success') {
      yield put(fetchSubcribedAlgoSuccess(
        { algo: data.algo, pages: total_pages || data.pages }, kind, detailed, params,
      ))
      if (detailed) {
        yield put(getInstruments(
          fetchAlgoInstrument(null, data.algo.slice(0, 12)),
          {},
          NAVIGATIONS.SUBSCRIBED.name,
        ))
      }
    } else {
      yield put(fetchSubcribedAlgoFailure(data.error, detailed))
    }
  } catch(err) {
    yield put(fetchSubcribedAlgoFailure(err.message || error_msg, detailed))
  }
}

export function* fetchSubscribedAlgoSaga() {
  yield takeLatest(FETCH_SUBSCRIBED_ALGO_INIT, fetchSubscribedAlgo)
}

function* subscribeAndDeployAlgo(action) {
  try {
    const { params, headers } = action
    const {
      publishing_uuid = '',
      csrfmiddlewaretoken,
    } = params
    const subParams = {
      csrfmiddlewaretoken,
      publishing_uuid,
    }

    const url = `${baseUrl}${prodEndpoints.subscribeAlgo}`
    const data = yield call(postRequest(url, subParams, headers))
    if (!data.error && data.status === 'success') {
      yield put(subscribeAlgoSuccess(data))
    } else if (!data.algo_subscription_uuid) {
      yield put(subscribeAlgoFailure(data.error))
    }
    const {
      algo_subscription_uuid = '',
    } = data
    const newParams = {
      ...params,
      algo_subscription_uuid,
    }
    if (algo_subscription_uuid) {
      yield put(deployAlgo(newParams, headers))
    }
  } catch (err) {
    yield put(subscribeAlgoFailure(err.message || error_msg))
  }
}

export function* subscribeAndDeployAlgoSaga() {
  yield takeLatest(SUBSCRIBE_AND_DEPLOY_INIT, subscribeAndDeployAlgo)
}

function* deployAlgo(action) {
  try {
    const { params, headers } = action
    const { algo_subscription_uuid } = params
    const subsData = yield call(getRequest, `${prodEndpoints.get_subscription_limit}?algo_uuid=${algo_subscription_uuid}&resp=json`, headers)
    if (!subsData.error) {
      const { status, deployments_remaining } = subsData
      if (status === 'success' && deployments_remaining >= params.seg_sym_list.length) {
        const url = `${baseUrl}${prodEndpoints.marketplace_deploy}`
        const data = yield call(postRequest, url, params, headers)
        if (!data.error && data.status === 'success') {
          yield put(deployAlgoSuccess(data))
        } else {
          yield put(deployAlgoSuccess(data.error))
        }
      } else {
        yield put(deployAlgoFailure('Deployment limit reached'))
      }
    } else {
      yield put(deployAlgoFailure('Deployment limit reached'))
    }
  } catch (err) {
    yield put(deployAlgoFailure(err.message || error_msg))
  }
}

export function* deployAlgoSaga() {
  yield takeLatest(MARKET_DEPLOY_STOCKS_INIT, deployAlgo)
}

function* filterSubscribedAlgos(action) {
  try {
    const { params, fetchAlgoPayload } = action
    const url = prodEndpoints.filter_subscribed_algos
    const data = yield call(postRequest, url, JSON.stringify(params), { 'content-type': 'application/json' }, false)
    if (!data.error && data.data) {
      const { total_pages, results } = data.data
      if (results) {
        const algo_subscription_uuids = results.map(item => item.algo_subscription_uuid)
        if (algo_subscription_uuids.length === 0) {
          yield put(fetchSubcribedAlgoSuccess(
            { algo: [], pages: total_pages }, fetchAlgoPayload.kind, true, fetchAlgoPayload,
          ))
        } else {
          yield put({
            type: FETCH_SUBSCRIBED_ALGO_INIT,
            params: {
              ...fetchAlgoPayload,
              total_pages,
              algo_subscription_uuids,
            },
            detailed: true,
          })
        }

        yield put(filterSubscribedAlgoSuccess('No strategy found matching the filter selection', algo_subscription_uuids))
      }
    } else {
      yield put(filterSubscribedAlgoFailure(data.error_msg))
    }
  } catch (err) {
    yield put(filterSubscribedAlgoFailure(err.message || error_msg))
  }
}
export function* filterSubscribedAlgosSaga() {
  yield takeLatest(FILTER_SUBSCRIBED_ALGO, filterSubscribedAlgos)
}

function* fetchDiscoverCategories() {
  try {
    const url = prodEndpoints.marketplace_tags
    const data = yield call(getRequest, url, {})
    if (data.status === 'success' && !data.error) {
      yield put(fetchDiscoverCategoriesSuccess(data))
    } else {
      yield put(fetchDiscoverCategoriesFailure(data.error_msg))
    }
  } catch (err) {
    yield put(fetchDiscoverCategories(err.message))
  }
}

export function* fetchDiscoverCategoriesSaga() {
  yield takeLatest(FETCH_DISCOVER_CATEGORY, fetchDiscoverCategories)
}

function* paperTradeMarket(action) {
  try {
    const { params } = action
    const url = prodEndpoints.follow_deployment
    const data = yield call(postRequest, url, params)
    if (data.status === 'success' && !data.error) {
      yield put(paperTradeMarketSuccess(data))
    } else {
      yield put(paperTradeMarketFailure(data.error_msg))
    }
  } catch (err) {
    yield put(paperTradeMarket(err.message))
  }
}

export function* paperTradeMarketSaga() {
  yield takeLatest(PAPER_TRADE_MARKET, paperTradeMarket)
}
