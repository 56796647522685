import {
  FETCH_SUMMARY_INIT,
  FETCH_SUMMARY_SUCCESS,
  FETCH_SUMMARY_FAILURE,
  FETCH_HEAT_MAP_SUCCESS,
  FETCH_HEAT_MAP,
  FETCH_HEAT_MAP_FAILURE,
  FETCH_NIFTY_50_HEAT_MAP,
  FETCH_NIFTY_50_HEAT_MAP_SUCCESS,
  FETCH_NIFTY_50_HEAT_MAP_FAILURE,
  FETCH_NIFTY_BANK_HEAT_MAP,
  FETCH_NIFTY_BANK_HEAT_MAP_SUCCESS,
  FETCH_NIFTY_BANK_HEAT_MAP_FAILURE,
} from './actionTypes'

export function fetchSummary(params) {
  return {
    type: FETCH_SUMMARY_INIT,
    params,
  }
}

export function fetchSummarySuccess(data) {
  return {
    type: FETCH_SUMMARY_SUCCESS,
    data,
  }
}

export function fetchSummaryFailure(error) {
  return {
    type: FETCH_SUMMARY_FAILURE,
    error,
  }
}

export function updateHeatMap(params) {
  return {
    type: FETCH_HEAT_MAP,
    params,
  }
}

export function updateHeatMapSuccess(data, index) {
  return {
    type: FETCH_HEAT_MAP_SUCCESS,
    data,
    index,
  }
}

export function updateHeatMapFailure(error) {
  return {
    type: FETCH_HEAT_MAP_FAILURE,
    error,
  }
}
export function updateNifty50HeatMap(params) {
  return {
    type: FETCH_NIFTY_50_HEAT_MAP,
    params,
  }
}

export function updateNifty50HeatMapSuccess(data, index) {
  return {
    type: FETCH_NIFTY_50_HEAT_MAP_SUCCESS,
    data,
    index,
  }
}

export function updateNifty50HeatMapFailure(error) {
  return {
    type: FETCH_NIFTY_50_HEAT_MAP_FAILURE,
    error,
  }
}
export function updateNiftyBankHeatMap(params) {
  return {
    type: FETCH_NIFTY_BANK_HEAT_MAP,
    params,
  }
}

export function updateNiftyBankHeatMapSuccess(data, index) {
  return {
    type: FETCH_NIFTY_BANK_HEAT_MAP_SUCCESS,
    data,
    index,
  }
}

export function updateNiftyBankHeatMapFailure(error) {
  return {
    type: FETCH_NIFTY_BANK_HEAT_MAP_FAILURE,
    error,
  }
}
