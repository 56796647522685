import {
  takeLatest, put, call, takeEvery,
} from 'redux-saga/effects'
import isEmpty from 'lodash/isEmpty'
import {
  GET_WATCHLIST_INIT,
  UPDATE_WATCHLIST_INIT,
  GET_ANALYSIS_INIT,
  GET_TOP_MOVERS,
} from './actionTypes'
import { error_msg } from '../../utils/common'
import {
  getWatchListSuccess,
  getWatchListFailure,
  updateWatchListSuccess,
  updateWatchListFailure,
  getAnalysisSuccess,
  getAnalysisFailure,
  getTopMoversSuccess,
  getTopMoversFailure,
} from './actions'

import { prodEndpoints } from '../../utils/apiEndpoints'
import { getRequest, postRequest } from '../../utils/apiRequests'
import { pairsFromSegSym, getInstruments } from '../Instruments/actions'
import { NAVIGATIONS } from '../../utils/navigationConstant'

// function* directOrder(action) {
//   try {
//     const { params, headers } = action.payload
//     const url = `${prodEndpoints.direct_order}`
//     const data = yield call(postRequest, url, params, headers)
//     if (!data.error && data.status === 'success') {
//       yield put(directOrderSuccess(data))
//     } else {
//       yield put(directOrderFailure(data.error_msg || error_msg))
//     }
//   } catch (err) {
//     yield put(directOrderFailure(error_msg))
//   }
// }

// export function* directOrderSaga() {
//   yield takeLatest(DIRECT_ORDER_INIT, directOrder)
// }

function* getWatchList(action) {
  try {
    const { params, headers } = action.payload
    const url = `${prodEndpoints.market_watch}`
    const data = yield call(getRequest, url, params, headers)
    if (data.status === 'success') {
      // const instruments_list = []
      const { market_watch_name, market_watch_instruments } = data
      if (market_watch_name && market_watch_instruments) {
        const instrumentList = !market_watch_instruments || (market_watch_instruments && isEmpty(market_watch_instruments)) ? '{}' : market_watch_instruments
        // instruments_list.push(...JSON.parse(market_watch_instruments).instrumentList)
        yield put(getWatchListSuccess({
          market_watches: { [market_watch_name]: instrumentList },
        }))
      } else {
        yield put(getWatchListSuccess(data))
        // Object.keys(market_watches).forEach((item) => {
        //   if (market_watches[item]) {
        //     instruments_list.push(...JSON.parse(market_watches[item]).instrumentList)
        //   }
        // })
      }
      // const { tokens, token_keys } = marketPairs(instruments_list)
      // yield put(subscribe(tokens, token_keys, 'subscribe'))
    } else {
      yield put(getWatchListFailure(data.error_msg || 'Unable to get watchList'))
    }
  } catch (err) {
    yield put(getWatchListFailure('Unable to get watchList'))
  }
}

export function* getWatchListSaga() {
  yield takeEvery(GET_WATCHLIST_INIT, getWatchList)
}

function* updateWatchList(action) {
  try {
    const { params = {} } = action
    const url = `${prodEndpoints.market_watch}`
    const data = yield call(postRequest, url, params)
    if (!data.error && data.status === 'success') {
      yield put(updateWatchListSuccess({ ...data, ...params }))
    } else {
      yield put(updateWatchListFailure(data.error_msg || error_msg, params))
    }
  } catch (err) {
    yield put(updateWatchListFailure(error_msg, action.params))
  }
}

export function* updateTechnicalListSaga() {
  yield takeEvery(UPDATE_WATCHLIST_INIT, updateWatchList)
}

function* getAnalysis(action) {
  try {
    const { params, headers } = action
    const url = prodEndpoints.tech_analysis
    const finalParams = { ...params, stock: encodeURIComponent(params.stock) }
    const data = yield call(getRequest, url, finalParams, headers)
    if (!data.error && data.status === 1) {
      yield put(getAnalysisSuccess(data, params.stock.split(':').join('_')))
    } else {
      yield put(getAnalysisFailure(data.error_msg || 'Unable to get analysis'))
    }
  } catch (err) {
    yield put(getAnalysisFailure('Unable to get analysis'))
  }
}

export function* getAnalysisSaga() {
  yield takeLatest(GET_ANALYSIS_INIT, getAnalysis)
}

function* getTopMovers(action) {
  try {
    const { params, headers } = action
    const url = prodEndpoints.top_gainers
    const data = yield call(postRequest, url, params, headers, false)
    if (!data.error && data.data) {
      yield put(getTopMoversSuccess(data.data))
      yield put(getInstruments(
        pairsFromSegSym(null, data.data),
        {},
        NAVIGATIONS.TOP_MOVERS.name,
      ))
    } else {
      yield put(getTopMoversFailure(data.error_msg))
    }
  } catch (err) {
    yield put(getTopMoversFailure(error_msg))
  }
}

export function* getTopMoversSaga() {
  yield takeLatest(GET_TOP_MOVERS, getTopMovers)
}
