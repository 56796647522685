import isEqual from 'lodash/isEqual'
import cloneDeep from 'lodash/cloneDeep'
import isEmpty from 'lodash/isEmpty'

import dayjs from './helpers/dayjs'
import {
  candle_map, PRECISION, holding_type_map, MATH_FUNCS, BROKERS, // platformKeys
  condensed_candle_map, ORDER_TYPE, POSITION_SIZING_TYPE,
} from './consts'
import { baseUrl } from './apiEndpoints'
import { LIGHT, DARK, ICONS } from '../Theme'

const { PARTIAL_1, PARTIAL_2, PARTIAL_3 } = ICONS

export const headersGenerator = (obj, json) => {
  const headers = new Headers()
  Object.keys(obj).forEach(key => headers.append(key, obj[key]))
  headers.append('referer', `${baseUrl}dashboard_metrics/`)
  if (json) {
    headers.append('accept', 'application/json text/plain')
    headers.append('content-type', 'application/json')
  } else {
    headers.append('content-type', 'application/x-www-form-urlencoded; charset=UTF-8')
  }
  // headers.append('Cache-Control', 'no-cache, no-store, must-revalidate')
  // headers.append('Pragma', 'no-cache')
  // headers.append('Expires', 0)
  return headers
}

export const getEncodedParams = (params) => {
  const searchParams = []
  Object.keys(params).forEach((key) => {
    const encodedKey = encodeURIComponent(key)
    if (
      key === 'seg_sym_list'
      || (params[key] && params[key].constructor.name === 'Array')
    ) {
      const encodedValue = encodeURIComponent(JSON.stringify(params[key]))
      searchParams.push(`${encodedKey}=${encodedValue}`)
    } else {
      const encodedValue = encodeURIComponent(params[key])
      searchParams.push(`${encodedKey}=${encodedValue}`)
    }
  })
  return searchParams.join('&')
}

// returns a non-encoded string representation of params with `?` appended at the start.
export const getPlainQueryParams = (params, encode) => {
  let queryParamString = ''
  if (params && Object.keys(params).length) {
    queryParamString = Object.keys(params)
      .map(key => `${key}=${encode ? btoa(params[key]) : params[key]}`)
      .join('&')
    queryParamString = `?${queryParamString}`
  }
  return queryParamString
}

export const getQueryParams = (encodeUrl) => {
  // const url = decodeURIComponent(encodeUrl)
  // const query = url.split('?')[1] || ''
  const encodedParams = encodeUrl.split('?')[1] || ''
  const query = decodeURIComponent(encodedParams)
  const queryArray = query.split('&') || []
  const queries = {}
  queryArray.map((q) => {
    const keyValue = q.split('=')
    queries[keyValue[0]] = keyValue[1]
    return null
  })
  return queries
}

// used to get params from url
export const parseQuery = (str, decode) => {
  const queries = {}
  if (!str) return queries
  const index = str.indexOf('?')
  let query = str.substring(index + 1)
  query = query.split('&')
  for (let j = 0; j < query.length; j++) {
    const [key, value] = query[j].split('=')
    if (decode) {
      try {
        queries[key] = atob(value)
      } catch (e) {
        queries[key] = value
      }
    } else if (key === 'stock') {
      queries.stock = decodeURIComponent(value).split(',')
      // if (queries.stock) {
      //   queries.stock.push(decodeURIComponent(value))
      // } else {
      //   queries.stock = [value]
      // }
    } else {
      queries[key] = decodeURIComponent(value)
    }
  }
  return queries
}

export const mergeHeaders = (defaultHeaders, newheaders) => {
  const mergedHeaders = cloneDeep(defaultHeaders)
  const headersLocation = newheaders instanceof Headers ? newheaders.map : newheaders
  if (!isEmpty(headersLocation)) {
    Object.keys(newheaders).forEach((head) => {
      mergedHeaders.set(head, headersLocation[head])
    })
  }
  return mergedHeaders
}

export const validateMail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

export const formEntryExitString = (algoObj) => {
  const {
    position_type, positionType, ip_interval, quantity = 0, time_frame = '', chart_type = 'candlestick',
    stop_loss = 0, take_profit = 0, trading_start_time = '00:00', trading_stop_time = '23:59',
    entry_logic, exit_logic, tpsl_type, max_allocation, position_sizing_type, position_qty: qty,
    action_str, action_str_exit, action_type, candleInterval, stopLoss = 0, takeProfit = 0,
    trailing_sl,
  } = algoObj
  let amount = ''
  let exitStr = ''
  let entryStr = ''
  const final_tp = take_profit || takeProfit
  const final_sl = stop_loss || stopLoss
  const final_postion_type = position_type || positionType
  let final_entry_str = action_str
  let final_exit_str = action_str_exit
  let final_action_type = action_type
  const final_time_frame = candleInterval ? candle_map[candleInterval] : (time_frame || ip_interval)
  let interval = ''
  if (typeof final_postion_type === 'number') {
    final_entry_str = entry_logic || action_str
    final_exit_str = exit_logic || action_str_exit
    final_action_type = final_postion_type === 1 ? 'BUY' : 'SELL'
  }
  if (typeof final_postion_type === 'string') {
    final_entry_str = entry_logic || action_str
    final_exit_str = exit_logic || action_str_exit
    final_action_type = final_postion_type
  }
  final_action_type = final_action_type || 'BUY'
  const f_quantity = qty || quantity
  try {
    if (max_allocation) {
      let maxAllocationLabel = 'max allocation'
      if (position_sizing_type === POSITION_SIZING_TYPE.RISK_BASED) { maxAllocationLabel = 'max SL per trade' }
      amount = `with ${maxAllocationLabel} ${max_allocation}`
    } else {
      amount = Math.abs(f_quantity) > 1 ? `${f_quantity} shares` : '1 share'
    }
    const userAction = final_action_type.toUpperCase()
    const userExit = final_action_type.toUpperCase() === 'BUY' ? 'SELL' : 'BUY'
    interval = final_time_frame.toLowerCase()
    const { slLabel: sl, tpLabel: tp, tslLabel: tsl } = getAdvancedParamsLabel({ tpsl_type })
    const slLabel = sl.toLowerCase()
    const tpLabel = tp.toLowerCase()
    entryStr = final_entry_str ? `${userAction} ${amount} when ${final_entry_str} at ${interval} candle interval using ${chart_type} chart.
\nEnter trade between ${trading_start_time} to ${trading_stop_time}` : ''
    if (final_exit_str) {
      exitStr = `${userExit} ${amount} when ${final_exit_str} or at ${slLabel} of ${final_sl} or \
${tpLabel} of ${final_tp} ${trailing_sl && trailing_sl !== '0' ? `with a ${tsl} of ${trailing_sl}` : ''} at ${interval} candle interval using ${chart_type} chart.`
    } else if (final_sl || final_tp) {
      exitStr = `${userExit} ${amount} at ${slLabel} of ${final_sl} \
or ${tpLabel} of ${final_tp} ${trailing_sl && trailing_sl !== '0' ? `with a ${tsl} of ${trailing_sl}` : ''} at ${interval} candle interval using ${chart_type} chart.`
    }
  } catch (err) {
    console.log('summary generator error', err)
    return {
      entryStr: '', exitStr: '', interval: '', quantity: 1,
    }
  }
  return {
    entryStr,
    exitStr,
    interval,
    quantity: f_quantity,
    final_entry_str,
    final_exit_str,
  }
}

export const algoSummaryGenerator = (algoObj) => {
  const {
    dt_start,
    dt_stop,
    algo_name,
    chart_type = 'candlestick',
    updated_at,
  } = algoObj
  const { entryStr, exitStr, interval } = formEntryExitString(algoObj)

  return {
    entryStr,
    exitStr,
    deployTime: dt_stop || dt_start || updated_at,
    algoName: algo_name,
    algo_name,
    chart_type,
    interval,
  }
}

export const orderSummaryGenerator = (algoObj = {}) => {
  const {
    dt_start = '',
    dt_stop = '',
    algo_name = '',
    time_frame,
    chart_type = 'candlestick',
  } = algoObj
  if (algoObj === undefined) {
    return {
      entryStr: '',
      exitStr: '',
      deployTime: dt_stop || dt_start,
      algoName: algo_name,
      algo_name,
      chart_type,
      time_frame,
    }
  }
  const { entryStr, exitStr } = formEntryExitString(algoObj)

  return {
    entryStr,
    exitStr,
    deployTime: dt_stop || dt_start,
    algoName: algo_name,
    algo_name,
    chart_type,
    time_frame,
  }
}

export const completeAlgoSummary = (algoObj = {}) => {
  const {
    start_time: dt_start = '',
    stop_time: dt_stop = '',
    algo_name,
    chart_type = 'candlestick',
    updated_at,
    algo_desc,
    holding_type,
    order_type = 'MARKET',
  } = algoObj
  const {
    entryStr, exitStr, interval, quantity,
  } = formEntryExitString(algoObj)

  return {
    entryStr,
    exitStr,
    deployTime: dt_stop || dt_start || updated_at,
    algoName: algo_name,
    algo_name,
    chart_type,
    interval,
    quantity,
    defaultQuantity: quantity,
    algo_desc: algo_desc === 'null' ? '' : algo_desc,
    holding_type: holding_type || holding_type_map.MIS,
    order_type,
  }
}

export const getBacktestDetails = (backtest) => {
  const {
    algo_name,
    algo_uuid,
    entry_logic,
    exit_logic,
    trading_start_time,
    trading_stop_time,
    take_profit,
    stop_loss,
    ip_interval,
    min_candle_freq,
    chart_type,
    position_type,
    start_time,
    stop_time,
    position_qty,
    equities,
    dt_start,
    dt_stop,
    create_plus,
    order_type = ORDER_TYPE.MARKET,
    holding_type,
    initial_capital = 10000000,
    position_sizing_type,
    daily_strategy_cycle,
    tpsl_type,
    max_allocation,
    trailing_sl,
  } = backtest
  const details = {
    algo_name,
    algo_uuid,
    chart_type,
    entry_logic,
    exit_logic,
    equities,
    time_frame: ip_interval,
    ip_interval,
    position_type,
    action_type: position_type,
    dt_start: dt_start || start_time,
    dt_stop: dt_stop || stop_time,
    stop_time,
    start_time,
    stop_loss,
    take_profit,
    action_str_exit: exit_logic,
    action_str: entry_logic,
    quantity: position_qty,
    min_candle_freq,
    trading_start_time,
    trading_stop_time,
    create_plus: !!create_plus,
    order_type: Object.values(ORDER_TYPE).some(item => item === order_type.toUpperCase())
      ? order_type : ORDER_TYPE.MARKET,
    holding_type: holding_type || holding_type_map.MIS,
    initial_capital,
    position_sizing_type,
    daily_strategy_cycle,
    tpsl_type,
    max_allocation,
    trailing_sl,
  }
  return details
}

export const separateActiveStocks = (stocks) => {
  const activeWaitingStocks = []
  const activeEnteredStocks = []
  for (let i = 0; i < stocks.length; i++) {
    const stock = stocks[i]
    if (
      stock.status === 0
      && stock.algorithm_position
      && Number(stock.algorithm_position.positions.qty) !== 0
    ) {
      activeEnteredStocks.push(stock)
    } else {
      activeWaitingStocks.push(stock)
    }
  }
  return { activeWaitingStocks, activeEnteredStocks }
}

export const error_msg = 'Error getting response'

export const exchange_mapping = {
}

export const exchange_sector_mapping = {
  COINBASE: 'crypto',
  BITFINEX: 'crypto',
  BINANCE: 'crypto',
  POLONIEX: 'crypto',
  HUOBI: 'crypto',
  CCCAGG: 'crypto',
  HITBTC: 'crypto',
  BITHUMB: 'crypto',
  BITBANK: 'crypto',
  COINBASEPRO: 'crypto',
  OKEX: 'crypto',
  KRAKEN: 'crypto',
  ZB: 'crypto',
  BITMEX: 'crypto',
  DERIBIT: 'crypto',
  NASDAQ: 'equity',
  NYSE: 'equity',
  'BINANCE-FUTURES': 'crypto',
  'KRAKEN-FUTURES': 'crypto',
}

export const hexCodeGenerator = (str) => {
  const s = unescape(encodeURIComponent(str))
  let h = ''
  for (let i = 0; i < s.length; i++) {
    h += s.charCodeAt(i).toString(16)
  }
  return h
}

export const getPrecision = (meta = {}, seg_sym = '', exchange = '', defaultPrecision = 2) => {
  let precision = defaultPrecision
  const f_exchange = exchange === 'coinbase' ? 'coinbasepro' : exchange
  const token = `${seg_sym.toUpperCase()}_${f_exchange.toLowerCase()}`
  try {
    const metaObj = meta[token]
    if (metaObj) {
      const { quote_increment = '' } = metaObj
      const quoteIncrement = quote_increment.toString()
      const minSizeSplitArray = quoteIncrement.split('.')
      if (minSizeSplitArray.length === 2) {
        const matchResult = minSizeSplitArray[1].match(/[1-9]/)
        precision = matchResult.index + 1
      }
    }
  } catch(e) {
    // eslint-disable-next-line no-console
    // console.log('Error in getPrecision')
  }
  return precision
}

// can supply seg_sym or seg
export const getPrecisionNew = (seg_sym) => {
  const precision = seg_sym.toLowerCase().includes('cds') ? 4 : PRECISION
  return precision
}

// https://fbs.com/analytics/guidebooks/currency-pairs-base-and-quote-currencies-majors-and-crosses-15
export const getMetaObj = (meta = {}, seg_sym = '', exchange = '') => {
  let quotePrecision = 4
  let basePrecision = 2
  let quoteCurrency = ''
  let baseCurrency = ''
  let orderTypes = []

  const f_exchange = exchange === 'coinbase' ? 'coinbasepro' : exchange
  const token = `${seg_sym.toUpperCase()}_${f_exchange.toLowerCase()}`

  try {
    const metaObj = meta[token]
    // if(token === 'DERIBIT_ETH-PERPETUAL_deribit') {
    //   console.log('getMetaObj', meta, metaObj, token)
    // }
    if (metaObj) {
      const {
        quote_increment = '', quote_currency = '', base_currency = '', base_min_size = '', order_types = [],
      } = metaObj
      baseCurrency = base_currency
      quoteCurrency = quote_currency
      orderTypes = order_types
      const quoteIncrement = quote_increment.toString()
      const minSizeSplitArray = quoteIncrement.split('.')
      // console.log('minSizeSplitArray', minSizeSplitArray, token)
      if (minSizeSplitArray.length === 2) {
        const matchResult = minSizeSplitArray[1].match(/[1-9]/)
        // console.log('matchResult', matchResult, token)
        quotePrecision = matchResult.index + 1
      }
      const minSize = base_min_size.toString()
      const baseSizeSplitArray = minSize.split('.')
      if (baseSizeSplitArray.length === 2) {
        if(parseInt(baseSizeSplitArray[1], 10) > 0) {
          const matchResult = baseSizeSplitArray[1].match(/[1-9]/)
          basePrecision = matchResult.index + 1
        }else{
          basePrecision = 0
        }
      }
    }
  }catch(e) {
    // eslint-disable-next-line no-console
    // console.log('Error in getPrecision')
  }

  const returnObj = {
    quotePrecision,
    quoteCurrency,
    orderTypes,
    baseCurrency,
    basePrecision,
  }

  return returnObj
}

export const navigationOptions = (screen, passProps = {}) => {
  return {
    component: {
      name: screen,
      passProps,
    },
  }
}

export const areEqual = (prevProps, nextProps) => {
  return isEqual(prevProps, nextProps)
}

export const convertToIndicatorKeyName = (name) => {
  return name.trim().replace(/ /g, '-').toLowerCase()
}

export const formatAnalysis = (analysis) => {
  let maBuy = 0
  let maSell = 0
  let maNeutral = 0
  let oBuy = 0
  let oSell = 0
  let oNeutral = 0
  const newAnalysis = { ...analysis }
  const {
    rec_stochastic_k,
    rec_stochastic_rsi_fast,
    rec_ao,
    rec_adx,
    rec_macd,
    rec_mom,
    rec_cci,
    close,
    ema10,
    ema100,
    ema20,
    ema200,
    ema30,
    ema5,
    ema50,
    hma,
    rec_ichimoku,
    rec_ult_osc,
    rec_willR,
    rec_rsi,
    sma10,
    sma100,
    sma20,
    sma200,
    sma30,
    sma5,
    sma50,
    vwma,
  } = newAnalysis
  const maArr = [
    ema5,
    sma5,
    ema10,
    sma10,
    ema20,
    sma20,
    ema30,
    sma30,
    ema50,
    sma50,
    ema100,
    sma100,
    ema200,
    sma200,
    vwma,
    hma,
  ]
  maArr.forEach((item) => {
    if (item > close && item !== 0) {
      maSell++
    } else if (item < close && item !== 0) {
      maBuy++
    } else {
      maNeutral++
    }
  })
  if (rec_ichimoku === 1) {
    maSell++
  } else if (rec_ichimoku === -1) {
    maBuy++
  } else {
    maNeutral++
  }

  // for oscillators
  if (rec_rsi === 1) {
    oBuy++
  } else if (rec_rsi === -1) {
    oSell++
  } else {
    oNeutral++
  }
  if (rec_ult_osc === 1) {
    oBuy++
  } else if (rec_ult_osc === -1) {
    oSell++
  } else {
    oNeutral++
  }
  if (rec_willR === 1) {
    oBuy++
  } else if (rec_willR === -1) {
    oSell++
  } else {
    oNeutral++
  }
  if (rec_adx === 1) {
    oBuy++
  } else if (rec_adx === -1) {
    oSell++
  } else {
    oNeutral++
  }
  if (rec_ao === 1) {
    oBuy++
  } else if (rec_ao === -1) {
    oSell++
  } else {
    oNeutral++
  }
  if (rec_cci === 1) {
    oBuy++
  } else if (rec_cci === -1) {
    oSell++
  } else {
    oNeutral++
  }
  if (rec_stochastic_k === 1) {
    oBuy++
  } else if (rec_stochastic_k === -1) {
    oSell++
  } else {
    oNeutral++
  }
  if (rec_stochastic_rsi_fast === 1) {
    oBuy++
  } else if (rec_stochastic_rsi_fast === -1) {
    oSell++
  } else {
    oNeutral++
  }
  if (rec_macd === 1) {
    oBuy++
  } else if (rec_macd === -1) {
    oSell++
  } else {
    oNeutral++
  }
  if (rec_mom === 1) {
    oBuy++
  } else if (rec_mom === -1) {
    oSell++
  } else {
    oNeutral++
  }
  newAnalysis.maBuy = maBuy
  newAnalysis.maSell = maSell
  newAnalysis.maNeutral = maNeutral
  newAnalysis.oBuy = oBuy
  newAnalysis.oSell = oSell
  newAnalysis.oNeutral = oNeutral
  newAnalysis.buy = maBuy + oBuy
  newAnalysis.sell = maSell + oSell
  newAnalysis.neutral = maNeutral + oNeutral
  return newAnalysis
}

export const nameGenerator = (syntax = '', details) => {
  let display_name = syntax
  const regx = /[^<>]+(?=>)/g
  const params = syntax.match(regx)
  if (params) {
    const detailValues = params.map(param => details[param])
    for (let i = 0; i < params.length; i++) {
      display_name = display_name.replace(`<${params[i]}>`, detailValues[i])
    }
  }
  return display_name
}

export const getTemplate = (type) => {
  if (type === 'func') {
    return {
      name: '',
      valid: false,
      details: {},
      indicatorDetails: {},
      id: Date.now(),
      show: false,
      type: 'func',
      subType: '',
      mathFunc: false,
      conditions: [],
    }
  }
  if (type === 'bracket') {
    return {
      name: '(',
      valid: true,
      id: Date.now(),
      type: 'bracket',
      subType: 'bracket',
    }
  }
  return {
    name: '',
    valid: false,
    details: {},
    comparator: {},
    id: Date.now(),
    type: 'filler',
    subType: '',
    show: false,
  }
}

export const conditionToStringGenerator = (conditions, noBrackets) => {
  let tempStr = ''
  let andor = false
  for (let j = 0; j < conditions.length; j++) {
    const {
      name,
      subType,
      mathFunc,
      period,
      symbol = '',
      conditions: cds,
      multiConditions,
    } = conditions[j]
    if (subType === 'and/or') {
      andor = true
    }
    if (mathFunc) {
      if (multiConditions && multiConditions[0]) {
        tempStr += `${name}( `
        if (multiConditions[0] === 'array') {
          const newCds = multiConditions[1]
          tempStr += conditionToStringGenerator(newCds, true) // `${newCds[a].name} `
          tempStr += ', '
        }
      } else if (name === MATH_FUNCS.SYMBOL.name) {
        tempStr += `${name}(${symbol}, `
      } else {
        tempStr += `${name}(${period}, `
      }
      tempStr += conditionToStringGenerator(cds, true)
      tempStr += ') '
    } else {
      tempStr += `${name} `
    }
  }
  if (andor && !noBrackets) {
    tempStr = `( ${tempStr}) `
  }
  return tempStr
}

export const screenerLogicStringGenerator = (expressions, andOrs) => {
  let str = ''
  const len = expressions.length
  let conditionExit = false
  for (let i = 0; i < len; i++) {
    const { conditions, disabled = false } = expressions[i]
    if (!disabled) {
      if (conditionExit) {
        str += `${andOrs[i]} `
      }
      conditionExit = true
      str += conditionToStringGenerator(conditions, i === 0)
    }
  }
  return str
}

// CalculatePNL

export const calculatePnl = ({
  precision,
  ltp,
  lastOrderAvgPrice,
  quantity,
  seg_sym = '',
  realizedPnl,
  currency = '',
}) => {
  // if(seg_sym === 'DERIBIT_ETH-PERPETUAL') {
  //   console.log('calculatePnl', precision,
  //     ltp,
  //     lastOrderAvgPrice,
  //     quantity,
  //     seg_sym,
  //     realizedPnl,
  //     currency)
  // }
  const absQuantity = Math.abs(quantity)
  const [segment = '', symbol = ''] = seg_sym.split('_')
  let modPrecision = precision
  let unRealizedPnl = ((+ltp - +lastOrderAvgPrice) * +absQuantity).toFixed(8)
  let modCurrency = currency
  if (
    segment.toLowerCase() === 'bitmex'
    && (symbol.includes('XBT') || symbol.includes('ETH'))
  ) {
    modPrecision = 8
    modCurrency = 'XBT'
    if (symbol.includes('XBT')) {
      unRealizedPnl = (
        +unRealizedPnl
        / (+lastOrderAvgPrice * +ltp)
      ).toFixed(8)
    } else if (symbol.includes('ETH')) {
      unRealizedPnl = (+unRealizedPnl * 0.000001).toFixed(8) // XBT multiplier refer : https://www.bitmex.com/app/seriesGuide/ETH
    }
  } else if (
    segment.toLowerCase() === 'deribit'
    && (symbol.includes('BTC') || symbol.includes('ETH'))
  ) {
    modPrecision = 8
    if (symbol.includes('BTC')) {
      modCurrency = 'BTC'
      unRealizedPnl = (
        +unRealizedPnl
        / (+lastOrderAvgPrice * +ltp)
      ).toFixed(modPrecision)
    } else if (symbol.includes('ETH')) {
      modCurrency = 'ETH'
      unRealizedPnl = (
        +unRealizedPnl
        / (+lastOrderAvgPrice * +ltp)
      ).toFixed(modPrecision)
      // console.log('unrealized pnl', unRealizedPnl)
    }
  }
  const finalUnRealizedPnl = Number(+unRealizedPnl + +realizedPnl).toFixed(
    modPrecision,
  )
  if (+lastOrderAvgPrice === 0 || +absQuantity === 0
      || +ltp === 0 || Number.isNaN(finalUnRealizedPnl)) {
    return { realizedPnl, modCurrency }
  }
  return { finalUnRealizedPnl, modCurrency }
}

// converts algo_obj keys to run backtest keys
export const cleanAlgoObj = (algo_obj, scripList = [], isMyAlgo) => {
  const {
    dt_start, dt_stop, create_plus, tpsl_type = 'pct', action_str: entry_logic, action_str_exit: exit_logic,
    take_profit, stop_loss, quantity, position_sizing_type = '-', max_allocation = '', daily_strategy_cycle = '-',
    action_type,
  } = algo_obj
  let f_algo_obj = { ...algo_obj }
  if (dt_start && dt_stop) {
    f_algo_obj = {
      ...f_algo_obj,
      start_time: dt_start,
      stop_time: dt_stop,
    }
  }
  let newEquities = {}
  scripList.forEach((scrip) => {
    const { segment, symbol } = scrip
    newEquities = {
      ...newEquities,
      [symbol]: segment,
    }
  })
  if(!isMyAlgo) delete f_algo_obj.algo_uuid
  f_algo_obj = {
    ...f_algo_obj,
    order_type: 'MARKET',
    equities: newEquities,
    create_plus: !!create_plus,
    tpsl_type,
    entry_logic,
    exit_logic,
    take_profit: take_profit.toString(),
    stop_loss: stop_loss.toString(),
    position_qty: quantity,
    position_sizing_type,
    max_allocation,
    daily_strategy_cycle,
    position_type: action_type,
  }
  return f_algo_obj
}

export const removeUndefinedKeys = (obj) => {
  let modObj = {}
  Object.keys(obj).forEach((key) => {
    if (obj[key]) {
      modObj = {
        ...modObj,
        [key]: obj[key],
      }
    }
  })
  return modObj
}

export const getLocalDate = ({ dateObj, format } = {}) => {
  // const finalOffset = offset || dayjs().utcOffset()
  const localDate = dayjs(dateObj).utc().format(format || 'DD/MM/YYYY HH:mm:ss')
  return localDate
}

export const parseLaunchParams = (paramUrl) => {
  const params = { stocks: [], algo_uuid: '' }
  let parsedUrl = decodeURIComponent(paramUrl).split('?')
  if (parsedUrl.length > 1) {
    parsedUrl = parsedUrl[1].split('&')
    for (let i = 0; i < parsedUrl.length; i++) {
      let param = parsedUrl[i]
      param = param.split('=')
      if (param[0] === 'stock') {
        if(param[1].includes(':')) {
          const [segment, symbol] = param[1].split(':')
          params.stocks.push({ segment, symbol })
        } else {
          const [segment, symbol] = param[1].split('_')
          params.stocks.push({ segment, symbol })
        }
      }
      if (param[0] === 'algo_uuid') {
        params.algo_uuid = param[1]
      }
    }
  }
  return params
}

// export const getRegistrationParams = (transToken = '', promoToken = '') => {
//   switch (Platform.OS) {
//     case 'android':
//       return {
//         transact_devices: {
//           [platformKeys.android]: transToken,
//         },
//         promo_devices: {
//           [platformKeys.android]: promoToken,
//         },
//       }
//     case 'ios':
//       return {
//         transact_devices: {
//           [platformKeys.ios]: transToken,
//         },
//         promo_devices: {
//           [platformKeys.ios]: promoToken,
//         },
//       }
//     case 'chrome':
//       break
//     case 'firefox':
//       break
//     default:
//       return {}
//   }
//   return {}
// }

// export const getUnRegistrationParams = () => {
//   switch (Platform.OS) {
//     case 'android':
//       return {
//         transact_devices: {
//           [platformKeys.android]: '',
//         },
//         promo_devices: {
//           [platformKeys.android]: '',
//         },
//       }
//     case 'ios':
//       return {
//         transact_devices: {
//           [platformKeys.ios]: '',
//         },
//         promo_devices: {
//           [platformKeys.ios]: '',
//         },
//       }
//     case 'chrome':
//       break
//     case 'firefox':
//       break
//     default:
//       return {}
//   }
//   return {}
// }

export const getAlgoToBacktestMapping = (algo_obj) => {
  const {
    entry_logic,
    exit_logic,
    position_type,
    algo_name,
    chart_type,
    holding_type,
    min_candle_freq,
    stop_loss,
    take_profit,
    time_frame,
    trade_time_given,
    trading_start_time,
    trading_stop_time,
    position_qty,
    quantity,
    dt_start,
    dt_stop,
    algo_uuid,
    user_uuid,
    create_plus,
    scripList = [],
  } = algo_obj
  return {
    action_str: entry_logic,
    action_str_exit: exit_logic,
    entry_logic,
    exit_logic,
    action_type: position_type.toUpperCase(),
    position_type: position_type.toUpperCase(),
    algo_desc: '',
    algo_name,
    algo_pref: '',
    algo_uuid,
    chart_type,
    commission: 0,
    dt_start,
    dt_stop,
    holding_type,
    initial_capital: '10000000',
    min_candle_freq,
    position_qty: position_qty || quantity,
    stop_loss,
    take_profit,
    time_frame,
    trade_time_given,
    trading_start_time,
    trading_stop_time,
    user_uuid,
    create_plus: !!create_plus,
    scripList,
  }
}

export const getAlgoStocks = (algos) => {
  const tokens = []
  for (let j = 0; j < algos.length; j++) {
    const { backtest } = algos[j]
    for (let k = 0; k < backtest.length; k++) {
      const item = backtest[k].seg_sym
      if (!tokens.some(token => token === item)) {
        tokens.push(item.split('_'))
      }
    }
  }
  return tokens
}

export const segSymConverter = (pair, reverse) => { // reverse true if sym_seg instead of seg_sym
  let modPair = pair
  let seg_sym = pair
  let symbol = ''
  let segment = ''
  if (typeof pair === 'string') {
    modPair = modPair.replace(':', '_')
    if (pair.includes('DYNAMIC CONTRACT')) {
      segment = ''
      symbol = modPair.replace('_', '')
      return { segment, symbol, seg_sym: pair }
    }
    if (reverse) {
      ([symbol, segment = ''] = modPair.split('_'))
    } else {
      ([segment = '', symbol] = modPair.split('_'))
    }
    if (segment === 'CDS') {
      segment = 'CDS-FUT'
    } else if (segment === 'NFO') {
      if (symbol.slice(-3) === 'FUT') segment = 'NFO-FUT'
      else segment = 'NFO-OPT'
    }
    modPair = { segment, symbol }
  } else {
    ({ segment = '', symbol } = pair)
  }
  seg_sym = `${segment.toUpperCase()}_${symbol}`
  return { ...modPair, seg_sym }
}

export const avgBacktestDetailsCalculator = (
  backtest_results, onGoingBacktests, scripList = [],
) => {
  let sym_pnl = 0
  let sym_max_cap_used = 0
  let absolute_pnl_pct = 0
  let absolute_pnl = 0
  let avg_winning_streak = 0
  let avg_lossing_streak = 0
  // let avg_win_loss_ratio = 0
  let avg_loss = 0
  let avg_win = 0
  let avg_max_draw = 0
  let final_return = 0
  let totalSignals = 0
  if (onGoingBacktests) {
    return { waiting: true, type: 'average' }
  }
  // not same as app code
  // const data = Object.keys(backtest_results)
  if (scripList.length === 0) {
    return {
      type: 'average',
      win_count: avg_win,
      loss_count: avg_loss,
      total_number_of_signals: totalSignals,
      final_pnl: absolute_pnl,
      winning_streak: avg_winning_streak,
      lossing_streak: avg_lossing_streak,
      max_draw: avg_max_draw,
      sym_max_cap_used,
      absolute_pnl_pct,
      final_return,
    }
  }
  let sym_count = 0
  let multiplier = 1
  scripList.forEach((segSymObj) => {
    const {
      segment, symbol, seg_sym,
    } = segSymObj
    const segSym = seg_sym || `${segment}_${symbol}`
    // const {
    //   seg_sym, backtest_result, waiting, error, error_msg: errMsg,
    // } = item
    // if (waiting || error || errMsg) return
    if (!backtest_results[segSym]) return
    const {
      final_pnl = 0, max_cap_used = 0, winning_streak = 0, lossing_streak = 0,
      max_draw = -999999, return: return_value = 0, win_count = 0, loss_count = 0,
      error: btErr, error_msg: btErrMsg, waiting, total_number_of_signals = 0,
      // average_gain_per_winning_trade = 0, average_gain_per_losing_trade = 0,
    } = backtest_results[segSym] || {}
    if (btErr || btErrMsg || waiting) return
    sym_count += 1

    sym_pnl += final_pnl
    sym_max_cap_used += max_cap_used
    avg_winning_streak += winning_streak
    avg_lossing_streak += lossing_streak
    avg_win += win_count
    avg_loss += loss_count
    totalSignals += total_number_of_signals
    const prev_avg_max_draw = avg_max_draw
    avg_max_draw = Math.max(Math.abs(max_draw), avg_max_draw)
    if (avg_max_draw !== prev_avg_max_draw) {
      multiplier = max_draw < 0 ? -1 : 1
    }
    final_return += return_value
    // avg_win_loss_ratio +=
    //  Math.abs(average_gain_per_winning_trade) / Math.abs(average_gain_per_losing_trade)
    // console.log(backtest_result[segSym], 'lol item')
  })
  absolute_pnl_pct = sym_max_cap_used === 0 ? 0 : (sym_pnl / sym_max_cap_used) * 100
  absolute_pnl = sym_pnl
  avg_winning_streak = sym_count ? avg_winning_streak / sym_count : 0
  avg_lossing_streak = sym_count ? avg_lossing_streak / sym_count : 0
  // avg_win_loss_ratio /= sym_count
  return {
    type: 'average',
    win_count: avg_win,
    loss_count: avg_loss,
    total_number_of_signals: totalSignals,
    final_pnl: absolute_pnl,
    winning_streak: parseFloat(avg_winning_streak.toFixed(2)),
    lossing_streak: parseFloat(avg_lossing_streak.toFixed(2)),
    max_draw: avg_max_draw * multiplier,
    sym_max_cap_used,
    absolute_pnl_pct,
    return: sym_count ? final_return / sym_count : 0,
  }
}

export const seperateActiveStocks = (stocks) => {
  const activeWaitingStocks = []
  const activeEnteredStocks = []
  for (let i = 0; i < stocks.length; i++) {
    const stock = stocks[i]
    if (stock.status === 0 && stock.algorithm_position
      && Number(stock.algorithm_position.positions.qty) !== 0) {
      activeEnteredStocks.push(stock)
    } else {
      activeWaitingStocks.push(stock)
    }
  }
  return { activeWaitingStocks, activeEnteredStocks }
}

export const getChartType = (chartType) => {
  let chart_type = chartType

  chart_type = /Candlestick/i.test(chartType)
    ? 'candlestick'
    : 'heikinAshi'
  if (/Renko/i.test(chartType)) {
    chart_type = chartType
  }
  return chart_type
}

export const showTNC = (brokerName, termsPageSection) => {
  let baseURL = BROKERS.ZERODHA.termsLink
  switch(brokerName) {
    case BROKERS.ANGEL.name: {
      baseURL = BROKERS.ANGEL.termsLink
      break
    }
    default: {
      baseURL = BROKERS.ZERODHA.termsLink
    }
  }
  const url = `${baseURL}${termsPageSection ? `#${termsPageSection}` : ''}`
  window.open(url)
  // navigation.navigate(NAVIGATIONS.WEBVIEW.name, {
  //   url: termsAndCondsUrl,
  // })
}

export const getProgressColor = (progress = 0, isDark) => {
  const themeType = isDark ? DARK : LIGHT
  if (progress <= 25) return { progressColor: themeType.progress25, progressImg: PARTIAL_1 }
  if (progress <= 50) return { progressColor: themeType.progress50, progressImg: PARTIAL_2 }
  if (progress <= 75) return { progressColor: themeType.progress75, progressImg: PARTIAL_3 }
  return { progressColor: themeType.progress25, progressImg: PARTIAL_1 }
}

export const tickerDuration = (text, minDuration = 5000) => {
  return text.length * 180 < minDuration ? minDuration : text.length * 180
}

// web specific

export const setCookie = (obj_to_save, expiryTime) => {
  const keys = Object.keys(obj_to_save)
  let expiry = new Date(Date.now() + 36000000).toUTCString()
  if (expiryTime) {
    expiry = expiryTime
  }
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i]
    const value = obj_to_save[key]
    document.cookie = `${key}=${value};expires=${expiry};path=/;`
  }
}

export const deleteCookies = (cookieArr) => {
  cookieArr.map((item) => {
    document.cookie = `${item}=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;`
    return null
  })
}

export const getCookies = () => {
  let cookies = decodeURIComponent(document.cookie)
  cookies = cookies.split(';')
  const cookie_obj = {}
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].split('=')
    const [key, value] = cookie
    if (key && value) {
      const finalValue = cookie.slice(1, cookie.length).join('=')
      cookie_obj[key.trim()] = finalValue.trim()
    }
  }
  return cookie_obj
}

export const headerGenerator = (auth_token = '', sessionid = '') => {
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
    authorization: `csrftoken=${auth_token};csrfmiddlewaretoken=${auth_token};sessionid=${sessionid}`,
    'x-csrftoken': sessionid,
  }
  return headers
}

export const setLocalStorage = (item, convert) => {
  Object.keys(item).forEach((key) => {
    localStorage.setItem(key, convert ? JSON.stringify(item[key]) : item[key])
  })
}

export const getLocalStorage = (key) => {
  return localStorage.getItem(key)
}

export const deleteLocalStorage = (keyArr, clearAll) => {
  if (clearAll) {
    localStorage.clear()
    return
  }
  keyArr.forEach(item => localStorage.removeItem(item))
}

export const getCandleIntervalsMap = (fullMap, shortMap) => {
  return Object.keys(fullMap).map((item) => {
    return ({
      label: item, value: condensed_candle_map[item], shortLabel: shortMap[item],
    })
  })
}

export const getCurrentBroker = () => {
  // change this according to which broker you want to work in development
  let broker = BROKERS.NONE
  Object.values(BROKERS).forEach(({ hostnames, key }) => {
    if (hostnames.includes(window.location.hostname)) {
      broker = BROKERS[key]
    }
  })
  return broker
}

export const removeObjectProperties = (obj, props) => {
  for (let i = 0; i < props.length; i++) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(props[i])) {
      delete obj[props[i]]
    }
  }
}

export const updateQueryParamsSessExp = () => {
  const { session } = parseQuery(window.location.search)
  if(session !== 'expired') {
    const url = new URL(window.location.href)
    url.searchParams.append('session', 'expired')
    window.location.replace(url)
  }
}

export const validity = (indicatorSec) => {
  const {
    indicator,
    compareIndicator,
    comparator,
    comparatorValid = true,
    indicatorValid = true,
    compareIndicatorValid = true,
  } = indicatorSec
  return (comparator !== '' && comparator !== 'Comparator' && (comparatorValid || comparator === 'N/A')
    && compareIndicator !== '' && compareIndicator !== 'Indicator' && (compareIndicatorValid || compareIndicator === 'N/A')
    && indicator !== '' && indicator !== 'Indicator' && indicatorValid)
}

export const logicToStringGenerator = (indicatorArr, andors) => {
  let str = ''
  const len = indicatorArr.length
  let conditionExit = false

  for (let i = 0; i < len; i++) {
    const {
      indicator,
      compareIndicator,
      comparator,
      disabled = false,
      // andor
    } = indicatorArr[i]
    if (!disabled && validity(indicatorArr[i])) {
      if (
        conditionExit
      ) {
        const andor = andors[i] || 'and' // DANGER
        str += ` ${andor} `
      }
      conditionExit = true
      if (compareIndicator !== 'N/A') {
        // add all 3 of them
        str += `${indicator} ${comparator} ${compareIndicator}`
      } else {
        str += `${indicator}`
      }
    } else {
      str += ''
    }
  }
  return str
}

export const dynamicScripParams = (segment) => {
  const offsetRange = segment === 'INDICES' ? [9, -9, 1] : [4, -4, 1]
  const expiry_type_opt = segment === 'INDICES' ? ['MONTHLY', 'WEEKLY'] : ['MONTHLY']
  return {
    indicator: {
      params: [
        [
          'contract_type',
          'CE',
          true,
          'dropdown',
          ['CE', 'PE', 'FUT'],
          'Contract type',
        ],
        [
          'offset', // valueKey
          '0', // value
          true,
          'range', // value type
          offsetRange, // value type params,
          'Strikes offset from ATM', // value label (if not specified then will fallback to value key)
        ],
        [
          'expiring',
          'CURRENT',
          true,
          'dropdown',
          ['NEXT', 'CURRENT'],
          'Expiring',
        ],
        [
          'expiry_type',
          'MONTHLY',
          true,
          'dropdown',
          expiry_type_opt,
          'Expiry type',
        ],
        [
          'order_type',
          'LONG',
          true,
          'dropdown',
          ['LONG', 'SHORT'],
          'Order type',
        ],
        [
          'change_frequency',
          'CANDLE',
          true,
          'dropdown',
          ['CANDLE', 'DAY OPEN'],
          'Change frequency',
        ],
      ],
    },
  }
}

export const DYNAMIC_SEGMENTS = ['NSE', 'INDICES']

export const getDynamicSymbol = (params, seg_sym) => {
  const {
    contract_type, offset, expiring, expiry_type, order_type, change_frequency,
  } = params
  if (contract_type === 'FUT') {
    return `DYNAMIC CONTRACT(${seg_sym}, ${contract_type}, ${expiring}, ${expiry_type}, ${order_type})`
  }
  return `DYNAMIC CONTRACT(${seg_sym}, ${contract_type}, ATM, ${offset}, ${expiring}, ${expiry_type}, ${order_type}, ${change_frequency})`
}

// gives base seg_sym from dynamic contracts
export const getSegSymFromDC = (pair) => {
  let segSym = pair
  let isDyc = false
  if (pair.includes('DYNAMIC CONTRACT')) {
    [segSym] = pair.split('(')[1].split(',')
    isDyc = true
  }
  return { segSym, isDyc }
}

export const getAdvancedParamsLabel = ({ tpsl_type }) => {
  let tpLabel = 'Target profit %'
  let slLabel = 'Stop loss %'
  let tslLabel = 'Trailing SL %'
  switch (tpsl_type) {
    case 'abs':
      tpLabel = 'Total Target profit'
      slLabel = 'Total Stop loss'
      tslLabel = 'Total Trailing SL'
      break
    case 'pts':
      tpLabel = 'Target profit'
      slLabel = 'Stop loss'
      tslLabel = 'Trailing SL'
      break
    default: break
  }
  return {
    tpLabel,
    slLabel,
    tslLabel,
  }
}

export const tslValidator = (tpsl_type, trailingSl) => {
  let error = false
  let errorMsg = ''
  if (tpsl_type === 'pct') {
    if (trailingSl > 0 && trailingSl < 0.1) {
      error = true
      errorMsg = TSL_ERROR_TYPE_MAP.PCT
    }
  } else if (tpsl_type === 'abs') {
    if (trailingSl > 0 && trailingSl < 100) {
      error = true
      errorMsg = TSL_ERROR_TYPE_MAP.ABS
    }
  } else if (tpsl_type === 'pts') {
    if (trailingSl > 0 && trailingSl < 1) {
      error = true
      errorMsg = TSL_ERROR_TYPE_MAP.PTS
    }
  }
  return {
    error, errorMsg,
  }
}

export const TSL_ERROR_TYPE_MAP = {
  ABS: 'If TPSL type is abs, enter values either 0 or greater than 100(abs)',
  PTS: 'If TPSL type is pts, enter values either 0 or greater than 1(pts)',
  PCT: 'If TPSL type is pct, enter values either 0 or greater than 0.1(pct)',
}

export const ALGO_MODE = {
  BASIC: {
    label: 'Basic mode', value: false, id: 'BASIC', iconName: 'SETTING_OUTLINED',
  },
  ADVANCE: {
    label: 'Advance mode', value: true, id: 'ADVANCE', iconName: 'CREATE_ADVANCE',
  },
}

export const CREATE_PAGE_VIEW_MAP = {
  BASIC: {
    label: 'Single page mode', value: false, id: 'SINGLE', iconName: 'SINGLE_PAGE_VIEW',
  },
  ADVANCE: {
    label: 'Multipage mode', value: true, id: 'MULTI', iconName: 'MULTI_PAGE_VIEW',
  },
}

// do not change
export const CONDITION_TYPE = {
  ENTRY: 'entry',
  EXIT: 'exit',
  REVIEW: 'review',
  POSITION: null,
  SCREENER: 'expressions',
  PRO: 'pro',
  PARAMS: 'parameters',
  ALGO_NAME: 'algo_name',
  QUANTITY: 'quantity',
  MAX_ALLOC: 'max_allocation',
  SCRIPLIST: 'scripList',
  SINGLE: 'single',
  SCREENER_NAME: 'screener_name',
}

export const STEP_CONDITION_TYPE = [
  {
    label: 'Positions', value: CONDITION_TYPE.POSITION, stepValue: 1, validTypes: ['scripList', 'quantity'],
  },
  {
    label: 'Entry', value: CONDITION_TYPE.ENTRY, stepValue: 2, validTypes: ['entry'],
  },
  {
    label: 'Exit', value: CONDITION_TYPE.EXIT, stepValue: 3, validTypes: ['tp', 'sl', 'tsl'],
  },
  {
    label: 'Review',
    value: CONDITION_TYPE.REVIEW,
    stepValue: '',
    validTypes: [
      'entry', 'tp', 'sl', 'max_allocation', 'scripList', 'quantity', 'tsl',
    ],
  },
]

export const VALID_TYPE_ERROR = {
  scripList: 'Add instruments to continue',
  quantity: 'Enter a valid quantity',
  entry: 'Entry field has an invalid condition',
  tp: 'Enter target profit for your strategy',
  sl: 'Enter stop loss for your strategy',
  tslPct: 'Enter tsl 0 or greater than 0.1(pct)',
  tslAbs: 'Enter tsl 0 or greater than 100(abs)',
  tslPts: 'Enter tsl 0 or greater than 1(pts)',
}

export const initialState = {
  candleInterval: '1 Hour',
  positionType: 'Buy',
  quantity: '1',
  stopLoss: '',
  takeProfit: '',
  holding_type: 'MIS',
  order_type: ORDER_TYPE.MARKET,
  chart_type: 'Candlestick',
  trading_start_time: '00:00',
  trading_stop_time: '23:59',
  trailing_sl: '',
  initial_capital: 10000000,
}

export const symbolNameByOptions = (symbol = '', withoutSuperScript) => {
  try {
    const numberRegex = /^-?[\d.]+(?:e-?\d+)?$/
    const MONTHS = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC']
    const optionType = symbol.slice(-2)

    if ((optionType === 'PE' || optionType === 'CE') && symbol.match(/\d+/)) {
      const tempNumber = symbol.match(/\d+/)[0]
      const symbolName = symbol.split(tempNumber)[0]
      let isWeekly = false
      const data = symbol.substring(symbolName.length, symbol.length - 2)
      const year = data.substring(0, 2)
      let month_week = data.substring(2, 5)
      if (!MONTHS.includes(month_week) && numberRegex.test(month_week.slice(-2))) {
        isWeekly = true
        const week = parseInt(month_week.slice(-2), 10)
        let weekStr = week.toString()
        weekStr = ordinalSuffix(week, withoutSuperScript)
        const monthNumber = month_week.substring(0, month_week.length - 2)
        if (numberRegex.test(monthNumber)) { month_week = `${MONTHS[parseInt(monthNumber, 10) - 1]} ${weekStr}` } else {
          switch (monthNumber) {
            case 'S': month_week = `SEP ${weekStr}`; break
            case 'O': month_week = `OCT ${weekStr}`; break
            case 'N': month_week = `NOV ${weekStr}`; break
            case 'D': month_week = `DEC ${weekStr}`; break
            default: month_week = `${MONTHS[parseInt(monthNumber, 10) - 1]} ${weekStr}`; break
          }
        }
      }
      const stikePrice = data.substring(5)
      return `${symbolName}${year} ${month_week} ${stikePrice} ${optionType} ${isWeekly ? '(W)' : '(M)'}`
    }

    const futureSymbol = symbol.slice(-3)
    if (futureSymbol === 'FUT') {
      const tempNumber = symbol.match(/\d+/)[0]
      const symbolName = symbol.split(tempNumber)[0]
      const year = tempNumber.substring(0, 2)
      const month_week = symbol.split(tempNumber)[1].substring(0, 3)
      return `${symbolName}${year} ${month_week} FUT    `
    }
  } catch (e) {
    console.log(e)
    return symbol
  }

  return symbol
}

export const ordinalSuffix = (number, withoutSuperScript = false) => {
  const j = number % 10
  const k = number % 100
  if (j === 1 && k !== 11) {
    return withoutSuperScript ? `${number}st` : `${number}ˢᵗ`
  }
  if (j === 2 && k !== 12) {
    return withoutSuperScript ? `${number}nd` : `${number}ⁿᵈ`
  }
  if (j === 3 && k !== 13) {
    return withoutSuperScript ? `${number}rd` : `${number}ʳᵈ`
  }
  return withoutSuperScript ? `${number}th` : `${number}ᵗʰ`
}

export const symbolFormatter = (symbol, segment) => {
  const symbolArray = symbol.split(' ')
  if(segment === 'NFO-OPT' && symbolArray.length === 5) {
    const month = 1
    const date = 2;
    [symbolArray[month], symbolArray[date]] = [symbolArray[date], symbolArray[month]]
  }
  if (segment === 'NFO-FUT' || segment === 'NFO-OPT') {
    const finalsymbol = symbolArray.map((item, index) => (index === 0 ? item.slice(0, -2) : item)).join(' ')
    return finalsymbol
  }
  return symbol
}

export const getFinalSymbol = (seg_sym) => {
  const { symbol, segment } = segSymConverter(seg_sym)
  let symbolName = symbol
  let symbolSup = ''
  let restSymbol = ''
  const rawSymbolName = symbolNameByOptions(symbolName)
  let showOptions
  if (symbolName !== rawSymbolName) {
    showOptions = rawSymbolName.charAt(rawSymbolName.length - 2)
    symbolName = rawSymbolName.substring(0, rawSymbolName.length - 4)
    symbolName = symbolFormatter(symbolName, segment)
  }
  if (showOptions === 'W') {
    const substrings = ['ᵗʰ', 'ʳᵈ', 'ˢᵗ', 'ⁿᵈ']
    const pattern = new RegExp(`(${substrings.join('|')})`)
    const parts = symbolName.split(pattern)
    symbolName = parts[0]
    symbolSup = `${parts[1]} `
    restSymbol = parts[2]
  }

  return {
    symbolName, symbolSup, restSymbol, showOptions, segment,
  }
}

export const redundantIndices = ['INDICES_EURINR', 'INDICES_EURUSD', 'INDICES_JPYINR', 'INDICES_DUSDINR', 'INDICES_GBPINR', 'INDICES_USDJPY', 'INDICES_GBPUSD']

export const decodeRoute = (pathname, search) => {
  let decodedSearch = search
  if(search) {
    const searchArray = search.split('&')
    const splitedArray = searchArray[0].split('=')
    splitedArray[1] = atob(splitedArray[1])
    searchArray[0] = splitedArray.join('=')
    decodedSearch = searchArray.join('&')
  }
  return pathname + decodedSearch
}

export const setSessionCookie = (sessionId) => {
  const expirationDate = new Date()
  expirationDate.setTime(expirationDate.getTime() + (30 * 60 * 1000))
  setCookie({ sessionId }, `${expirationDate.toUTCString()}`)
}
