/* eslint-disable import/no-cycle */
import {
  combineReducers, applyMiddleware, createStore, compose,
} from 'redux'
import createSagaMiddleware from 'redux-saga'

import rootSaga from './rootSaga'
import dashboard from './containers/Dashboard/DashBoard-reducer'
import scanner from './containers/Scanner/Scanner-reducer'
import technicals from './containers/Technicals/Technicals-reducer'
import common from './containers/Common/Common-reducer'
import instruments from './containers/Instruments/Instruments-reducer'
import scans from './containers/Scans/Scans-reducer'
import market from './containers/Marketwatch/Marketwatch-reducer'
import marketplace from './containers/MarketPlace/MarketPlace-reducer'

const appReducer = combineReducers({
  dashboard,
  scanner,
  technicals,
  common,
  instruments,
  scans,
  market,
  marketplace,
})

let enhancer

const sagaMiddleware = createSagaMiddleware()

if (process.env.NODE_ENV === 'production') {
  enhancer = applyMiddleware(sagaMiddleware)
} else {
  const composeEnhancers = typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      maxAge: 25,
    })
    : compose
  enhancer = composeEnhancers(applyMiddleware(sagaMiddleware))
}

const rootReducer = (state, action) => {
  if (action.type === 'CLEAR_USER_DETAILS') {
    return appReducer(undefined, action)
  }
  return appReducer(state, action)
}
const store = createStore(rootReducer, enhancer)

sagaMiddleware.run(rootSaga)

export default store
