import {
  FETCH_SCANNER_DETAILS_INIT,
  FETCH_SCANNER_DETAILS_SUCCESS,
  FETCH_SCANNER_DETAILS_FAILURE,
} from './actionTypes'

export function fetchScannerDetails(params) {
  return {
    type: FETCH_SCANNER_DETAILS_INIT,
    params,
  }
}

export function fetchScannerDetailsSuccess(data) {
  return {
    type: FETCH_SCANNER_DETAILS_SUCCESS,
    data,
  }
}

export function fetchScannerDetailsFailure(error) {
  return {
    type: FETCH_SCANNER_DETAILS_FAILURE,
    error,
  }
}
