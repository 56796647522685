import { DEPLOYMENT_TYPE } from './consts'

export const NAVIGATIONS = {
  DASHBOARD: {
    name: 'streak.dashboard',
    id: 'DASHBOARD',
  },
  PARTIAL_COMPLETE: {
    name: 'streak.partialComplete',
    id: 'PARTIAL_COMPLETE',
  },
  DISCOVER: {
    name: 'streak.discover',
    id: 'DISCOVER',
  },
  SUBSCRIBED: {
    name: 'streak.subscribed',
    id: 'SUBSCRIBED',
  },
  REWARDS: {
    name: 'streak.rewards',
    id: 'REWARDS',
  },
  MY_ALGOS: {
    name: 'streak.myAlgos',
    id: 'MY_ALGOS',
  },
  STRATEGIES: {
    name: 'streak.strategies',
    id: 'STRATEGIES',
  },
  CREATE: {
    name: 'streak.create',
    id: 'CREATE',
  },
  BACKTESTS: {
    name: 'streak.backtests',
    id: 'BACKTESTS',
  },
  DEPLOYED: {
    name: 'streak.deployed',
    id: 'DEPLOYED',
  },
  ORDERBOOK: {
    name: 'streak.orderbook',
    id: 'ORDERBOOK',
    title: 'OrderBook',
  },
  PORTFOLIO: {
    name: 'streak.portfolio',
    id: 'PORTFOLIO',
    title: 'Portfolio',
  },
  BASKETS: {
    name: 'streak.baskets',
    id: 'BASKETS',
  },
  NOTIFICATIONS: {
    name: 'streak.notifications',
    id: 'NOTIFICATIONS',
  },
  SCANNER_HISTORY: {
    name: 'streak.scannerHistory',
    id: 'SCANNER_HISTORY',
  },
  ACCOUNT: {
    name: 'streak.account',
    id: 'ACCOUNT',
  },
  FEEDBACK: {
    name: 'streak.feedback',
    id: 'FEEDBACK',
  },
  LOGOUT: {
    name: 'streak.logout',
    id: 'LOGOUT',
  },
  PRICING: {
    name: 'streak.pricing',
    id: 'PRICING',
  },
  PROFILE: {
    name: 'streak.profile',
    id: 'PROFILE',
  },
  VIDEOS: {
    name: 'streak.videos',
    id: 'VIDEOS',
  },
  MANUAL: {
    name: 'streak.manual',
    id: 'MANUAL',
  },
  TERMS: {
    name: 'streak.terms',
    id: 'TERMS',
  },
  PRIVACY: {
    name: 'streak.privacy',
    id: 'PRIVACY',
  },
  BACKTEST_DETAILS: {
    name: 'streak.backtestDetails',
    id: 'BACKTEST_DETAILS',
  },
  SIDEMENU: {
    name: 'streak.sidemenu',
    id: 'SIDEMENU',
  },
  AUTH: {
    name: 'streak.auth',
    id: 'AUTH',
  },
  CENTER_STACK: {
    name: 'streak.centerStack',
    id: 'CENTER_STACK',
  },
  ACCEPTTNC: {
    name: 'streak.acceptTnc',
    id: 'ACCEPTTNC',
  },
  LOGIN: {
    name: 'streak.login',
    id: 'LOGIN',
  },
  BILLING: {
    name: 'streak.billing',
    id: 'BILLING',
  },
  REFERRAL: {
    name: 'streak.referral',
    id: 'REFERRAL',
  },
  SIGNUP: {
    name: 'streak.signup',
    id: 'SIGNUP',
  },
  SIGNUP_SUCCESS: {
    name: 'streak.signupSuccess',
    id: 'SIGNUP_SUCCESS',
  },
  ARCHIVE: {
    name: 'streak.archive',
    id: 'ARCHIVE',
  },
  SCANNER: {
    name: 'streak.scanner',
    id: 'SCANNER',
  },
  SCANS: {
    name: 'streak.scans',
    id: 'SCANS',
  },
  PAYMENT: {
    name: 'streak.payment',
    id: 'PAYMENT',
  },
  BOTTOM_STACK: {
    name: 'streak.bottomStack',
    id: 'BOTTOM_STACK',
  },
  MY_SCANS: {
    name: 'streak.myScans',
    id: 'MY_SCANS',
  },
  LIVE_SCANS: {
    name: 'streak.liveScans',
    id: 'LIVE_SCANS',
  },
  DISCOVER_SCANS: {
    name: 'streak.discoverScans',
    id: 'DISCOVER_SCANS',
  },
  SCAN_DETAILS: {
    name: 'streak.scanDetails',
    id: 'SCAN_DETAILS',
  },
  SCAN_RESULT: {
    name: 'streak.scanResult',
    id: 'SCAN_RESULT',
  },
  MARKET: {
    name: 'streak.market',
    id: 'MARKET',
    title: 'Marketwatch',
  },
  REFERRALS: {
    name: 'streak.referrals',
    id: 'REFERRALS',
  },
  WATCHLIST: {
    name: 'streak.watchList',
    id: 'WATCHLIST',
    title: 'WatchList',
  },
  EDIT_WATCHLIST: {
    name: 'streak.editWatchList',
    id: 'EDIT_WATCHLIST',
  },
  ADD_SCRIP: {
    name: 'streak.addScrip',
    id: 'ADD_SCRIP',
  },
  BACKTEST_TRANSACTIONS: {
    name: 'streak.backtestTransaction',
    id: 'BACKTEST_TRANSACTIONS',
  },
  BACKTEST_EDIT_PARAMS: {
    name: 'streak.backtestEditParams',
    id: 'BACKTEST_EDIT_PARAMS',
  },
  // [DEPLOYMENT_TYPE.AUTO.value]: {
  //   name: 'streak.autotrading',
  //   id: DEPLOYMENT_TYPE.AUTO.value,
  // },
  [DEPLOYMENT_TYPE.PAPER.value]: {
    name: 'streak.papertrading',
    id: DEPLOYMENT_TYPE.PAPER.value,
  },
  [DEPLOYMENT_TYPE.NOTIFICATION.value]: {
    name: 'streak.notificationtrading',
    id: DEPLOYMENT_TYPE.NOTIFICATION.value,
  },
  SYMBOL_DETAILS: {
    name: 'streak.symbolDetails',
    id: 'SYMBOL_DETAILS',
  },
  BUY_SELL: {
    name: 'streak.buySell',
    id: 'BUY_SELL',
  },
  TRADES: {
    name: 'streak.trades',
    id: 'TRADES',
  },
  ORDERLOG: {
    name: 'streak.orderlog',
    id: 'ORDERLOG',
  },
  DEPLOY: {
    name: 'streak.deploy',
    id: 'DEPLOY',
  },
  MY_PROFILE: {
    name: 'streak.myProfile',
    id: 'MY_PROFILE',
  },
  MY_ACCOUNT: {
    name: 'streak.myAccount',
    id: 'MY_ACCOUNT',
  },
  PREFERENCE: {
    name: 'streak.preference',
    id: 'PREFERENCE',
  },
  INVOICES: {
    name: 'streak.invoices',
    id: 'INVOICES',
  },
  TECHNICALS: {
    name: 'streak.technicals',
    id: 'TECHNICALS',
  },
  HELP: {
    name: 'streak.help',
    id: 'HELP',
  },
  ONBOARDING: {
    name: 'streak.onboarding',
    id: 'ONBOARDING',
  },
  TOP_MOVERS: {
    name: 'streak.topMovers',
    id: 'TOP_MOVERS',
  },
  DASHBOARD_POSITIONS: {
    name: 'streak.dashboardPositions',
    id: 'DASHBOARD_POSITIONS',
  },
  WEBVIEW: {
    name: 'streak.webview',
    id: 'WEBVIEW',
  },
}

// This is a mapping of pathnames to the endpoints (in sync with streak world v3 repo)
export const pathname_mapping = {
  Dashboard: '/dashboard',
  Scanner: '/scans',
  Technicals: '/symbol',
  Discover: 'scans/popular', //  detailed view
  DiscoverAlgo: 'algos/discover', //  detailed view
  Backtests: 'backtests',
  popular: 'scans/popular',
}
