import React, { Component } from 'react'
import isEqual from 'lodash/isEqual'

// import events from '../../utils/events'
// import RootModals from '../../components/Common/RootModals'
import WebWorker from '../../utils/web.worker'
import { showSnackbar } from '../../UI/Snackbar'

import {
  WEBSOKET_BROKER_MAP, PREFERENCES, BROKERS,
} from '../../utils/consts'
import { ASSETS } from '../../Theme'
import { withTheme } from '../../Theme/ThemeProvider'
// import NotifMp3 from '../../assets/audio.mp3'
import { pathname_mapping } from '../../utils/navigationConstant'
import { redundantIndices, setLocalStorage } from '../../utils/common'

const { StreakLogo } = ASSETS

class Common extends Component {
  constructor(props) {
    super(props)

    this.state = {
      app_state: 'active',
      subbed_tokens: [],
      shouldResubscribe: false,
    }
    this.tokenRegisterCount = 0
    // this.pollUserTimer = null
    this.alert = false
    this.analytics = {}
    this.thread = {}
    this.audioRef = React.createRef()
    this.notif_connected = false
  }

  componentDidMount() {
    const { isWebView } = this.props
    this.thread = new WebWorker()
    if (this.thread) this.thread.addEventListener('message', event => this.tickHandler(event))

    if (!isWebView) {
      this.initializeApp()
      this.connected = true
      // this.getNotifPermission()
    }
    // this.notifSound = new Audio(NotifMp3)
  }

  componentDidUpdate = (prevProps, prevState) => {
    const {
      tokens,
      unSubscribe,
      unSubList,
      ticks,
      isWebView,
    } = this.props

    const { subbed_tokens, shouldResubscribe } = this.state
    if(!isWebView && !this.connected) {
      this.initializeApp()
      this.setState({ shouldResubscribe: true })
    }
    if(shouldResubscribe && this.connected) {
      // console.log('resubing', subbed_tokens)
      this.thread.postMessage({ type: 'subscribe', instrument_list: subbed_tokens })
      this.setState({ shouldResubscribe: false })
    }
    if (!unSubscribe) {
      if (!isEqual(tokens, prevProps.tokens) && tokens.length) {
        // eslint-disable-next-line no-prototype-builtins
        const subArray = tokens.filter(scrib => !ticks.hasOwnProperty(scrib)
        && !redundantIndices.includes(scrib))
        this.setState({ subbed_tokens: [...prevState?.subbed_tokens, ...subArray] })
        if(subArray.length > 0) {
          this.thread.postMessage({ type: 'subscribe', instrument_list: subArray })
        }
        // eslint-disable-next-line max-len
        // setTimeout(() => this.thread.postMessage({ type: 'subscribe', instrument_list: tokens }), 5000)
      }
      if (!isEqual(unSubList, prevProps.unSubList) && unSubList.length) {
        this.thread.postMessage({ type: 'unsubscribe', instrument_list: unSubList })
      }
    } else if (unSubscribe !== prevProps.unSubscribe && unSubscribe) {
      this.thread.postMessage({ type: 'unsubscribe' })
    }
  }

  componentWillUnmount = () => {
    if (this.thread) {
      this.thread.postMessage({
        type: 'close',
      })
    }
    // if (this.pollUserTimer) clearInterval(this.pollUserTimer)
  }

  getNotifPermission = () => {
    if (!('Notification' in window)) {
      showSnackbar('Notifications not supported in browser', {}, 0)
    } else if (Notification.permission !== 'granted') {
      Notification.requestPermission((permission) => {
        let value = false
        if (permission === 'granted') {
          value = true
          showSnackbar('Great, you will now receive notifications on browser as well', {}, 1)
        }
        const { preferences, updatePreferences } = this.props
        setLocalStorage({
          [PREFERENCES.KEY]: { ...preferences, [PREFERENCES.NOTIF_POPUP]: value },
        }, true)
        updatePreferences(value, PREFERENCES.NOTIF_POPUP)
      })
    }
  }

  playSound = () => {
    this.notifSound.play()
  }

  initializeApp = async () => {
    const broker = BROKERS.NONE.name
    if (navigator.serviceWorker && navigator.serviceWorker.controller) {
      navigator.serviceWorker.controller.postMessage({ user_uuid: 'streak' })
    }
    const wsBroker = WEBSOKET_BROKER_MAP[broker]
    this.thread.postMessage({
      type: 'connect',
      // access_token,
      // broker_api_key,
      broker: wsBroker,
    })
    // this.thread.postMessage({
    //   type: 'notif-connect',
    //   user_uuid,
    // })
    // if (this.pollUserTimer) clearInterval(this.pollUserTimer)
    // if (process.env.NODE_ENV !== 'development') {
    //   this.pollUserTimer = setInterval(() => this.pollHandler('streak'), 30000) // user_uuid
    // }
    this.connected = true
  }

  tickHandler = (event = {}) => {
    const {
      updateTicks, updateNotifications, unSubscribe,
      getNotifications, getDeployedCount, isFetchingNotifications,
      preferences,
    } = this.props
    const { data } = event
    const { silent, popUp } = preferences
    if (!unSubscribe) {
      if (data === 'Connected!!') {
        this.connected = true
        return
      }
      if (data.constructor.name === 'Object' && this.connected
        && !data.notification_uuid) {
        updateTicks(data) // updateLTPTicks
        return
      }

      if (data.includes('Closed!!') || data.includes('Disconnected!!')) {
        // const tokens = Object.values(seg_sym_list)
        this.connected = false
        // if (tokens.length && this.thread) {
        //   this.thread.postMessage({ type: 'subscribe', instrument_list: tokens })
        // }
      }
    }
    if (data === 'notif service connected') {
      this.notif_connected = true
    }
    if (data.constructor.name === 'Object' && this.notif_connected
      && (data['notification-type'] || data.notification_uuid || data['price_trigger-notification'])) {
      if (!silent) this.playSound()
      if (popUp) this.showInAppNotification(data)
      updateNotifications(data)
      if (!isFetchingNotifications) {
        getNotifications()
        if (data['notification-type'] !== 'screener') {
          getDeployedCount()
        }
      }
    }
  }

  showInAppNotification = (data = {}) => {
    const {
      type = '',
      trigger_price = 0.0,
      order_type = '',
      price = 0.0,
      action_type,
      quantity = 0,
      symbol = '',
      notification_msg = '',
      algo_name = '',
      screener_name = '',
      deployment_type,
      algo_uuid = '',
      screener_uuid = '',
    } = data
    if (deployment_type === 'Paper trading') {
      return
    }
    let tag = algo_uuid || 'notification'
    let title = algo_name
    let body = ''
    let payloadData = {}
    if (data['notification-type'] === 'screener') {
      title = 'Scanner Alert'
      body = screener_name
      tag = screener_uuid
      payloadData = {
        ...payloadData,
        id: screener_uuid,
      }
    } else {
      let last = trigger_price === 0 ? order_type : `\u20B9  ${(+trigger_price).toFixed(2)}`
      if (trigger_price === 0 && order_type === 'LIMIT') {
        last = `${price || trigger_price} at ${order_type}`
      }
      let notifMsg = `${action_type} ${quantity}  shares of ${symbol} at ${last}`
      if (type === 'inrange') {
        const itms = data['price_trigger-notification'].split(':')
        notifMsg = `${itms[9]} ${itms[10]}  shares of ${itms[13]} at \u20B9 ${itms[5]}`
      }
      body = notification_msg.toLowerCase() === 'take action' ? notifMsg : notification_msg
      payloadData = {
        ...payloadData,
        id: algo_uuid,
      }
    }
    const options = {
      icon: StreakLogo,
      tag,
      timestamp: Date.now(),
      requireInteraction: true,
      body,
      data: payloadData,
    }
    const notif = new Notification(title, options)
    notif.onclick = ((e) => {
      e.currentTarget.close()
      if (e.currentTarget.title === 'Scanner Alert') {
        window.open(`${pathname_mapping.Scanners}?id=${btoa(e.currentTarget.data.id)}&tab=scan_details_live`)
      } else {
        window.open(pathname_mapping.notif_live)
      }
    })
    // e.waitUntil(
    //   clients.matchAll({
    //     type: 'window'
    //   })
    //     .then((clientList) => {
    //       for (let i = 0; i < clientList.length; i++) {
    //         const client = clientList[i]
    //         if (client.url === 'https://streak.zerodha.com/' && 'focus' in client) {
    //           return client.focus()
    //         }
    //       }
    //       if (clients.openWindow) {
    //         return clients.openWindow('https://streak.zerodha.com/notifications')
    //       }
    //       return null
    //     })
    // )
  }

  pollHandler = (user_uuid) => {
    const { pollUser, location } = this.props
    const params = {
      user_uuid,
      view: location.pathname,
    }
    const headers = new Headers()
    headers.append('Content-Type', 'application/json')
    pollUser(params, headers)
  }

  render() {
    return (
      <div />
    )
  }
}

export default withTheme({})(Common)
