import {
  takeLatest, put, call, select,
} from 'redux-saga/effects'
import { prodEndpoints, summaryBaseUrl } from '../../utils/apiEndpoints'
import { getRequest } from '../../utils/apiRequests'
import {
  fetchSummarySuccess,
  fetchSummaryFailure,
  updateHeatMapSuccess,
  updateHeatMapFailure,
  updateNifty50HeatMapFailure,
  updateNiftyBankHeatMapSuccess,
  updateNiftyBankHeatMapFailure,
  updateNifty50HeatMapSuccess,
} from './actions'
import {
  FETCH_HEAT_MAP,
  FETCH_NIFTY_50_HEAT_MAP,
  FETCH_NIFTY_BANK_HEAT_MAP,
  FETCH_SUMMARY_INIT,
} from './actionTypes'

import { error_msg } from '../../utils/common'
import { getInstruments, screenerPairs } from '../Instruments/actions'
import { NAVIGATIONS } from '../../utils/navigationConstant'

function* fetchSummary(action) {
  try {
    const { params } = action
    const url = `${summaryBaseUrl}${prodEndpoints.get_summary}`
    const data = yield call(getRequest, url, params, {})
    if ((!data.error && data.status === 'success')) {
      yield put(fetchSummarySuccess(data))
      const { screeners } = data
      const stocksToSubscribe = []
      const screenerResults = [...new Set(screeners)]
      screenerResults.forEach((screener) => {
        if (screener !== null) {
          const { stocks = [], screener_uuid } = screener
          if (screener_uuid && screener_uuid !== '') {
            stocksToSubscribe.push(...stocks)
          }
        }
      })

      yield put(getInstruments(
        screenerPairs(null, stocksToSubscribe),
        {},
        NAVIGATIONS.DASHBOARD.name,
      ))
    } else {
      yield put(fetchSummaryFailure(data.error_msg))
    }
  } catch (err) {
    yield put(fetchSummaryFailure(err.message || error_msg))
  }
}

export function* fetchSummarySaga() {
  yield takeLatest(FETCH_SUMMARY_INIT, fetchSummary)
}

function* fetchHeatMap(action) {
  const currState = yield select()
  const { dashboard: { nifty50MapData, heatmapData } } = currState
  let data = {}
  try {
    if(!Object.keys(heatmapData).length && nifty50MapData?.status === 'success') {
      data = { ...nifty50MapData }
    } else {
      const { params } = action
      const url = `${summaryBaseUrl}${prodEndpoints.heatmap}`
      data = yield call(getRequest, url, params, {})
    }
    if ((!data.error && data.status === 'success')) {
      yield put(updateHeatMapSuccess(data))
    } else {
      yield put(updateHeatMapFailure(data.error_msg))
    }
  } catch(err) {
    yield put(fetchSummaryFailure(err.message || error_msg))
  }
}

// eslint-disable-next-line import/prefer-default-export
export function* fetchHeatMapSaga() {
  yield takeLatest(FETCH_HEAT_MAP, fetchHeatMap)
}

function* fetchNifty50HeatMap(action) {
  try {
    const { params } = action
    const url = `${summaryBaseUrl}${prodEndpoints.heatmap}`
    const data = yield call(getRequest, url, params, {})
    if ((!data.error && data.status === 'success')) {
      yield put(updateNifty50HeatMapSuccess(data))
    } else {
      yield put(updateNifty50HeatMapFailure(data.error_msg))
    }
  } catch(err) {
    yield put(updateNifty50HeatMapFailure(err.message || error_msg))
  }
}

// eslint-disable-next-line import/prefer-default-export
export function* fetchNifty50HeatMapSaga() {
  yield takeLatest(FETCH_NIFTY_50_HEAT_MAP, fetchNifty50HeatMap)
}

function* fetchNiftyBankHeatMap(action) {
  try {
    const { params } = action
    const url = `${summaryBaseUrl}${prodEndpoints.heatmap}`
    const data = yield call(getRequest, url, params, {})
    if ((!data.error && data.status === 'success')) {
      yield put(updateNiftyBankHeatMapSuccess(data))
    } else {
      yield put(updateNiftyBankHeatMapFailure(data.error_msg))
    }
  } catch(err) {
    yield put(updateNiftyBankHeatMapFailure(err.message || error_msg))
  }
}

// eslint-disable-next-line import/prefer-default-export
export function* fetchNiftyBankHeatMapSaga() {
  yield takeLatest(FETCH_NIFTY_BANK_HEAT_MAP, fetchNiftyBankHeatMap)
}
