// import dayjs from 'dayjs'
import React from 'react'
import { connect } from 'react-redux'
import {
  SPACING, theme, ASSETS, COLORS,
} from '../Theme'
import { withTheme } from '../Theme/ThemeProvider'
import CustomText from './CustomText'

const { Logo, ZerodhaLogo } = ASSETS

function ScreenerFooter({ styles }) { // summary_data = {}
  // const { market_breadth = {} } = summary_data
  // const { updated_at = '' } = market_breadth
  return (
    <div className={styles.container}>
      <a className={styles.poweredByRow} href="https://streak.tech" rel="noreferrer" target="_blank">
        <CustomText size="small" color={theme.textSecondary} style={{ whiteSpace: 'pre-line' }}>
          {'© Powered by  '}
          {/* <a href={webBaseUrl} rel="noreferrer" target="_blank" className={styles.webBaseUrl}>
            Streak.tech
          </a>
          {' View on'} */}
        </CustomText>
        <img className={styles.streakLogo} alt="streak logo" src={Logo} />
      </a>
      {/* {updated_at ? <CustomText color="textSecondary" size="small">{`Last updated on
      ${dayjs(updated_at).format('DD/MM/YYYY HH:mm A')}`}</CustomText> : null} */}
      <div>
        <img className={styles.zerodhaLogo} alt="zerodha logo" src={ZerodhaLogo} />
        <CustomText size="small" color={theme.textSecondary}>
          NSE &BSE-SEBI Registration No: INZO00031633 CDSL SEBI Registration No: IN-DP-431-2019
        </CustomText>
      </div>
    </div>
  )
}

const stylesheet = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: `${SPACING.SPACE_20} ${SPACING.SPACE_64} ${SPACING.SPACE_60}`,
    backgroundColor: theme.hover,
    gap: SPACING.SPACE_40,
  },
  poweredByRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: SPACING.SPACE_10,
  },
  webBaseUrl: {
    color: COLORS.GREY_200,
  },
  streakLogo: {
    width: '50px',
  },
  zerodhaLogo: {
    width: '120px',
    filter: 'grayscale(100%)',
  },
}

const mapStateToProps = (state) => {
  return {
    summary_data: state.dashboard.summary_data,
  }
}

export default withTheme(stylesheet)(connect(mapStateToProps, null)(ScreenerFooter))
