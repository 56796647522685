import { connect } from 'react-redux'

import ScreenHeader from './ScreenHeader'

import { toggleTour } from '../../containers/Common/actions'
import { updateNifty50HeatMap, updateNiftyBankHeatMap } from '../../containers/Dashboard/actions'

const mapStateToProps = (state) => {
  return {
    ...state.dashboard,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    toggleTour: params => dispatch(toggleTour(params)),
    updateNifty50HeatMap: params => dispatch(updateNifty50HeatMap(params)),
    updateNiftyBankHeatMap: params => dispatch(updateNiftyBankHeatMap(params)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ScreenHeader)
