import { showSnackbar } from '../UI/Snackbar'
import { BROKERS } from './consts'
import { validateMail } from './common'

export const accessConditions = {
  AB_NO_EMAIL_USAGE_LIMIT: 'AB_NO_EMAIL_USAGE_LIMIT',
  AB_NO_EMAIL: 'AB_NO_EMAIL',
  SHOW_BROKERAGE_TOGGLE: 'SHOW_BROKERAGE_TOGGLE',
  DYNAMIC_CONTRACT: 'DYNAMIC_CONTRACT',
  ULTIMATE_OPTION_CHECK: 'ULTIMATE_OPTION_CHECK',
}

export const PRO_FEATURE_MAP = {
  BT_LIMIT: {
    text: 'Backtest limit reached for the day!',
    ID: 'BT_LIMIT',
  },
  TRAILING_SL: {
    text: 'Trailing SL is only available in Ultimate',
    ID: 'TRAILING_SL',
  },
  RENKO: {
    text: 'Renko is only available in Ultimate',
    ID: 'RENKO',
  },
  SCANNER_LIMIT: {
    text: 'Scanner limit reached for the day!',
    ID: 'SCANNER_LIMIT',
  },
  MCX: {
    text: 'MCX contrats is only available in Ultimate',
    ID: 'MCX',
  },
  MTF: {
    text: 'Multitime frame is only available in Ultimate',
    ID: 'MTF',
  },
  OPTIONS: {
    text: 'Options is only available in Ultimate',
    ID: 'OPTIONs',
  },
  HEIKIN_ASHI: {
    text: 'Heikin-ashi is only available in Regular and Ultimate',
    ID: 'HEIKIN_ASHI',
  },
  THIRTY_DAY_LIVE: {
    text: '30 Days live is only available in Ultimate',
    ID: 'THIRTY_DAY_LIVE',
  },
  DP_LIMIT: {
    text: 'Deployment limit reached for the day!',
    ID: 'DP_LIMIT',
  },
}

const NoEmailUsageLimit = {
  SCAN_LIMIT: 3,
  DEPLOYMENT_LIMIT: 3,
}

export const userAccessCheck = ({
  condition,
  user_details = {},
  params,
  blockedUniverseList = [],
  // showSnackbar,
}) => {
  let returnValue = false

  switch (condition) {
    case accessConditions.AB_NO_EMAIL_USAGE_LIMIT: {
      const { additional_details = {} } = user_details
      const { secondary_email: email = '' } = additional_details
      const {
        deployed = 0,
        scan_count = 0,
      } = user_details
      const scanCount = scan_count
      const deploymentCount = deployed
      if ((!email || !validateMail(email))
        && (scanCount >= NoEmailUsageLimit.SCAN_LIMIT
          || deploymentCount >= NoEmailUsageLimit.DEPLOYMENT_LIMIT)) {
        returnValue = true
        showSnackbar('Please update your Email ID')
        break
      }
      break
    }
    case accessConditions.AB_NO_EMAIL: {
      const { additional_details = {} } = user_details
      const { secondary_email: email = '' } = additional_details
      const modEmail = email && email.trim()
      if (!modEmail || !validateMail(modEmail)) {
        returnValue = true
        showSnackbar('Please update your Email ID')
        break
      }
      break
    }
    case accessConditions.SHOW_BROKERAGE_TOGGLE: {
      const { broker } = user_details
      if(broker === BROKERS.ANGEL.name) {
        returnValue = false
      } else {
        returnValue = true
      }
      break
    }

    case accessConditions.DYNAMIC_CONTRACT: {
      if (user_details.subscription_type < 3) {
        showSnackbar('Subscribe to Ultimate plan to use Dynamic Contracts')
        returnValue = true
      }
      break
    }

    case accessConditions.ULTIMATE_OPTION_CHECK: {
      const { scan_on = '' } = params
      if (blockedUniverseList.includes(scan_on)) {
        returnValue = true
        showSnackbar('Scanning on Options contracts is available only in Ultimate Plan')
        // else if (toggleGreetingModal)
        // toggleGreetingModal(greetingActionMap.pro_features_option); break
      }
      break
    }

    default: break
  }

  return returnValue
}
