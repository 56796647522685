import React from 'react'
import { ICONS, COLORS, ASSETS } from '../../Theme'

const {
  CANDLE_PATTERNS, WEBINAR, BULLISH, BEARISH, INTRADAY_BULLISH, MISCELLANEOUS,
  INTRADAY_BEARISH, RANGE_BREAKOUT, CROSSOVER, TOP_GAINERS, TOP_LOSERS, ALL,

  CANDLE_PATTERNS_OB, WEBINAR_OB, BULLISH_OB, BEARISH_OB, INTRADAY_BULLISH_OB, MISCELLANEOUS_OB,
  INTRADAY_BEARISH_OB, RANGE_BREAKOUT_OB, CROSSOVER_OB, TOP_GAINERS_OB, TOP_LOSERS_OB, ALL_OB,
} = ASSETS

export const CONDENSED_POPULAR_SCANNER_LIMIT = 6

export const SCANNER_CATEGORY_ID = {
  MY_SCANS: 'My Scanners',
  LIVE_SCANS: 'Live Scanners',
  DISCOVER_SCANS: 'Discover Scanners',
  SHARED_SCANS: 'Shared Scanners',
}

export const SCANNER_FILTER = {
  [SCANNER_CATEGORY_ID.DISCOVER_SCANS]: {
    show_favorites: false,
    selections: {
      // position: [],
      // indicators: [],
      chart_type: [],
      time_frame: [],
    },
  },
  [SCANNER_CATEGORY_ID.MY_SCANS]: {
    show_favorites: false,
    selections: {
      // position: [],
      // indicators: [],
      chart_type: [],
      time_frame: [],
    },
  },
  [SCANNER_CATEGORY_ID.LIVE_SCANS]: {
    show_favorites: false,
    selections: {
      // position: [],
      // indicators: [],
      chart_type: [],
      time_frame: [],
    },
    [SCANNER_CATEGORY_ID.SHARED_SCANS]: {
      show_favorites: false,
      selections: {
        // position: [],
        // indicators: [],
        chart_type: [],
        time_frame: [],
      },
    },
  },
}

export const activeScannerSectionRef = React.createRef()

export const FAVORITE_MAP = {
  [SCANNER_CATEGORY_ID.MY_SCANS]: 'my_screens',
  [SCANNER_CATEGORY_ID.LIVE_SCANS]: 'my_screens',
  [SCANNER_CATEGORY_ID.DISCOVER_SCANS]: 'popular',
}

export const SCANNER_ALERT_ACTION_MAP = {
  BUY: { LABEL: 'BUY', ID: 'BUY' },
  SELL: { LABEL: 'SELL', ID: 'SELL' },
  TECHNICALS: { LABEL: 'TECHNICALS', ID: 'TECHNICALS' },
}

export const DISCOVER_SCANNER_ICON_MAP = isDark => ({
  ALL: {
    NAME: ICONS.ALL,
    COLOR: '#F4E5FD',
    TEXT_COLOR: '#A585B9',
    CHIP_COLOR: '#0001fc',
    OB_IMG: ALL_OB,
    IMG: ALL,
  },
  'CHART PATTERNS': { // before candle pattern
    NAME: ICONS.SCANNER_CANDLE_PATTERNS,
    COLOR: isDark ? '#36273E' : '#F4E5FD',
    TEXT_COLOR: '#A585B9',
    OB_IMG: CANDLE_PATTERNS_OB,
    IMG: CANDLE_PATTERNS,
    CHIP_COLOR: COLORS.ORANGE,
  },
  'WEBINAR SCANNERS': {
    NAME: ICONS.YOUTUBE_FILLED,
    COLOR: isDark ? '#2C413A' : '#DDFFF4',
    TEXT_COLOR: '#7BBAA5',
    OB_IMG: WEBINAR_OB,
    IMG: WEBINAR,
    CHIP_COLOR: COLORS.YOUTUBE_RED,
  },
  BULLISH: {
    NAME: ICONS.SCANNER_BULLISH,
    COLOR: isDark ? '#2F3E2D' : '#E3FFE0',
    TEXT_COLOR: '#57ABB2',
    OB_IMG: BULLISH_OB,
    IMG: BULLISH,
    CHIP_COLOR: COLORS.GREEN,
  },
  BEARISH: {
    NAME: ICONS.SCANNER_BEARISH,
    COLOR: isDark ? '#382E29' : '#FEF2EC',
    TEXT_COLOR: '#C7A38D',
    OB_IMG: BEARISH_OB,
    IMG: BEARISH,
    CHIP_COLOR: COLORS.RED,
  },
  'INTRADAY BULLISH': {
    NAME: ICONS.SCANNER_INTRADAY_BULLISH,
    COLOR: isDark ? '#3B4130' : '#F0FFD8',
    TEXT_COLOR: '#88C182',
    OB_IMG: INTRADAY_BULLISH_OB,
    IMG: INTRADAY_BULLISH,
    CHIP_COLOR: COLORS.GREEN,
  },
  'INTRADAY BEARISH': {
    NAME: ICONS.SCANNER_INTRADAY_BEARISH,
    COLOR: isDark ? '#342A26' : '#FEF2EC',
    TEXT_COLOR: '#C7A38D',
    OB_IMG: INTRADAY_BEARISH_OB,
    IMG: INTRADAY_BEARISH,
    CHIP_COLOR: COLORS.RED,
  },
  'RANGE BREAKOUT': {
    NAME: ICONS.SCANNER_RANGE_BREAKOUT,
    COLOR: isDark ? '#272733' : '#E8E8FF',
    TEXT_COLOR: '#9898C9',
    OB_IMG: RANGE_BREAKOUT_OB,
    IMG: RANGE_BREAKOUT,
    CHIP_COLOR: COLORS.GREEN,
  },
  CROSSOVER: {
    NAME: ICONS.SCANNER_CROSSOVER,
    COLOR: isDark ? '#3C3A2B' : '#FDF9E5',
    TEXT_COLOR: '#CBC08C',
    OB_IMG: CROSSOVER_OB,
    IMG: CROSSOVER,
    CHIP_COLOR: COLORS.BLUE,
  },
  'TOP GAINERS': {
    NAME: ICONS.SCANNER_TOP_GAINERS,
    COLOR: isDark ? '#2B3839' : '#D3FCFF',
    TEXT_COLOR: '#57ABB2',
    OB_IMG: TOP_GAINERS_OB,
    IMG: TOP_GAINERS,
    CHIP_COLOR: COLORS.GREEN,
  },
  'TOP LOSERS': {
    NAME: ICONS.SCANNER_TOP_LOSERS,
    COLOR: isDark ? '#46302F' : '#FFEEED',
    TEXT_COLOR: '#BA8481',
    OB_IMG: TOP_LOSERS_OB,
    IMG: TOP_LOSERS,
    CHIP_COLOR: COLORS.RED,
  },
  MISCELLANEOUS: {
    NAME: ICONS.MISCELLANEOUS,
    COLOR: isDark ? '#483331' : '#E8E5FD',
    TEXT_COLOR: '#BA8481',
    CHIP_COLOR: COLORS.BLUE,
    OB_IMG: MISCELLANEOUS_OB,
    IMG: MISCELLANEOUS,
  },
})

export const SCANNER_RESULT_SORT_OPTIONS = {
  NAME: {
    LABEL: 'Name', SORT_KEY: 'symbol', ASCENDING: true, ID: 'NAME',
  },
  PRICE: {
    LABEL: 'Last Traded Price', SORT_KEY: 'last_price', ASCENDING: true, ID: 'PRICE',
  },
  PERCT_CHANGE: {
    LABEL: '% Change', SORT_KEY: 'change', ASCENDING: true, ID: 'PERCT_CHANGE',
  },
}

export const SCANNER_SORT_OPTIONS = {
  NAME: {
    LABEL: 'Name', SORT_KEY: 'screener_name', ASCENDING: true, ID: 'NAME',
  },
  TIME_FRAME: {
    LABEL: 'Time Frame', SORT_KEY: 'time_frame', ASCENDING: true, ID: 'TIME_FRAME',
  },
  MATCHES: {
    LABEL: 'Matches', SORT_KEY: 'symbol_count', ASCENDING: true, ID: 'MATCHES',
  },
}

export const filterKeys = [
  { paramsKey: 'time_frame', key: 'time_frame' },
  { paramsKey: 'action_type', key: 'position' },
  { paramsKey: 'chart_type', key: 'chart_type' },
  { paramsKey: 'entry_logic', key: 'indicators' },
  { paramsKey: 'exit_logic', key: 'indicators' },
]

export const formatSelections = (selection, filters = filterKeys) => {
  let params = {}
  let count = 0
  filters.forEach((item) => {
    const { paramsKey, key } = item
    params = {
      ...params,
      [paramsKey]: selection[key] ? selection[key].map(i => i.toLowerCase()) : [],
    }
    count += params[paramsKey].length
  })
  return { params, count }
}

// changes seg_sym from NASDAQ:GOOG to NASDAQ_GOOG
export const formatScannedStocks = (scannedStocks) => {
  const stocks = []
  scannedStocks.forEach((stock) => {
    stocks.push({ ...stock, seg_sym: stock.seg_sym.split(':').join('_') })
  })

  return stocks
}

// formats time_frame & periodicity from from min/xmin/hour/day to 1 minute,
// x minute, 1 hour, daily.
export const formatTimeFrame = (time_frame, options = {}) => {
  if(time_frame) {
    const timeFrame = time_frame.toLowerCase()
    const timeValue = parseInt(timeFrame, 10)
    let timeUnit = ''
    if (timeFrame.indexOf('min') !== -1) {
      timeUnit = options.expanded ? 'Minute' : 'Min'
    } else if (timeFrame.indexOf('hour') !== -1) {
      timeUnit = 'Hour'
    }
    if (timeFrame.indexOf('day') !== -1) {
      timeUnit = 'Daily'
    }
    if (timeUnit === 'Daily') {
      return timeUnit
    }
    if(Number.isInteger(timeValue)) {
      return `${timeValue} ${timeUnit}`
    }
    return `1 ${timeUnit}`
  }
  return ''
}

export const isSearching = ({ selections, searchQuery, favUuids } = {}) => {
  if(searchQuery?.length > 0 || favUuids?.length > 0) {
    return true
  }
  const values = Object.values(selections)
  for (let i = 0; i < values?.length; i++) {
    if(values[i]?.length > 0) {
      return true
    }
  }
  return false
}

export const NEW_LIST_TYPE_MAP = {
  CONVERT_STRATEGY: 'convert',
  CREATE_STRATEGY: 'create',
  COPY_SCANNER: 'scanner',
  CREATE_BASKET: 'watchlistModal',
  COPY: 'copy',
}

export const DEFAULT_MENU_OPTIONS = {
  EDIT: {
    label: 'Edit', value: 'edit', icon: ICONS.EDIT, iconSize: 13, iconColor: 'TEXT', optionSize: 'small',
  },
  COPY: {
    label: 'Copy', value: NEW_LIST_TYPE_MAP.COPY, icon: ICONS.COPY_FILLED, iconSize: 13, iconColor: 'TEXT', optionSize: 'small',
  },
  COPY_SCANNER: {
    label: 'Copy', value: NEW_LIST_TYPE_MAP.COPY, icon: ICONS.COPY_FILLED, iconSize: 13, iconColor: 'TEXT', optionSize: 'small',
  },
  LIVE: {
    label: 'Take Live', value: 'live', icon: ICONS.NOTIF_SCANNER, iconSize: 13, iconColor: 'GREEN', optionSize: 'small',
  },
  STOP: {
    label: 'Stop Live', value: 'stop', icon: ICONS.NOTIF_SCANNER, iconSize: 13, iconColor: 'RED', optionSize: 'small',
  },
  DELETE: {
    label: 'Delete', value: 'delete', icon: ICONS.DELETE, iconSize: 12, iconColor: 'RED', optionSize: 'small',
  },
  CREATE_BASKET: {
    label: 'Create Basket', value: NEW_LIST_TYPE_MAP.CREATE_BASKET, icon: ICONS.CREATE_BASKET, iconSize: 15, optionSize: 'small',
  },
  CONVERT_STRATEGY: {
    label: 'Convert into Strategy', value: NEW_LIST_TYPE_MAP.CONVERT_STRATEGY, icon: ICONS.CONVERT_INTO_STRATEGY, iconSize: 15, optionSize: 'small',
  },
  EXPORT_SCANLOGS: {
    label: 'Export Scanlogs', value: 'exportScanlogs', icon: ICONS.DOWNLOAD_CLOUD, iconSize: 15, optionSize: 'small',
  },
  DEPLOY: {
    label: 'Deploy', value: 'deploy', icon: ICONS.DEPLOYED, iconSize: 15, optionSize: 'small',
  },
}
export const createNewListProps = {
  [NEW_LIST_TYPE_MAP.COPY_SCANNER]: {
    title: 'Copy Scanner As',
    inputPlaceholder: 'Scanner Name',
    btnText: 'Copy',
  },
  [NEW_LIST_TYPE_MAP.CONVERT_STRATEGY]: {
    title: 'Create Strategy As',
    inputPlaceholder: 'Strategy Name',
    btnText: 'Convert',
  },
  [NEW_LIST_TYPE_MAP.CREATE_STRATEGY]: {
    title: 'Create Strategy As',
    inputPlaceholder: 'Strategy Name',
    btnText: 'Create',
  },
  [NEW_LIST_TYPE_MAP.COPY]: {
    title: 'Copy Scanner As',
    inputPlaceholder: 'Scanner Name',
    btnText: 'Copy',
  },
  [NEW_LIST_TYPE_MAP.CREATE_SCANNER]: {
    title: 'Create Scanner',
    inputPlaceholder: 'Scanner Name',
    btnText: 'Create',
  },
}

export const SCREENER_TAGS_DATA = {
  status: 'success',
  tags: [
    {
      tag_name: 'Chart patterns',
      tag_description: 'Chart patterns scanners',
    },
    {
      tag_name: 'Webinar Scanners',
      tag_description: 'Scanners as seen in webinars',
    },
    {
      tag_name: 'Bullish',
      tag_description: 'Bullish scanners',
    },
    {
      tag_name: 'Intraday Bullish',
      tag_description: 'Intraday bullish scanners',
    },
    {
      tag_name: 'Intraday Bearish',
      tag_description: 'Intraday bearish scanners',
    },
    {
      tag_name: 'Range Breakout',
      tag_description: 'Range breakout scanners',
    },
    {
      tag_name: 'Top Gainers',
      tag_description: 'Top gainer scanners',
    },
    {
      tag_name: 'Crossover',
      tag_description: 'Crossover scanners',
    },
    {
      tag_name: 'Top Losers',
      tag_description: 'Top loser scanners',
    },
    {
      tag_name: 'Bearish',
      tag_description: 'Bearish scanners',
    },
  ],
}

export const segSymConvertor = (item) => {
  const { segment, symbol } = item
  return {
    seg_sym: `${segment}_${symbol}`,
    symbol,
    segment,
  }
}
