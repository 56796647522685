// DANGER remove cycle dep
/* eslint-disable import/no-cycle */
import React, { lazy } from 'react'
import {
  Route,
} from 'react-router-dom'

import { pathname_mapping } from './utils/navigationConstant'

const Dashboard = lazy(() => import('./containers/Dashboard/DashBoard-redux'))
const Scanner = lazy(() => import('./containers/Scanner/Scanner-redux'))
const SymbolDetails = lazy(() => import('./components/Marketwatch/SymbolDetails'))

export const routes = [
  { Component: Dashboard, path: pathname_mapping.Dashboard, exact: true },
  { Component: Scanner, path: pathname_mapping.Scanner, exact: true },
  { Component: SymbolDetails, path: pathname_mapping.Technicals, exact: true },
]

const Routes = () => {
  return (
    routes.map((item) => {
      const { Component, path, exact } = item
      return (
        <Route
          key={path}
          exact={exact}
          path={path}
          component={Component}
        />
      )
    })
  )
}

export default Routes
