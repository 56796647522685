import { TOP_SCANNER_CARD_SEQ_MAP, summaryCandleIntervals } from '../../utils/consts'
import {
  FETCH_SUMMARY_INIT,
  FETCH_SUMMARY_SUCCESS,
  FETCH_SUMMARY_FAILURE,
  FETCH_HEAT_MAP,
  FETCH_HEAT_MAP_SUCCESS,
  FETCH_HEAT_MAP_FAILURE,
  FETCH_NIFTY_50_HEAT_MAP,
  FETCH_NIFTY_50_HEAT_MAP_SUCCESS,
  FETCH_NIFTY_50_HEAT_MAP_FAILURE,
  FETCH_NIFTY_BANK_HEAT_MAP,
  FETCH_NIFTY_BANK_HEAT_MAP_SUCCESS,
  FETCH_NIFTY_BANK_HEAT_MAP_FAILURE,
} from './actionTypes'

const initialState = {
  isFetchingSummary: false,
  fetchSummaryErr: false,
  fetchSummaryErrMsg: '',
  summary_data: {
    market_breadth: {},
    heatmapData: [],
    summaryGridData: [],
    summaryListData: [],
    max_oi: {},
  },
  heatmapData: {},
  nifty50MapData: {},
  niftyBankMapData: {},
  isFetchingHeatmap: false,
}

const dashboard = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SUMMARY_INIT:
      return {
        ...state,
        isFetchingSummary: true,
        fetchSummaryErr: false,
        fetchSummaryErrMsg: '',
        summary_data: {},
      }

    case FETCH_SUMMARY_SUCCESS: {
      const { data } = action
      const {
        market_breadth, screeners, heatmap: heatmapData, max_oi = {},
      } = data

      screeners.map((screener) => {
        const { stocks } = screener
        const filteredStocks = []
        if(stocks) {
          stocks.forEach((stock) => {
            if (!(!stock.symbol.startsWith('NIFTY') && stock.segment === 'INDICES')) {
              filteredStocks.push(stock)
            }
          })
          // screener.stocks = filteredStocks.slice(0, 20)
          screener.stocks = filteredStocks
        }else{
          screener.stocks = []
        }
        return 1
      })

      const finalScreeners = screeners.filter(screener => (screener !== null)).sort((a, b) => {
        return TOP_SCANNER_CARD_SEQ_MAP.indexOf(b.key) - TOP_SCANNER_CARD_SEQ_MAP.indexOf(a.key)
      })

      const is52week = finalScreeners[0]?.time_frame === summaryCandleIntervals['52 Week']
      const summaryGridData = is52week ? finalScreeners.slice(0, 2).reverse()
        : finalScreeners.slice(0, 4).reverse()
      const summaryListData = is52week ? finalScreeners.slice(4, 8)
        : finalScreeners.slice(6)
      const summary_data = {
        market_breadth,
        heatmapData,
        summaryGridData,
        summaryListData,
        max_oi,
      }
      return {
        ...state,
        isFetchingSummary: false,
        fetchSummaryErr: false,
        summary_data,
      }
    }

    case FETCH_SUMMARY_FAILURE: {
      const { error } = action
      return {
        ...state,
        isFetchingSummary: false,
        fetchSummaryErr: true,
        fetchSummaryErrMsg: error,
      }
    }

    case FETCH_HEAT_MAP: {
      return {
        ...state,
        isFetchingHeatmap: true,
      }
    }

    case FETCH_HEAT_MAP_SUCCESS: {
      const { data } = action
      return {
        ...state,
        isFetchingHeatmap: false,
        heatmapData: { ...data },
      }
    }

    case FETCH_HEAT_MAP_FAILURE: {
      return {
        ...state,
        isFetchingHeatmap: false,
      }
    }

    case FETCH_NIFTY_50_HEAT_MAP: {
      return {
        ...state,
        isFetchingHeatmap: true,
      }
    }

    case FETCH_NIFTY_50_HEAT_MAP_SUCCESS: {
      const { data } = action
      return {
        ...state,
        isFetchingHeatmap: false,
        nifty50MapData: { ...data },
      }
    }

    case FETCH_NIFTY_50_HEAT_MAP_FAILURE: {
      return {
        ...state,
        isFetchingHeatmap: false,
      }
    }

    case FETCH_NIFTY_BANK_HEAT_MAP: {
      return {
        ...state,
        isFetchingHeatmap: true,
      }
    }

    case FETCH_NIFTY_BANK_HEAT_MAP_SUCCESS: {
      const { data } = action
      return {
        ...state,
        isFetchingHeatmap: false,
        niftyBankMapData: { ...data },
      }
    }

    case FETCH_NIFTY_BANK_HEAT_MAP_FAILURE: {
      return {
        ...state,
        isFetchingHeatmap: false,
      }
    }

    default:
      return state
  }
}

export default dashboard
