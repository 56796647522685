import { takeLatest, put, call } from 'redux-saga/effects'
import { summaryBaseUrl } from '../../utils/apiEndpoints'
import { getRequest } from '../../utils/apiRequests'
import {
  fetchScannerDetailsFailure,
  fetchScannerDetailsSuccess,
} from './actions'
import {
  FETCH_SCANNER_DETAILS_INIT,
} from './actionTypes'
import { screenerPairs, getInstruments } from '../Instruments/actions'

import { error_msg } from '../../utils/common'
import { NAVIGATIONS } from '../../utils/navigationConstant'

function* fetchScanner(action) {
  try {
    const { params } = action
    const { scanner, index, timeframe } = params
    const finalParams = {
      index,
      timeframe,
    }

    const url = `${summaryBaseUrl}${scanner}`
    const data = yield call(getRequest, url, finalParams, {})
    if (!data.error && data.status === 'success') {
      const { screeners } = data
      const { stocks = [] } = screeners[0]
      yield put(getInstruments(
        // screenerPairs(null, stocks.slice(0, 20)),
        screenerPairs(null, stocks),
        {},
        NAVIGATIONS.SCAN_DETAILS.name,
      ))
      yield put(fetchScannerDetailsSuccess(data))
    } else {
      yield put(fetchScannerDetailsFailure(data.error))
    }
  } catch(err) {
    yield put(fetchScannerDetailsFailure(err.message || error_msg))
  }
}

// eslint-disable-next-line import/prefer-default-export
export function* fetchScannersSaga() {
  yield takeLatest(FETCH_SCANNER_DETAILS_INIT, fetchScanner)
}
