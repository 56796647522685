export const FETCH_SUMMARY_INIT = 'FETCH_SUMMARY_INIT'
export const FETCH_SUMMARY_SUCCESS = 'FETCH_SUMMARY_SUCCESS'
export const FETCH_SUMMARY_FAILURE = 'FETCH_SUMMARY_FAILURE'

export const FETCH_HEAT_MAP_SUCCESS = 'FETCH_HEAT_MAP_SUCCESS'
export const FETCH_HEAT_MAP = 'FETCH_HEAT_MAP'
export const FETCH_HEAT_MAP_FAILURE = 'FETCH_HEAT_MAP_FAILURE'

export const FETCH_NIFTY_50_HEAT_MAP_SUCCESS = 'FETCH_NIFTY_50_HEAT_MAP_SUCCESS'
export const FETCH_NIFTY_50_HEAT_MAP = 'FETCH_NIFTY_50_HEAT_MAP'
export const FETCH_NIFTY_50_HEAT_MAP_FAILURE = 'FETCH_NIFTY_50_HEAT_MAP_FAILURE'

export const FETCH_NIFTY_BANK_HEAT_MAP_SUCCESS = 'FETCH_NIFTY_BANK_HEAT_MAP_SUCCESS'
export const FETCH_NIFTY_BANK_HEAT_MAP = 'FETCH_NIFTY_BANK_HEAT_MAP'
export const FETCH_NIFTY_BANK_HEAT_MAP_FAILURE = 'FETCH_NIFTY_BANK_HEAT_MAP_FAILURE'
