/* eslint-disable import/no-cycle */
import { all, fork } from 'redux-saga/effects'
import * as dashboardSagas from './containers/Dashboard/DashBoard-saga'
import * as scannerSagas from './containers/Scanner/Scanner-saga'
import * as technicalsSagas from './containers/Technicals/Technicals-saga'
import * as commonSagas from './containers/Common/Common-saga'
import * as instrumentSagas from './containers/Instruments/Instruments-saga'
import * as scansSaga from './containers/Scans/Scans-saga'
import * as marketSagas from './containers/Marketwatch/Marketwatch-saga'
import * as marketPlaceSagas from './containers/MarketPlace/MarketPlace-saga'

const dashboard = Object.values(dashboardSagas).map((saga) => {
  return fork(saga)
})

const scanner = Object.values(scannerSagas).map((saga) => {
  return fork(saga)
})

const technicals = Object.values(technicalsSagas).map((saga) => {
  return fork(saga)
})

const common = Object.values(commonSagas).map((saga) => {
  return fork(saga)
})

const instrument = Object.values(instrumentSagas).map((saga) => {
  return fork(saga)
})

const scans = Object.values(scansSaga).map((saga) => {
  return fork(saga)
})

const market = Object.values(marketSagas).map((saga) => {
  return fork(saga)
})

const marketPlace = Object.values(marketPlaceSagas).map((saga) => {
  return fork(saga)
})

export default function* root() {
  yield all([
    ...dashboard,
    ...scanner,
    ...technicals,
    ...common,
    ...instrument,
    ...scans,
    ...market,
    ...marketPlace,
  ])
}
