// fetch deployed/waiting algo stocks
// export const CRYPTO_SYMBOLS_INIT = 'CRYPTO_SYMBOLS_INIT'
// export const CRYPTO_SYMBOLS_SUCCESS = 'CRYPTO_SYMBOLS_SUCCESS'
// export const CRYPTO_SYMBOLS_FAILURE = 'CRYPTO_SYMBOLS_FAILURE'

export const MOD_WATCH_LIST = 'MOD_WATCH_LIST'

export const DIRECT_ORDER_INIT = 'DIRECT_ORDER_INIT'
export const DIRECT_ORDER_SUCCESS = 'DIRECT_ORDER_SUCCESS'
export const DIRECT_ORDER_FAILURE = 'DIRECT_ORDER_FAILURE'

export const GET_WATCHLIST_INIT = 'GET_WATCHLIST_INIT'
export const GET_WATCHLIST_SUCCESS = 'GET_WATCHLIST_SUCCESS'
export const GET_WATCHLIST_FAILURE = 'GET_WATCHLIST_FAILURE'

export const UPDATE_WATCHLIST_INIT = 'UPDATE_WATCHLIST_INIT'
export const UPDATE_WATCHLIST_SUCCESS = 'UPDATE_WATCHLIST_SUCCESS'
export const UPDATE_WATCHLIST_FAILURE = 'UPDATE_WATCHLIST_FAILURE'

export const REORDER_WATCHLIST = 'REORDER_WATCHLIST'

export const GET_ANALYSIS_INIT = 'GET_ANALYSIS_INIT'
export const GET_ANALYSIS_SUCCESS = 'GET_ANALYSIS_SUCCESS'
export const GET_ANALYSIS_FAILURE = 'GET_ANALYSIS_FAILURE'

export const GET_TOP_MOVERS = 'GET_TOP_MOVERS'
export const GET_TOP_MOVERS_SUCCESS = 'GET_TOP_MOVERS_SUCCESS'
export const GET_TOP_MOVERS_FAILURE = 'GET_TOP_MOVERS_FAILURE'

export const CLEAR_UPDATE_RESP = 'CLEAR_UPDATE_RESP'

export const UPDATE_MARKET_SCRIPLIST = 'UPDATE_MARKET_SCRIPLIST'

export const CLEAR_ANALYSIS = 'CLEAR_ANALYSIS'
