import { ICONS, ASSETS } from '../../Theme'

const {
  ALL, TREND_FOLLOWING, PRICE_ACTION, MEAN_REVERSION, MOMENTUM,
  MISCELLANEOUS, PIVOT_POINTS, OVERLAYS, OPTIONS_STRATEGIES, INTRADAY,
  SWING_TRADING, POSITION_TRADING, THE_MILLENNIALS, TECH, LEADING, ATR, MTF,

  ALL_OB, TREND_FOLLOWING_OB, PRICE_ACTION_OB, MEAN_REVERSION_OB, MOMENTUM_OB,
  MISCELLANEOUS_OB, PIVOT_POINTS_OB, OVERLAYS_OB, OPTIONS_STRATEGIES_OB, INTRADAY_OB,
  SWING_TRADING_OB, POSITION_TRADING_OB, THE_MILLENNIALS_OB, TECH_OB, LEADING_OB, ATR_OB, MTF_OB,
} = ASSETS

export const DISOVER_CATEGORY_ICON_MAP = isDark => ({
  ALL: {
    NAME: ICONS.ALL,
    COLOR: isDark ? '#23233C' : '#F4E5FD',
    TEXT_COLOR: '#A585B9',
    CHIP_COLOR: '#0001fc',
    IMG_OB: ALL_OB,
    IMG: ALL,
  },
  'TREND FOLLOWING': {
    NAME: ICONS.TREND_FOLLOWING,
    COLOR: isDark ? '#251F29' : '#F4E5FD',
    TEXT_COLOR: '#A585B9',
    CHIP_COLOR: '#b231ff', // CATEGORY_GREEN,
    IMG_OB: TREND_FOLLOWING_OB,
    IMG: TREND_FOLLOWING,
  },
  'PRICE ACTION': {
    NAME: ICONS.PRICE_ACTION,
    COLOR: isDark ? '#2E3C2C' : '#E3FFE0',
    TEXT_COLOR: '#88C182',
    CHIP_COLOR: '#19db00', // CATEGORY_YELLOW,
    IMG_OB: PRICE_ACTION_OB,
    IMG: PRICE_ACTION,
  },
  'MEAN REVERSION': {
    NAME: ICONS.MEAN_REVERSION,
    COLOR: isDark ? '#273233' : '#D3FCFF',
    TEXT_COLOR: '#57ABB2',
    CHIP_COLOR: '#08c8d8', // CATEGORY_GREEN,
    IMG_OB: MEAN_REVERSION_OB,
    IMG: MEAN_REVERSION,
  },
  MOMENTUM: {
    NAME: ICONS.MOMENTUM,
    COLOR: isDark ? '#332B28' : '#FEF2EC',
    TEXT_COLOR: '#A77258',
    CHIP_COLOR: '#ff8141', // CATEGORY_YELLOW,
    IMG_OB: MOMENTUM_OB,
    IMG: MOMENTUM,
  },
  MISCELLANEOUS: {
    NAME: ICONS.MISCELLANEOUS,
    COLOR: isDark ? '#483331' : '#FFEEED',
    TEXT_COLOR: '#BA8481',
    CHIP_COLOR: '#e63c32', // CATEGORY_BLUE,
    IMG_OB: MISCELLANEOUS_OB,
    IMG: MISCELLANEOUS,
  },
  'PIVOT POINTS': {
    NAME: ICONS.PIVOT_POINTS,
    COLOR: isDark ? '#2B433B' : '#DDFFF4',
    TEXT_COLOR: '#8BC5B2',
    CHIP_COLOR: '#5dffcc', // CATEGORY_BLUE,
    IMG_OB: PIVOT_POINTS_OB,
    IMG: PIVOT_POINTS,
  },
  OVERLAYS: {
    NAME: ICONS.OVERLAYS,
    COLOR: isDark ? '#2D2D41' : '#E8E8FF',
    TEXT_COLOR: '#A8A8D4',
    CHIP_COLOR: '#6060ff', // CATEGORY_ORANGE,
    IMG_OB: OVERLAYS_OB,
    IMG: OVERLAYS,
  },
  'OPTIONS STRATEGIES': {
    NAME: ICONS.MISCELLANEOUS,
    COLOR: isDark ? '#2D2D41' : '#E8E8FF',
    TEXT_COLOR: '#9898C9',
    CHIP_COLOR: '#1d1efd', // CATEGORY_ORANGE,
    IMG_OB: OPTIONS_STRATEGIES_OB,
    IMG: OPTIONS_STRATEGIES,
  },
  INTRADAY: {
    NAME: ICONS.INTRADAY,
    COLOR: isDark ? '#2E4243' : '#D3FCFF',
    TEXT_COLOR: '#82BFC4',
    CHIP_COLOR: '#08c8d8', // CATEGORY_ORANGE,
    IMG_OB: INTRADAY_OB,
    IMG: INTRADAY,
  },
  'SWING TRADING': {
    NAME: ICONS.INTRADAY,
    COLOR: isDark ? '#483331' : '#FFEEED',
    TEXT_COLOR: '#BA8481',
    CHIP_COLOR: '#e63c32', // CATEGORY_ORANGE,
    IMG_OB: SWING_TRADING_OB,
    IMG: SWING_TRADING,
  },
  'POSITION TRADING': {
    NAME: ICONS.INTRADAY,
    COLOR: isDark ? '#2E412C' : '#E3FFE0',
    TEXT_COLOR: '#88C182',
    CHIP_COLOR: '#19db00', // CATEGORY_ORANGE,
    IMG_OB: POSITION_TRADING_OB,
    IMG: POSITION_TRADING,
  },
  'THE MILLENNIALS': {
    NAME: ICONS.INTRADAY,
    COLOR: isDark ? '#372B3E' : '#F4E5FD',
    TEXT_COLOR: '#A585B9',
    CHIP_COLOR: '#b231ff', // CATEGORY_ORANGE,
    IMG_OB: THE_MILLENNIALS_OB,
    IMG: THE_MILLENNIALS,
  },
  TECH: {
    NAME: ICONS.TECH,
    COLOR: isDark ? '#2E4243' : '#D3FCFF',
    TEXT_COLOR: '#82BFC4',
    CHIP_COLOR: '#58dce7', // CATEGORY_ORANGE,
    IMG_OB: TECH_OB,
    IMG: TECH,
  },
  LEADING: {
    NAME: ICONS.LEADING,
    COLOR: isDark ? '#332B28' : '#FEF2EC',
    TEXT_COLOR: '#A77258',
    CHIP_COLOR: '#ff8141', // CATEGORY_ORANGE,
    IMG_OB: LEADING_OB,
    IMG: LEADING,
  },
  'ATR TRAILING STOP LOSS': {
    NAME: ICONS.LEADING,
    COLOR: isDark ? '#414A34' : '#F0FFD8',
    TEXT_COLOR: '#88C182',
    CHIP_COLOR: '#a2ed2b', // CATEGORY_ORANGE,
    IMG_OB: ATR_OB,
    IMG: ATR,
  },
  'MULTI TIME FRAME ANALYSIS': {
    NAME: ICONS.LEADING,
    COLOR: isDark ? '#2B433B' : '#DDFFF4',
    TEXT_COLOR: '#7BBAA5',
    CHIP_COLOR: '#31f7b7', // CATEGORY_ORANGE,
    IMG_OB: MTF_OB,
    IMG: MTF,
  },
})

export const ALGO_PAGE_LIMIT = 6
export const DISCOVER_PAGE_LIMIT = 6
export const CONDENSED_ALGO_PAGE_LIMIT = 8 // Math.floor(WIDTH / 120)
export const CONDENSED_ALGO_LIMIT = 10
export const ONBOARDING_ALGO_LIMIT = 10
export const CONDENSED_TOP_PERFORMER_LIMIT = 6

export const ALGO_KEY_MAP = {
  MY_ALGOS: 'algo_uuid',
  DISCOVER: 'publishing_uuid',
  SUBSCRIBED: 'algo_subscription_uuid',
  SHARED_ALGOS: 'backtest_shared_uuid',
}

export const ALGO_SORT_MAP = {
  PNL_PCT: {
    LABEL: 'Percentage P&L', SORT_KEY: 'absolute_pnl_pct', ASCENDING: true, ID: 'PNL_PCT',
  },
  PNL_ABS: {
    LABEL: 'Absolute P&L', SORT_KEY: 'absolute_pnl', ASCENDING: true, ID: 'PNL_ABS',
  },
  WINLOSS_RATIO: {
    LABEL: 'Win Loss Ratio', SORT_KEY: 'avg_win_loss_ratio', ASCENDING: true, ID: 'WINLOSS_RATIO',
  },
  WIN_STREAK: {
    LABEL: 'Average Winning Streak', SORT_KEY: 'avg_winning_streak', ASCENDING: true, ID: 'WIN_STREAK',
  },
}

export const TOP_PERFORMER_SORT_MAP = {
  PNL_PCT: {
    LABEL: 'Percentage P&L', SORT_KEY: 'today_ret', ASCENDING: true, ID: 'PNL_PCT',
  },
  PNL_ABS: {
    LABEL: 'Absolute P&L', SORT_KEY: 'today_pnl', ASCENDING: true, ID: 'PNL_ABS',
  },
  WINLOSS_RATIO: {
    LABEL: 'Win Loss Ratio', SORT_KEY: 'avg_win_loss_ratio', ASCENDING: true, ID: 'WINLOSS_RATIO',
  },
  WIN_STREAK: {
    LABEL: 'Average Winning Streak', SORT_KEY: 'avg_winning_streak', ASCENDING: true, ID: 'WIN_STREAK',
  },
}
